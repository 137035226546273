import { TranslationFunction } from '../../common/types'
import { Bedrooms } from '../../transports/property-details-page-transport'
import get from 'lodash/get'

export interface FormattedImproperBedroom {
    total: number
}

export class ImproperBedrooms {
    private readonly __: TranslationFunction

    constructor(translationFunction: TranslationFunction) {
        this.__ = translationFunction
    }

    public format(bedrooms?: Bedrooms): FormattedImproperBedroom | null {
        if (!bedrooms) {
            return null
        }

        let total = 0

        const countChildren = get(bedrooms, 'children')
        if (countChildren) {
            total += countChildren
        }
        const countLiving = get(bedrooms, 'living')
        if (countLiving) {
            total += countLiving
        }

        const countMisc = get(bedrooms, 'miscellaneous')
        if (countMisc) {
            total += countMisc
        }

        return total === 0
            ? null
            : {
                  total,
              }
    }
}
