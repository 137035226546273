import styled from 'styled-components'
import FreeCancellationIcon from '../../../assets/icons/filter-icons/free-cancellation.svg'

export const FreeCancellationWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.column};
`

export const FreeCancellationHeader = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    padding: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.xSmall}`};
`

export const ListItem = styled.li`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
`

export const Bullet = styled.span`
    display: ${({ theme }) => theme.style.display.flex};
    width: ${({ theme }) => theme.layout.xSmall};
    min-width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
`

export const StyledTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-bottom: ${({ theme }) => theme.spacing.xxSmall};
`

export const FreeCancellationImage = styled(FreeCancellationIcon).attrs({ as: FreeCancellationIcon })`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
    margin-right: ${({ theme }) => theme.spacing.small};
`
