import { useCallback, useContext } from 'react'

import { RoutesContext } from '../context/routes'
import { RoutingFunction } from '../common/types'
import { useRequestUserConfigContext } from './request'
import { defaultRoutes } from '../routes/config'

export const useRoutes = () => useContext(RoutesContext)

export const useRouting = () => {
    const { domain } = useRequestUserConfigContext()
    const { routes } = useRoutes()

    const r: RoutingFunction = useCallback(
        key => {
            if (routes[key] === undefined || routes[key] === null) {
                console.warn({ message: 'Route for domain not found.', key, domain })
            }
            return routes[key] || defaultRoutes[key] || ''
        },
        [domain, routes],
    )

    return { r }
}
