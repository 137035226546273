import React, { FC } from 'react'
import { Typography } from 'react-components'
import { useTranslation } from '../../../../hooks/locale'
import { CostBar, PriceGuestWrapper, AvailableTag, TickIcon } from './styles'
import CheckIcon from '../../../../assets/icons/check-icon.svg'
import { AnyStyledComponent } from 'styled-components'
import { SearchbarFieldsReturn } from '../../hooks/use-searchbar'
import { calculateDateDifference } from '../../../../utils/date-utils'
import PulsatingDots from '../../pulsating-dots'
import get from 'lodash/get'
import { FormattedLivePricingDataTransport } from '../../../../models/pricing/live-pricing-formatter'

type CostsProps = {
    pricingDetails: FormattedLivePricingDataTransport | null
    isPricingLoading: boolean
    pricingErrorCode: number | null
    searchbar: SearchbarFieldsReturn
    showVatTax: boolean
    showCalendar: boolean
}

const Costs: FC<CostsProps> = ({ pricingDetails, isPricingLoading, searchbar, showVatTax, showCalendar }) => {
    const { t } = useTranslation()
    const totalPersons = searchbar.adults + searchbar.kids
    const nights = calculateDateDifference(searchbar.arrival, searchbar.departure)
    const price = get(pricingDetails, 'price')

    if (isPricingLoading) {
        return (
            <CostBar>
                <PriceGuestWrapper>
                    <PulsatingDots />
                </PriceGuestWrapper>
            </CostBar>
        )
    }

    return (
        <CostBar>
            <PriceGuestWrapper>
                {price && (
                    <Typography variant="h5" fontWeight="semiBold">
                        {price}
                    </Typography>
                )}
                {nights && totalPersons && (
                    <Typography variant="subtitle2" color="tfwBlack40" fontWeight={'regular'}>
                        {`${t('guestsCount', { count: totalPersons })}/${t('nightsCount', { count: nights })}`}
                    </Typography>
                )}
                {showVatTax && (
                    <Typography variant="overline" color="tfwBlack40" fontWeight={'regular'}>
                        {t('price.vatTaxInfo')}
                    </Typography>
                )}
            </PriceGuestWrapper>
            <AvailableTag onRequest={!showCalendar}>
                <TickIcon as={CheckIcon as AnyStyledComponent} alt="tick-icon" />
                <Typography variant="overline" fontWeight="regular" color="white">
                    {showCalendar ? t('available') : t('onRequestTitle')}
                </Typography>
            </AvailableTag>
        </CostBar>
    )
}

export default Costs
