import styled from 'styled-components'

export const StyledTitleBar = styled.div<{ isHidden?: boolean }>`
    display: ${({ theme, isHidden }) => (isHidden ? 'none' : theme.style.display.flex)};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0`};
`

export const ErrorText = styled.div<{ isHidden?: boolean }>`
    display: ${({ theme, isHidden }) => (isHidden ? 'none' : theme.style.display.flex)};
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0 ${theme.spacing.xxSmall} 0`};
`
