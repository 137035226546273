import React, { FC } from 'react'
import { SpeacialFeatureWrapper, SpecialFeatureTitle } from './styles'
import { useTranslation } from '../../../hooks/locale'
import { ReadMore, Typography } from 'react-components'

type SpecialFeatureProps = {
    feature: string
}

const SpecialFeatures: FC<SpecialFeatureProps> = ({ feature }) => {
    const { t } = useTranslation()
    if (!feature) {
        return null
    }
    return (
        <SpeacialFeatureWrapper>
            <SpecialFeatureTitle>
                <Typography variant="subtitle1" fontWeight="highlight">
                    {t('specialFeaturesTitle')}
                </Typography>
            </SpecialFeatureTitle>
            <ReadMore
                text={feature}
                readLessLabel={t('readLessLabel')}
                readMoreLabel={t('readMoreLabel')}
                typographyProps={{
                    variant: 'subtitle2',
                }}
            />
        </SpeacialFeatureWrapper>
    )
}

export default SpecialFeatures
