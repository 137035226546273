import React from 'react'
import {
    FormattedBookingCancellationPolicy,
    FormattedRule,
} from '../../../../models/property-details-page/booking-cancellation-policy-model'
import { NotesSectionTitleWrapper } from '../notes/styles'
import { Typography } from 'react-components'
import { useTranslation } from '../../../../hooks/locale'
import { RuleContainer, Rule, RuleHeader, IconContainer, Icon, RuleItemsWrapper } from './styles'
import { AnyStyledComponent } from 'styled-components'
import IconMapping from '../../icon-mapping'
import isDate from 'lodash/isDate'
import { useDateFormatter } from '../../../../hooks/date'
import { sub } from 'date-fns'

interface BookingCancellationProps {
    policy?: FormattedBookingCancellationPolicy | null
    isTraumPaymentEnabled: boolean
    arrivalDate: Date | null
}

const BookingCancellationPolicy: React.FC<BookingCancellationProps> = ({
    policy,
    isTraumPaymentEnabled,
    arrivalDate,
}) => {
    const { t } = useTranslation()
    const { format } = useDateFormatter()

    function subtractDays(date: string | Date | null, days: number, dateFormat = 'dd MMM yyyy'): string {
        if (!date || !isDate(new Date(date))) {
            return '--'
        }
        const originalDate = typeof date === 'string' ? new Date(date) : date
        const newDate = sub(originalDate, { days })
        return format(newDate, dateFormat)
    }

    if (!isTraumPaymentEnabled) {
        return null
    }

    const renderRules = (
        rules: FormattedRule[],
        type: 'fullRefund' | 'noRefund' | 'partialRefund',
        isSlab: boolean,
    ) => {
        if (!rules || rules.length === 0) {
            return null
        }

        const titleRule = rules[0]
        const subtextRules = rules
        const messageType = !isSlab ? type : `slab.${type}`
        const messageKey =
            isDate(arrivalDate) && isSlab
                ? `bookingCancellationPolicy.${messageType}.messageWithArrival`
                : `bookingCancellationPolicy.${messageType}.message`

        return (
            <RuleContainer>
                <Rule>
                    <RuleHeader>
                        <IconContainer>
                            <Icon as={IconMapping[titleRule.icon] as AnyStyledComponent} />
                        </IconContainer>
                        <Typography variant="subtitle2" fontWeight="regular" color={titleRule.color}>
                            {t(messageKey, {
                                days: titleRule.daysFrom,
                                percent: titleRule.percent,
                                date: isDate(arrivalDate)
                                    ? subtractDays(
                                          arrivalDate,
                                          isSlab && type !== 'fullRefund' ? titleRule.daysTo : titleRule.daysFrom,
                                      )
                                    : '--',
                            })}
                        </Typography>
                    </RuleHeader>
                    {subtextRules.map((rule, index) => {
                        const subTextKey =
                            isDate(arrivalDate) && isSlab
                                ? `bookingCancellationPolicy.${messageType}.subtextWithArrival`
                                : `bookingCancellationPolicy.${messageType}.subText`

                        const hasSubText =
                            t(subTextKey, {
                                daysFrom: rule.daysFrom,
                                daysTo: rule.daysTo,
                                percent: rule.percent,
                                dateFrom: isDate(arrivalDate) ? subtractDays(arrivalDate, rule.daysTo) : '--',
                                dateTo: isDate(arrivalDate) ? subtractDays(arrivalDate, rule.daysFrom) : '--',
                            }) !== ''

                        return (
                            <RuleItemsWrapper key={index}>
                                {hasSubText && (
                                    <Typography variant="subtitle2" fontWeight="regular" color={'tfwBlack40'}>
                                        {t(subTextKey, {
                                            daysFrom: rule.daysFrom,
                                            daysTo: rule.daysTo,
                                            percent: rule.percent,
                                            dateFrom: isDate(arrivalDate)
                                                ? subtractDays(arrivalDate, rule.daysTo)
                                                : '--',
                                            dateTo: isDate(arrivalDate)
                                                ? subtractDays(arrivalDate, rule.daysFrom)
                                                : '--',
                                        })}
                                    </Typography>
                                )}
                            </RuleItemsWrapper>
                        )
                    })}
                </Rule>
            </RuleContainer>
        )
    }

    return (
        <React.Fragment>
            <NotesSectionTitleWrapper>
                <Typography variant={'subtitle2'} fontWeight="regular">
                    {t('bookingCancellationPolicy.heading')}
                </Typography>
            </NotesSectionTitleWrapper>
            {policy?.rules?.fullRefund && renderRules(policy.rules.fullRefund, 'fullRefund', false)}
            {policy?.rules?.noRefund && renderRules(policy.rules.noRefund, 'noRefund', false)}
            {policy?.rules?.slab?.fullRefund && renderRules(policy.rules.slab.fullRefund, 'fullRefund', true)}
            {policy?.rules?.slab?.partialRefund && renderRules(policy.rules.slab.partialRefund, 'partialRefund', true)}
            {policy?.rules?.slab?.noRefund && renderRules(policy.rules.slab.noRefund, 'noRefund', true)}
        </React.Fragment>
    )
}

export default BookingCancellationPolicy
