import React, { FC, memo } from 'react'
import { FeatureIcon, FeatureIconWrapper, FeaturesContainer, FeatureWrapper } from './styles'
import IconMapping from '../icon-mapping'
import { AnyStyledComponent } from 'styled-components'
import { Typography } from 'react-components'
import { Features as FeaturesTransport } from '../../../models/property-details-page/features-model'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

type FeaturesProps = {
    features: FeaturesTransport | null
}

const Features: FC<FeaturesProps> = ({ features }) => {
    if (!features || isEmpty(features)) {
        return null
    }

    return (
        <FeaturesContainer>
            {Object.values(features).map(feature => {
                const iconId = get(feature, 'iconId')
                const label = get(feature, 'label')
                const state = get(feature, 'state')

                if (!iconId || !label) {
                    return null
                }

                const IconComponent = IconMapping[iconId] as AnyStyledComponent

                return (
                    <FeatureWrapper key={iconId}>
                        <FeatureIconWrapper state={state}>
                            <FeatureIcon as={IconComponent} />
                        </FeatureIconWrapper>
                        <Typography variant="subtitle2">{label}</Typography>
                    </FeatureWrapper>
                )
            })}
        </FeaturesContainer>
    )
}

export default memo(Features)
