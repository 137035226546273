import styled from 'styled-components'

export const AccordionHeader = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0`};
    cursor: pointer;
`

export const AccordionItem = styled.div`
    border-bottom: ${({ theme }) => `1px dotted ${theme.colors.tfwBlack10}`};
    &:last-child {
        border-bottom: none;
    }
    &:last-child ${AccordionHeader} {
        padding-bottom: 0;
    }
`

export const AccordionContent = styled.div<{ isOpen: boolean }>`
    padding: ${({ isOpen, theme }) => (isOpen ? `${theme.spacing.xxxSmall} 0` : '0')};
    height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    overflow: hidden;
    transition: height 0.3s ease, padding 0.3s ease;
    white-space: pre-line;
    margin-bottom: ${({ isOpen, theme }) => (isOpen ? `${theme.spacing.xSmall}` : '0')}; ;
`

export const ChevronIcon = styled.img`
    height: ${({ theme }) => theme.layout.xxSmall};
    width: ${({ theme }) => theme.layout.xxSmall};
`

export const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
    height: ${({ theme }) => theme.layout.xSmall};
    width: ${({ theme }) => theme.layout.xSmall};
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
    transition: transform 0.3s ease;
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`
