import { TranslationFunction } from '../../common/types'
import { PropertyDetailsSuitables } from '../../transports/property-details-page-transport'

export interface FormattedSuitable {
    suitable: number
    status: string
    label: string
}

export interface FormattedSuitablesMap {
    [key: string]: FormattedSuitable
}

export enum SuitableStates {
    SUITABLE_STATUS_ALLOWED = 1,
    SUITABLE_STATUS_NOT_ALLOWED = 2,
    SUITABLE_STATUS_ON_REQUEST = 3,
    SUITABLE_STATUS_NOT_SPECIFIED = 4,
}

export enum SuitablesTypes {
    SUITABLE_PETS_ALLOWED = 1,
    SUITABLE_DOGS_ALLOWED = 2,
    SUITABLE_ALLERGIC_PERSON = 3,
    SUITABLE_LONG_TERM_HOLIDAY = 4,
    SUITABLE_FAMILY_HOLIDAY = 5,
    SUITABLE_HOLIDAY_WITH_BABIES = 6,
    SUITABLE_NONE_SMOKING_OBJECT = 7,
    SUITABLE_HOLIDAY_WITH_A_HORSE = 8,
    SUITABLE_FITTERS = 9,
}

export interface FormattedSuitableFeatures {
    title: string
    items: string[]
}

export class SuitablesModel {
    private readonly suitableTranslationKeys: Record<number, string> = {
        [SuitablesTypes.SUITABLE_PETS_ALLOWED]: 'petsSuitable',
        [SuitablesTypes.SUITABLE_DOGS_ALLOWED]: 'dogsSuitable',
        [SuitablesTypes.SUITABLE_ALLERGIC_PERSON]: 'allergicSuitable',
        [SuitablesTypes.SUITABLE_LONG_TERM_HOLIDAY]: 'longTermHolidaySuitable',
        [SuitablesTypes.SUITABLE_FAMILY_HOLIDAY]: 'familySuitable',
        [SuitablesTypes.SUITABLE_HOLIDAY_WITH_BABIES]: 'babiesSuitable',
        [SuitablesTypes.SUITABLE_NONE_SMOKING_OBJECT]: 'nonSmokingSuitable',
        [SuitablesTypes.SUITABLE_HOLIDAY_WITH_A_HORSE]: 'horseSuitable',
        [SuitablesTypes.SUITABLE_FITTERS]: 'fittersSuitable',
    }

    constructor(private __: TranslationFunction) {
        this.__ = __
    }

    public format(suitables: PropertyDetailsSuitables): FormattedSuitablesMap {
        const formattedMap: FormattedSuitablesMap = {}

        suitables.forEach(suitable => {
            const translationKey = this.suitableTranslationKeys[suitable.suitable]
            const label = this.__(translationKey)
            formattedMap[suitable.suitable] = {
                suitable: suitable.suitable,
                status: suitable.status,
                label,
            }
        })

        return formattedMap
    }

    public formatedSuitableFeatures(suitables: PropertyDetailsSuitables): FormattedSuitableFeatures | null {
        const result: string[] = []
        suitables.forEach(suitable => {
            if (Number(suitable.status) !== SuitableStates.SUITABLE_STATUS_NOT_ALLOWED) {
                let label = this.__(this.suitableTranslationKeys[suitable.suitable])

                if (Number(suitable.status) === SuitableStates.SUITABLE_STATUS_ON_REQUEST) {
                    label += ` (${this.__('onRequestTitle')})`
                }

                result.push(label)
            }
        })
        if (result.length === 0) {
            return null
        }
        result.sort()
        return {
            title: this.__('suitableForLabel'),
            items: result,
        }
    }
}
