import React, { memo, useCallback } from 'react'
import Searchbar from '../../search-result-listing/header-section/searchbar'
import { SearchRegionNodeTransport } from '../../../transports/search-result-listing-page-transport'
import { useDispatch } from 'react-redux'
import { useSearchUrl } from '../../../hooks/search'
import { saveHistoryItem } from '../../../redux/searchbar'
import { getHistoryObject } from '../../landing-page/utils'
import { getSearchDate } from '../../../utils/miscellaneous-utils'
import AnalyticsService from '../../../services/analytics-service'
import { commonPageEventActions } from '../../../services/analytics-service/actions'
import { CommonPageEventCategories } from '../../../services/analytics-service/categories'
import { getFilterFromHref } from '../../../utils/request-utils'
import { Location } from '../../search-result-listing/header-section/types'
import { StyledSearchBarWrapper } from './styles'
import updateDateRetentionCookies from '../utils/update-date-retention-cookies'

interface SearchbarProps {
    searchRegionNode: SearchRegionNodeTransport
    isAllInputModalOpen: boolean
    toggleAllInputModal: () => void
}

const SearchbarComponent: React.FC<SearchbarProps> = ({
    searchRegionNode,
    isAllInputModalOpen,
    toggleAllInputModal,
}) => {
    const dispatch = useDispatch()
    const { getSearchUrl } = useSearchUrl()

    const _handleSearch = useCallback(
        async ({
            location: { search, regionId },
            arrivalDate,
            departureDate,
            adultsCount,
            childrenCount,
        }: {
            location: Location
            arrivalDate: Date | null
            departureDate: Date | null
            adultsCount: number
            childrenCount: number
        }) => {
            dispatch(saveHistoryItem(getHistoryObject(arrivalDate, departureDate, adultsCount, childrenCount, search)))

            const arrival = getSearchDate(arrivalDate)
            const departure = getSearchDate(departureDate)

            AnalyticsService.trackEvent({
                action: commonPageEventActions.searchSection.searchBox.CLICK_SEARCH,
                category: CommonPageEventCategories.searchSection.SEARCH_BOX,
                label: search,
                ga_custom_param_arrival: arrival,
                ga_custom_param_departure: departure,
                ga_custom_param_person: adultsCount + childrenCount,
            })

            AnalyticsService.trackEvent({
                event: commonPageEventActions.searchSection.searchBox.SEARCH,
                eventValue: search,
            })

            const searchUrl = await getSearchUrl(getFilterFromHref(window.location.href), {
                regionId,
                search,
                arrival,
                departure,
                adults: adultsCount,
                children: childrenCount,
            })

            updateDateRetentionCookies(arrival, departure, adultsCount, childrenCount, search)

            window.location.href = `${window.location.origin}${searchUrl}`
        },
        [dispatch, getSearchUrl],
    )

    return (
        <StyledSearchBarWrapper>
            <Searchbar
                searchRegionNode={searchRegionNode}
                onSearch={_handleSearch}
                maxWidth="87.5rem"
                isAllInputOpen={isAllInputModalOpen}
                toggleAllInputModal={toggleAllInputModal}
            />
        </StyledSearchBarWrapper>
    )
}

export default memo(SearchbarComponent)
