import { TranslationFunction } from '../../common/types'
import {
    PropertyDetails,
    PropertyRooms,
    GeneralEquipments,
    GeneralEquipmentsEnum,
} from '../../transports/property-details-page-transport'
import { FormattedAccessibilityTransport } from './accessibility-model'
import { FeatFilterTypes, FormattedFeatFilterMap } from './featfilter-model'
import { FormattedSuitablesMap, SuitableStates, SuitablesTypes } from './suitables-model'
import { FormattedSuitedMap, SuitedTypes } from './suited-model'

export interface Feature {
    state: number
    label: string | null
    iconId: string
}

export interface Features {
    [key: string]: Feature
}

export interface Room {
    isBathroom: boolean
    count: number
}

export enum FeatureFilterTrackingEnum {
    FEATURE_WIFI = 'wifi',
    FEATURE_PETS = 'pets',
    FEATURE_ACCESSIBLE = 'accessible',
    FEATURE_SMOKING = 'smoking',
    FEATURE_TELEVISION = 'television',
    FEATURE_POOL = 'pool',
    FEATURE_DISH_WASHER = 'dishwasher',
    FEATURE_SAUNA = 'sauna',
    FEATURE_WASHING_MACHINE = 'washing-machine',
    FEATURE_E_MOBILITY_CHARGING_STATION = 'e-mobility-charging-station',
}

export class FeaturesFormatter {
    constructor(private __: TranslationFunction) {}

    /**
     * Format the features.
     *
     * @param objectGeneral - general data of the object.
     * @param objectSuitables - suitables data of the object
     * @param rooms - property rooms data
     * @param accessibility
     * @param suitables
     * @param suited
     * @param filterFeatMap
     * @param bedrooms - count of bedrooms
     * @param improperBedrooms - count of improper bedrooms
     * @param featuresLimit - number of features user wants
     * @param withAccessibility - boolean to check if we want accessibility features or not
     * @param withBasics - boolean to check if we want basic features or not
     * @returns Formatted features array with sorting.
     */
    public format(
        objectGeneral: PropertyDetails,
        rooms: PropertyRooms,
        accessibility: FormattedAccessibilityTransport | null,
        suitables: FormattedSuitablesMap,
        suited: FormattedSuitedMap,
        filterFeatMap: FormattedFeatFilterMap,
        bedrooms = 0,
        improperBedrooms = 0,
        featuresLimit = 5,
        withAccessibility = true,
        withBasics = true,
    ): Features {
        let basicFeatures: Features = {}
        let featuresWithoutBasicFeatures: Features = {}

        const personsTotal = objectGeneral.persons?.total || 0
        const squareMeters = objectGeneral.squareMeters

        if (withBasics) {
            basicFeatures = this.addBasics(basicFeatures, squareMeters, personsTotal, rooms, bedrooms, improperBedrooms)
        }
        featuresWithoutBasicFeatures = this.addPetFeature(featuresWithoutBasicFeatures, suitables)
        featuresWithoutBasicFeatures = this.addSmokingFeature(featuresWithoutBasicFeatures, suitables)
        featuresWithoutBasicFeatures = this.addAccessable(featuresWithoutBasicFeatures, suited)
        featuresWithoutBasicFeatures = this.addEMobilityChargingStation(featuresWithoutBasicFeatures, filterFeatMap)

        if (withAccessibility && accessibility) {
            featuresWithoutBasicFeatures = this.addAccessibility(featuresWithoutBasicFeatures, accessibility)
        }

        // Add other features based on objectGeneral data
        if (objectGeneral.generalEquipments) {
            featuresWithoutBasicFeatures = {
                ...featuresWithoutBasicFeatures,
                ...this.addOtherFeatures(objectGeneral.generalEquipments),
            }
        }

        featuresWithoutBasicFeatures = this.sortFeatures(featuresWithoutBasicFeatures)
        const features: Features = { ...basicFeatures, ...featuresWithoutBasicFeatures }

        // Slice to the requested limit
        const slicedFeatures: Features = Object.fromEntries(Object.entries(features).slice(0, featuresLimit))

        return slicedFeatures
    }

    private addEMobilityChargingStation(
        featuresWithoutBasicFeatures: Features,
        filterFeatMap: FormattedFeatFilterMap,
    ): Features {
        const eMobilityChargingAvailable =
            FeatFilterTypes.FILTER_MAPPER_FEAT_CHARGING_STATION_FOR_ELECTRIC_CAR in filterFeatMap

        if (eMobilityChargingAvailable) {
            featuresWithoutBasicFeatures[FeatureFilterTrackingEnum.FEATURE_E_MOBILITY_CHARGING_STATION] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label:
                    filterFeatMap[FeatFilterTypes.FILTER_MAPPER_FEAT_CHARGING_STATION_FOR_ELECTRIC_CAR].label +
                    ' ' +
                    this.__('featureAvailable'),
                iconId: 'chargingStation',
            }
        }
        return featuresWithoutBasicFeatures
    }

    private addBasics(
        features: Features,
        squareMeters: number | null,
        personsTotal: number,
        rooms: PropertyRooms,
        bedrooms: number,
        improperBedrooms: number,
    ): Features {
        if (bedrooms > 0) {
            features['beds'] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label:
                    improperBedrooms > 0
                        ? this.__('bedRoomCountWithImproper', { count: bedrooms, improper: improperBedrooms })
                        : this.__('properBedRoomCount', { count: bedrooms }),
                iconId: 'beds',
            }
        }

        const bathroomCount = this.getBathroomCount(rooms)
        if (rooms && bathroomCount > 0) {
            features['bathrooms'] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label: this.__('bathroomCount', { count: bathroomCount }),
                iconId: 'bathrooms',
            }
        }

        if (personsTotal) {
            features['guests'] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label: this.__('maxGuestCount', { count: personsTotal }),
                iconId: 'guests',
            }
        }

        if (squareMeters) {
            features['area'] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label: this.__('meterSquareSize', { size: squareMeters }),
                iconId: 'area',
            }
        }
        return features
    }

    private addPetFeature(featuresWithoutBasicFeatures: Features, suitables: FormattedSuitablesMap): Features {
        let petsAllowedStatus = SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        let dogsAllowedStatus = SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        let petFeatureState = SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        let petFeatureLabel = ''
        const dogsAllowedSuitableType = SuitablesTypes.SUITABLE_DOGS_ALLOWED
        const petsAllowedSuitableType = SuitablesTypes.SUITABLE_PETS_ALLOWED
        petsAllowedStatus = Number(suitables[dogsAllowedSuitableType]?.status ?? petsAllowedStatus)
        dogsAllowedStatus = Number(suitables[petsAllowedSuitableType]?.status ?? dogsAllowedStatus)

        // Determine label and state based on combination
        if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED
        ) {
            petFeatureLabel = this.__('petsAndDogsAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        ) {
            petFeatureLabel = this.__('petsAllowedDogsNotAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED
        ) {
            petFeatureLabel = this.__('petsNotAllowedDogsAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED
        ) {
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
            petFeatureLabel = this.__('dogsAllowed')
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        ) {
            petFeatureLabel = this.__('petsAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST
        ) {
            petFeatureLabel = this.__('petsAllowedDogsOnRequest')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ALLOWED
        ) {
            petFeatureLabel = this.__('petsOnRequestDogsAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        ) {
            petFeatureLabel = this.__('petsNotAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        ) {
            petFeatureLabel = this.__('dogsNotAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        ) {
            petFeatureLabel = this.__('petsAndDogsNotAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
        ) {
            petFeatureLabel = this.__('petsOnRequestDogsNotAllowed')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ON_REQUEST
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_NOT_ALLOWED &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST
        ) {
            petFeatureLabel = this.__('petsNotAllowedDogsOnRequest')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ON_REQUEST
        } else if (
            petsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST &&
            dogsAllowedStatus === SuitableStates.SUITABLE_STATUS_ON_REQUEST
        ) {
            petFeatureLabel = this.__('petsAndDogsOnRequest')
            petFeatureState = SuitableStates.SUITABLE_STATUS_ON_REQUEST
        } else {
            petFeatureLabel = this.__('noInformationAboutPetsAndDogs')
            petFeatureState = SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        }

        featuresWithoutBasicFeatures[FeatureFilterTrackingEnum.FEATURE_PETS] = {
            state: petFeatureState,
            label: petFeatureLabel,
            iconId: FeatureFilterTrackingEnum.FEATURE_PETS,
        }

        return featuresWithoutBasicFeatures
    }

    private addSmokingFeature(featuresWithoutBasicFeatures: Features, suitables: FormattedSuitablesMap): Features {
        const nonSmokingSuitableType = SuitablesTypes.SUITABLE_NONE_SMOKING_OBJECT
        let smokingFeatureState = SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
        let smokingFeatureLabel = this.__('noSmokingInfo')
        const nonSmokingStatus = Number(
            suitables[nonSmokingSuitableType]?.status ?? SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED,
        )

        switch (nonSmokingStatus) {
            case SuitableStates.SUITABLE_STATUS_ALLOWED:
                smokingFeatureState = SuitableStates.SUITABLE_STATUS_NOT_ALLOWED
                smokingFeatureLabel = this.__('smokingNotAllowed')
                break
            case SuitableStates.SUITABLE_STATUS_NOT_ALLOWED:
                smokingFeatureState = SuitableStates.SUITABLE_STATUS_ALLOWED
                smokingFeatureLabel = this.__('smokingAllowed')
                break
            case SuitableStates.SUITABLE_STATUS_ON_REQUEST:
                smokingFeatureState = SuitableStates.SUITABLE_STATUS_ON_REQUEST
                smokingFeatureLabel = this.__('smokingOnRequest')
                break
            default:
                smokingFeatureState = SuitableStates.SUITABLE_STATUS_NOT_SPECIFIED
                smokingFeatureLabel = this.__('noSmokingInfo')
        }

        featuresWithoutBasicFeatures[FeatureFilterTrackingEnum.FEATURE_SMOKING] = {
            state: smokingFeatureState,
            label: smokingFeatureLabel,
            iconId: FeatureFilterTrackingEnum.FEATURE_SMOKING,
        }
        return featuresWithoutBasicFeatures
    }

    private addAccessable(featuresWithoutBasicFeatures: Features, suited: FormattedSuitedMap): Features {
        const isBarrierFree = SuitedTypes.SUITED_BARRIER_FREE in suited
        const isWheelChairAvailable = SuitedTypes.SUITED_WHEELCHAIR in suited
        let accessibleLabel = ''
        let iconId = ''
        if (isBarrierFree) {
            accessibleLabel = this.__('barrierFreeSuitable')
            iconId = 'barrierFree'
        } else if (!isBarrierFree && isWheelChairAvailable) {
            accessibleLabel = this.__('wheelChairWithBarrier')
            iconId = 'wheelChairWithBarrier'
        } else {
            return featuresWithoutBasicFeatures
        }
        featuresWithoutBasicFeatures[FeatureFilterTrackingEnum.FEATURE_ACCESSIBLE] = {
            state: SuitableStates.SUITABLE_STATUS_ALLOWED,
            label: accessibleLabel,
            iconId,
        }
        return featuresWithoutBasicFeatures
    }

    private getBathroomCount(rooms: PropertyRooms): number {
        if (!rooms) {
            return 0
        }

        return Object.values(rooms).reduce((count, room) => {
            if (!room.isBathroom) {
                return count
            }
            return count + (room.count || 1)
        }, 0)
    }

    private addAccessibility(features: Features, accessibility: FormattedAccessibilityTransport): Features {
        if (!accessibility) {
            return features
        }
        if (accessibility.id) {
            features[accessibility.id] = {
                state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                label: accessibility.label,
                iconId: accessibility.id,
            }
        }
        return features
    }

    private addOtherFeatures(generalEquipments: GeneralEquipments): Features {
        const features: Features = {}

        const equipmentMap = {
            [GeneralEquipmentsEnum.TV]: {
                key: FeatureFilterTrackingEnum.FEATURE_TELEVISION,
                label: this.__('tvFeature', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.TV],
            },
            [GeneralEquipmentsEnum.POOL]: {
                key: FeatureFilterTrackingEnum.FEATURE_POOL,
                label: this.__('swimmingPoolFeature', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.POOL],
            },
            [GeneralEquipmentsEnum.DISH_WASHER]: {
                key: FeatureFilterTrackingEnum.FEATURE_DISH_WASHER,
                label: this.__('dishWaherFeature', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.DISH_WASHER],
            },
            [GeneralEquipmentsEnum.WIFI]: {
                key: FeatureFilterTrackingEnum.FEATURE_WIFI,
                label: this.__('featureWifi', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.WIFI],
            },
            [GeneralEquipmentsEnum.SAUNA]: {
                key: FeatureFilterTrackingEnum.FEATURE_SAUNA,
                label: this.__('saunaFeature', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.SAUNA],
            },
            [GeneralEquipmentsEnum.WASHING_MACHINE]: {
                key: 'washingMachine',
                label: this.__('washingMachineFeature', { count: 1 }),
                available: generalEquipments[GeneralEquipmentsEnum.WASHING_MACHINE],
            },
        }

        Object.values(equipmentMap).forEach(equipment => {
            if (equipment.available) {
                features[equipment.key] = {
                    state: SuitableStates.SUITABLE_STATUS_ALLOWED,
                    label: equipment.label,
                    iconId: equipment.key,
                }
            }
        })

        return features
    }

    private sortFeatures(features: Features): Features {
        const priorityOrder = [
            'wifi',
            'e-mobility-charging-station',
            'television',
            'pets',
            'accessible',
            'pool',
            'dish-washer',
            'sauna',
            'washing-machine',
            'smoking',
        ]

        const sortedFeatures: Features = {}

        priorityOrder.forEach(key => {
            if (features[key]) {
                sortedFeatures[key] = features[key]
                delete features[key]
            }
        })
        return { ...sortedFeatures, ...features }
    }
}
