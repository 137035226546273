import React, { FC, useContext } from 'react'
import ExposeGallery from './expose-gallery'
import {
    ExposeContentWrapper,
    Sidebar,
    Content,
    ExposeMobileWrapper,
    ExposeDesktopWrapper,
    FooterWrapper,
} from './style'
import TitleBar from './title-bar'
import RatingsBar from './ratings-bar'
import StatsBar from './stats-bar'
import Description from './description'
import Amenities from './amenities'
import Location from './location'
import Contact from './contact'
import Price from './price'
import Review from './review'
import WishlistShareBar from './wishlist-share-bar'
import SidebarComponent from './side-bar'
import { ThemeContext } from 'styled-components'
import { DatePickerAlignment } from '../common/date-picker/types'
import CustomerObjectsSection from './customer-objects'
import useSearchBar from './hooks/use-searchbar'
import { useDynamicAvailability } from '../../hooks/availability'
import { useLivePricing } from '../../hooks/pricing'
import { FormattedPropertyData } from '../../models/property-details-page/property-details-model'
import { RegionNodeTransport } from '../../transports/property-details-page-transport'
import { useDateFormatter } from '../../hooks/date'
import createChildrenAges from './utils/get-children-ages'
import { useTranslation } from '../../hooks/locale'
import usePriceFormatter from '../../hooks/price'
import NavigationBar from './navigation-bar'
import NavigationSectionTransport from '../../transports/common/navigation-section-transport'
import FooterSectionTransport from '../../transports/common/footer-section-transport'
import FooterSection from '../landing-page/footer-section'
import FreeCancellation from './free-cancellation'
import Footer from './footer'
import HeaderSearchbar from './header-searchbar'
import BreadcrumbSection from '../../components/landing-page/breadcrumb-section'
import LocationBar from './location-bar'
import StickyFooter from './sticky-footer'
import { ScreenContext } from 'react-components'
import { useQuery } from '../../hooks/query'
import dynamic from 'next/dynamic'
import SkeletonAvailabilityCalendar from './availability-calendar/skeleton-calendar'

const LazyAvailabilityCalendar = dynamic(() => import('./availability-calendar'), {
    ssr: false,
    loading: SkeletonAvailabilityCalendar,
})

type Props = {
    isLoggedIn: boolean
    userDetails: any
    url: string
    sitePathname: string
    propertyDetails: FormattedPropertyData | null
    listingId: string
    navigationSection: NavigationSectionTransport
    footerSection: FooterSectionTransport
    regionNode: RegionNodeTransport
}

const PropertyDetailsPage: FC<Props> = ({
    userDetails,
    isLoggedIn,
    url,
    sitePathname,
    propertyDetails,
    listingId,
    navigationSection,
    footerSection,
    regionNode,
}) => {
    const query = useQuery()
    const theme = useContext(ThemeContext)
    const { isMobile } = useContext(ScreenContext)
    const { format } = useDateFormatter()
    const { t } = useTranslation()
    const formatPrice = usePriceFormatter()

    const { searchbar, updateSearchbar } = useSearchBar({
        query,
        location: regionNode?.title || '',
    })

    const { isLoading, error, availableDates, departureBasedAvailableDates, arrivalBasedAvailableDates } =
        useDynamicAvailability({
            searchbarData: searchbar,
            listingId: listingId,
        })

    const {
        isLoading: isPricingLoading,
        error: pricingErrorCode,
        pricingDetails,
    } = useLivePricing(
        {
            listingId: listingId,
            arrival: searchbar.arrival ? format(searchbar.arrival, 'yyyy-MM-dd') : null,
            departure: searchbar.departure ? format(searchbar.departure, 'yyyy-MM-dd') : null,
            childrenAges: createChildrenAges(searchbar.kids).join(','),
            pets: 0,
            adults: searchbar.adults,
            optionalServicesQuantity: '',
            selectedOptionalServices: '',
            bypassCache: true,
        },
        arrivalBasedAvailableDates,
        departureBasedAvailableDates,
        12,
        format,
        t,
        formatPrice,
    )

    if (!propertyDetails) {
        return null
    }

    return (
        <>
            <HeaderSearchbar
                isLoggedIn={isLoggedIn}
                userDetails={userDetails}
                searchbar={searchbar}
                url={url}
                sitePathname={sitePathname}
                navigationSection={navigationSection}
                listingId={listingId}
                regionNode={regionNode}
            />
            <NavigationBar listingId={propertyDetails.id} />
            <WishlistShareBar listingId={propertyDetails.id} />
            {
                <ExposeMobileWrapper>
                    <ExposeGallery
                        showThumbnail={false}
                        images={propertyDetails.gallery}
                        bookingType={propertyDetails.bookingType}
                        searchbar={searchbar}
                        propertyName={propertyDetails.fullTitle}
                    />
                </ExposeMobileWrapper>
            }
            <ExposeContentWrapper>
                <Content>
                    {!!propertyDetails?.breadcrumbs && propertyDetails?.breadcrumbs.length > 0 && (
                        <BreadcrumbSection
                            breadcrumbs={propertyDetails.breadcrumbs}
                            url={url}
                            containerPadding={'1rem 0 0'}
                            hideMobile
                        />
                    )}
                    <TitleBar title={propertyDetails.fullTitle} />
                    <RatingsBar
                        ratingCount={propertyDetails.reviews.ratingsCount}
                        averageRating={propertyDetails.reviews.badge.avg}
                        trust={propertyDetails.trust}
                    />
                    <StatsBar stats={propertyDetails.stats} />
                    <ExposeMobileWrapper>
                        <LocationBar showMapHyperlink region={propertyDetails.propertyRegion} />
                    </ExposeMobileWrapper>
                    {
                        <ExposeDesktopWrapper>
                            <ExposeGallery
                                searchbar={searchbar}
                                propertyName={propertyDetails.fullTitle}
                                bookingType={propertyDetails.bookingType}
                                images={propertyDetails.gallery}
                                showThumbnail
                                imageBorderRadius={`${theme.typography.border.radius.medium}`}
                            />
                        </ExposeDesktopWrapper>
                    }
                    {
                        <ExposeMobileWrapper>
                            <Price
                                layout="stretched"
                                datePickerAlignment={DatePickerAlignment.LEFT}
                                showPriceTitle={false}
                                hideExtraInfo
                                notes={propertyDetails.notes}
                                bookingCancellationPolicy={propertyDetails.bookingCancellationPolicy}
                                updateSearchbar={updateSearchbar}
                                searchbar={searchbar}
                                availableDates={availableDates}
                                isAvailabilityLoading={isLoading}
                                pricingDetails={pricingDetails}
                                isPricingLoading={isPricingLoading}
                                pricingErrorCode={pricingErrorCode}
                                bookingType={propertyDetails.bookingType}
                                offers={propertyDetails.offers}
                                showVatTax={propertyDetails.general.showIncludedVatInformation}
                                isTraumPaymentEnabled={propertyDetails.general.traumPaymentsActivated}
                                showCalendar={propertyDetails.general.showAvailabilityCalendar}
                                maxTotalGuests={propertyDetails?.stats?.maxPersons}
                            />
                        </ExposeMobileWrapper>
                    }
                    <FreeCancellation
                        freeCancellationDays={propertyDetails.general.freeCancellationInDays}
                        isFreeCancellationAvailable={propertyDetails.general.activatedFreeCancellation}
                    />
                    <Description description={propertyDetails.description} />
                    <Amenities
                        features={propertyDetails.features}
                        rooms={propertyDetails.rooms}
                        extendedFeatures={propertyDetails.extendedFeatures}
                    />
                    <Price
                        layout={!isMobile ? 'compact' : 'stretched'}
                        datePickerAlignment={DatePickerAlignment.LEFT}
                        showPriceTitle
                        notes={propertyDetails.notes}
                        bookingCancellationPolicy={propertyDetails.bookingCancellationPolicy}
                        updateSearchbar={updateSearchbar}
                        searchbar={searchbar}
                        availableDates={availableDates}
                        isAvailabilityLoading={isLoading}
                        pricingDetails={pricingDetails}
                        isPricingLoading={isPricingLoading}
                        pricingErrorCode={pricingErrorCode}
                        bookingType={propertyDetails.bookingType}
                        offers={propertyDetails.offers}
                        showVatTax={propertyDetails.general.showIncludedVatInformation}
                        isTraumPaymentEnabled={propertyDetails.general.traumPaymentsActivated}
                        showCalendar={propertyDetails.general.showAvailabilityCalendar}
                        maxTotalGuests={propertyDetails?.stats?.maxPersons}
                    />
                    <Location
                        surroundings={propertyDetails.surroundings}
                        location={propertyDetails.location}
                        activities={propertyDetails.activities}
                        leisure={propertyDetails.description.leisure}
                        surroundingLocation={propertyDetails.description.surroundings}
                        journey={propertyDetails.description.journey}
                        regionLabel={propertyDetails.description.regionLabel}
                        region={propertyDetails.propertyRegion}
                    />
                    <LazyAvailabilityCalendar
                        showCalendar={propertyDetails.general.showAvailabilityCalendar}
                        listingId={listingId}
                    />
                    <Contact
                        contact={propertyDetails.contact}
                        bookingType={propertyDetails.bookingType}
                        providerId={propertyDetails.general.providerId}
                        searchbar={searchbar}
                    />
                    <Review ratings={propertyDetails.reviews} propertyTypeConfig={propertyDetails.propertyTypeConfig} />
                    <CustomerObjectsSection
                        customerObjects={propertyDetails.customerObjects}
                        contact={propertyDetails.contact}
                    />
                    <Footer footer={propertyDetails.footer} />
                </Content>
                <Sidebar>
                    <SidebarComponent
                        trust={propertyDetails.trust}
                        contact={propertyDetails.contact}
                        updateSearchbar={updateSearchbar}
                        searchbar={searchbar}
                        availableDates={availableDates}
                        isAvailabilityLoading={isLoading}
                        errorFetchingAvailability={error}
                        pricingDetails={pricingDetails}
                        isPricingLoading={isPricingLoading}
                        pricingErrorCode={pricingErrorCode}
                        bookingType={propertyDetails.bookingType}
                        offers={propertyDetails.offers}
                        showVatTax={propertyDetails.general.showIncludedVatInformation}
                        providerId={propertyDetails.general.providerId}
                        isTraumPaymentEnabled={propertyDetails.general.traumPaymentsActivated}
                        showCalendar={propertyDetails.general.showAvailabilityCalendar}
                        maxTotalGuests={propertyDetails?.stats?.maxPersons}
                    />
                </Sidebar>
            </ExposeContentWrapper>
            <FooterWrapper>
                <FooterSection
                    footerSection={footerSection}
                    footerLogo={navigationSection.navigation.logo}
                    socialMediaLinks={navigationSection.socialMediaLinks}
                />
                <StickyFooter
                    bookingType={propertyDetails.bookingType}
                    searchbar={searchbar}
                    pricingDetails={pricingDetails}
                />
            </FooterWrapper>
        </>
    )
}

export default PropertyDetailsPage
