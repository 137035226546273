import styled, { css } from 'styled-components'

export const CalendarWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    max-width: 600px;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    gap: ${({ theme }) => theme.spacing.xxxSmall};
    padding: ${({ theme }) => `0 ${theme.spacing.medium}`};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const WeekRow = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    gap: ${({ theme }) => theme.spacing.xxxSmall};
`

export const DayBox = styled.div<{ state?: string }>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${({ theme, state }) =>
        state === 'available' &&
        css`
            background: ${theme.colors.tfwPositiveMedium};
        `}
    ${({ theme, state }) =>
        state === 'booked' &&
        css`
            background: ${theme.colors.tfwNegativeMedium};
        `}
    ${({ theme, state }) =>
        state === 'available_booked' &&
        css`
            background: linear-gradient(
                to bottom right,
                ${theme.colors.tfwPositiveMedium} 50%,
                ${theme.colors.tfwNegativeMedium} 50%
            );
        `}
    ${({ theme, state }) =>
        state === 'booked_available' &&
        css`
            background: linear-gradient(
                to bottom right,
                ${theme.colors.tfwNegativeMedium} 50%,
                ${theme.colors.tfwPositiveMedium} 50%
            );
        `}
`

export const EmptyDayBox = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.tfwBlack40};
    opacity: 0.25;
`

export const DayHeaderCell = styled.div`
    padding: ${({ theme }) => theme.spacing.xxSmall};
`
