import CookieManagerService from '../../../services/cookie-manager-service'

function updateDateRetentionCookies(
    arrival: string,
    departure: string,
    adultsCount: number,
    childrenCount?: number,
    search?: string,
) {
    if (arrival && departure && adultsCount) {
        const children = childrenCount ? childrenCount.toString() : '0'
        CookieManagerService.setCookie(
            CookieManagerService.SEARCH_PARAMS,
            `${arrival}|${departure}|${adultsCount.toString()}|${children}|${search || ''}`,
        )
    }
}

export default updateDateRetentionCookies
