import React, { FC } from 'react'
import { Typography } from 'react-components'
import {
    NotesWrapper,
    List,
    ListItem,
    StyledTitle,
    Bullet,
    Separator,
    NotesSectionTitleWrapper,
    NoteContentWrapepr,
} from './styles'
import { FormattedTravelNote } from '../../../../models/property-details-page/notes-model'
import { useTranslation } from '../../../../hooks/locale'
import { SectionTitleWrapper } from '../../style'
import isEmpty from 'lodash/isEmpty'
import { FormattedBookingCancellationPolicy } from '../../../../models/property-details-page/booking-cancellation-policy-model'
import BookingCancellationPolicy from '../booking-cancellation-policy'

type NoteProps = {
    notes?: FormattedTravelNote[]
    bookingCancellationPolicy?: FormattedBookingCancellationPolicy | null
    arrivalDate: Date | null
    isTraumPaymentEnabled: boolean
}

const Note: FC<NoteProps> = ({ notes, bookingCancellationPolicy, arrivalDate, isTraumPaymentEnabled }) => {
    const { t } = useTranslation()
    return (
        <NotesWrapper>
            <SectionTitleWrapper size="header">
                <Typography variant={'subtitle1'} fontWeight="bold">
                    {t('travelNotes.notesTitle')}
                </Typography>
            </SectionTitleWrapper>
            {bookingCancellationPolicy && isTraumPaymentEnabled && (
                <>
                    <BookingCancellationPolicy
                        policy={bookingCancellationPolicy}
                        isTraumPaymentEnabled={isTraumPaymentEnabled}
                        arrivalDate={arrivalDate}
                    />
                    <Separator />
                </>
            )}
            {!isEmpty(notes) &&
                notes?.map((note, index) => {
                    const isLastNote = index === notes.length - 1
                    return (
                        <React.Fragment key={`${note.title}_${index}`}>
                            <NotesWrapper>
                                <NotesSectionTitleWrapper>
                                    <Typography variant={'subtitle2'} fontWeight="semiBold">
                                        {note.title}
                                    </Typography>
                                </NotesSectionTitleWrapper>
                                {Array.isArray(note.content) ? (
                                    <List>
                                        {note.content.map((contentItem, index) => (
                                            <ListItem key={index}>
                                                <Bullet>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="tfwBlack40"
                                                        fontWeight={'semiBold'}
                                                    >
                                                        •
                                                    </Typography>
                                                </Bullet>
                                                <StyledTitle>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="tfwBlack40"
                                                        fontWeight="regular"
                                                    >
                                                        {contentItem}
                                                    </Typography>
                                                </StyledTitle>
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <NoteContentWrapepr>
                                        <Typography
                                            variant="subtitle2"
                                            color="tfwBlack40"
                                            whiteSpace="pre-line"
                                            fontWeight="regular"
                                        >
                                            {note.content}
                                        </Typography>
                                    </NoteContentWrapepr>
                                )}
                            </NotesWrapper>
                            {!isLastNote && <Separator />}
                        </React.Fragment>
                    )
                })}
        </NotesWrapper>
    )
}

export default Note
