import React, { memo, FC, useMemo, useState, useCallback } from 'react'
import Searchbar from '../searchbar'
import HeaderSection from '../../landing-page/header-section'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import NavigationSectionTransport from '../../../transports/common/navigation-section-transport'
import { RegionNodeTransport } from '../../../transports/property-details-page-transport'
import { useQuery } from '../../../hooks/query'
import PreviewHeader from './preview-header'
import { WORLD_REGION_NODE_ID } from '../../../common/constants'

type HeaderSearchBarProps = {
    isLoggedIn: boolean
    userDetails: any
    url: string
    sitePathname: string
    navigationSection: NavigationSectionTransport
    searchbar: SearchbarFieldsReturn
    listingId: string
    regionNode: RegionNodeTransport
}

const HeaderSearchbar: FC<HeaderSearchBarProps> = ({
    isLoggedIn,
    userDetails,
    url,
    navigationSection,
    sitePathname,
    searchbar,
    listingId,
    regionNode,
}) => {
    const query = useQuery()
    const [isAllInputModalOpen, setAllInputModal] = useState<boolean>(false)

    const toggleAllInputModal = useCallback(() => {
        setAllInputModal((prev: boolean) => !prev)
    }, [])

    const headerSection = useMemo(
        () => ({
            navigationSectionResponse: navigationSection,
        }),
        [navigationSection],
    )

    const isPreviewMode = useMemo(() => query && query['preview'], [query])

    const searchRegionNode = useMemo(
        () => ({
            arrival: searchbar.arrival?.toDateString(),
            departure: searchbar.departure?.toDateString(),
            search: searchbar.location,
            adults: searchbar.adults,
            children: searchbar.kids,
            regionNodeId: regionNode?.id || WORLD_REGION_NODE_ID,
        }),
        [regionNode?.id, searchbar.adults, searchbar.arrival, searchbar.departure, searchbar.kids, searchbar.location],
    )

    return (
        <>
            {!isPreviewMode ? (
                <HeaderSection
                    isLoggedIn={isLoggedIn}
                    firstName={userDetails?.firstName}
                    url={url}
                    headerSection={headerSection}
                    sitePathname={sitePathname}
                    toggleAllInputModal={toggleAllInputModal}
                    showVacationIdeasNavButton
                />
            ) : (
                <PreviewHeader listingId={listingId} />
            )}
            <Searchbar
                searchRegionNode={searchRegionNode}
                toggleAllInputModal={toggleAllInputModal}
                isAllInputModalOpen={isAllInputModalOpen}
            />
        </>
    )
}

export default memo(HeaderSearchbar)
