import React, { FC, useMemo, memo } from 'react'
import { NearByWrapper, NearByTitle } from './styles'
import Accordion from '../../accordion'
import Activities from './activities'
import { useTranslation } from '../../../../hooks/locale'
import { Typography } from 'react-components'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

interface NearByProps {
    activities: string[]
    leisure: string
    surroundings: string
    journey: string
    regionLabel: string
}

const NearBySection: FC<NearByProps> = ({ activities, leisure, surroundings, journey, regionLabel }) => {
    const { t } = useTranslation()

    const safeActivities = useMemo(() => (isArray(activities) ? activities : []), [activities])
    const safeLeisure = useMemo(() => (isString(leisure) ? leisure : ''), [leisure])
    const safeSurroundings = useMemo(() => (isString(surroundings) ? surroundings : ''), [surroundings])
    const safeJourney = useMemo(() => (isString(journey) ? journey : ''), [journey])
    const safeRegionLabel = useMemo(() => (isString(regionLabel) ? regionLabel : ''), [regionLabel])

    const isDataEmpty = useMemo(
        () => isEmpty(safeActivities) && isEmpty(safeLeisure) && isEmpty(safeSurroundings) && isEmpty(safeJourney),
        [safeActivities, safeLeisure, safeSurroundings, safeJourney],
    )

    const accordionItems = useMemo(() => {
        if (isDataEmpty) {
            return []
        }

        return [
            {
                heading: t('ourLeisureTips'),
                content: !isEmpty(safeActivities) ? <Activities activities={safeActivities} /> : '',
            },
            {
                heading: t('otherRecreationalOpportunities'),
                content: safeLeisure,
            },
            {
                heading: t('holidayDestination', { region: safeRegionLabel }),
                content: safeSurroundings,
            },
            {
                heading: t('gettingThere'),
                content: safeJourney,
            },
        ].filter(item => !isEmpty(item.content))
    }, [isDataEmpty, t, safeActivities, safeLeisure, safeRegionLabel, safeSurroundings, safeJourney])

    if (isEmpty(accordionItems)) {
        return null
    }

    return (
        <NearByWrapper>
            <NearByTitle>
                <Typography variant="subtitle1" fontWeight="highlight">
                    {t('nearByTitle')}
                </Typography>
            </NearByTitle>
            <Accordion items={accordionItems} />
        </NearByWrapper>
    )
}

export default memo(NearBySection)
