import styled, { AnyStyledComponent } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

import Star from '../../../../assets/icons/star.svg'
import Villa from '../../../../assets/icons/villa.svg'
import Wallet from '../../../../assets/icons/wallet.svg'
import Smile from '../../../../assets/icons/smile.svg'
import MapPin from '../../../../assets/icons/map-pin.svg'

export const Container = styled.div``

export const InnerContainer = styled.div`
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0 0`};
`

export const ContentContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => `${theme.spacing.xxSmall} 0`};
`

export const ReviewWrapper = styled.div``

export const RatingWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    width: 100%;
    margin: ${({ theme }) => `${theme.spacing.xSmall} 0`};
`

export const RatingStarContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    padding-right: ${({ theme }) => theme.spacing.xSmall};
    border-right: ${({ theme }) => `1px dotted ${theme.colors.tfwBlack10}`};
`

export const RatingInnerContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    padding-left: ${({ theme }) => theme.spacing.xSmall};
    flex: 1;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex: unset;
    }
`

export const StarIconContainer = styled.div`
    margin: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.xSmall} ${theme.spacing.xSmall} 0`};
    width: ${({ theme }) => theme.layout.xSmall};
    zoom: 1.125;
`
export const IconContainer = styled.div`
    margin: ${({ theme }) => theme.spacing.xSmall};
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
`

export const RatingContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    padding: ${({ theme }) => `${theme.spacing.xxxSmall} 0`};
`

export const StarIcon = styled(Star as AnyStyledComponent)`
    width: 100%;
`

export const VillaIcon = styled(StarIcon).attrs({ as: Villa })`
    width: ${({ theme }) => theme.layout.xxSmall};
    height: ${({ theme }) => theme.layout.xxSmall};
`

export const WalletIcon = styled(StarIcon).attrs({ as: Wallet })`
    width: ${({ theme }) => theme.layout.xxSmall};
    height: ${({ theme }) => theme.layout.xxSmall};
`

export const SmileIcon = styled(StarIcon).attrs({ as: Smile })`
    width: ${({ theme }) => theme.layout.xxSmall};
    height: ${({ theme }) => theme.layout.xxSmall};
`

export const MapPinIcon = styled(StarIcon).attrs({ as: MapPin })`
    width: ${({ theme }) => theme.layout.xxSmall};
    height: ${({ theme }) => theme.layout.xxSmall};
`

export const DottedLine = styled.hr`
    border: 0;
    border-top: 1px dotted #d3d3d3;
    margin: 1rem 0;
    width: 100%;
`

export const ButtonsWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding-top: ${({ theme }) => `${theme.spacing.medium}`};
    gap: ${({ theme }) => theme.spacing.medium};
    @media (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    }
`
