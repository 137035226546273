import React, { FC } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Typography } from 'react-components'
import { OwnerInfoWrapper, OwnerInfoContent, OwnerDetails } from './styles'
import { FormattedContactDetails } from '../../../../models/property-details-page/contact-model'
import get from 'lodash/get'
import { PropertyDetails } from '../../../../transports/property-details-page-transport'
import OwnerImage from '../../contact/owner-image'

export interface ContactProps {
    contact?: FormattedContactDetails
    trust?: Record<string, string | null>
    providerId: PropertyDetails['providerId']
}

const OwnerInfo: FC<ContactProps> = ({ contact, trust = [], providerId }) => {
    return (
        <OwnerInfoWrapper>
            <OwnerInfoContent>
                <OwnerImage
                    imageUrl={contact?.photo}
                    name={contact?.name}
                    providerId={providerId}
                    gender={contact?.gender}
                    borderRadius={'50%'}
                    height={'3.5rem'}
                    width={'3.5rem'}
                />
                <OwnerDetails>
                    {!isEmpty(contact?.name) && (
                        <Typography variant="caption" fontWeight="semiBold">
                            {contact?.name}
                        </Typography>
                    )}
                    {!isEmpty(get(trust, 'onlineSince')) && (
                        <Typography variant="caption" color="tfwBlack40">
                            {get(trust, 'onlineSince')}
                        </Typography>
                    )}
                </OwnerDetails>
            </OwnerInfoContent>
        </OwnerInfoWrapper>
    )
}

export default OwnerInfo
