import React, { FC, useContext, memo, useMemo } from 'react'
import { Typography, ScreenContext } from 'react-components'
import { StyledTitleBar } from './styles'

type TitleBarProps = {
    title: string
}

const TitleBar: FC<TitleBarProps> = ({ title }) => {
    const { isDesktop } = useContext(ScreenContext)
    const variant = useMemo(() => (isDesktop ? 'h5' : 'h6'), [isDesktop])

    return (
        <StyledTitleBar>
            <Typography variant={variant} fontWeight="semiBold" element="h1">
                {title}
            </Typography>
        </StyledTitleBar>
    )
}

export default memo(TitleBar)
