import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
    0%, 80%, 100% {
        transform: scale(0);
        opacity: 0.3;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
`

interface DotProps {
    size: number
    delay: number
}

const Dot = styled.div<DotProps>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.colors.tfwBlack40};
    border-radius: 50%;
    margin: 0 2px;
    animation: ${pulse} 1.5s infinite;
    animation-delay: ${({ delay }) => delay}s;
`

const DotsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

interface PulsatingDotsProps {
    size?: number
    dots?: number
}

const PulsatingDots: React.FC<PulsatingDotsProps> = ({ size = 10, dots = 3 }) => {
    return (
        <DotsWrapper>
            {Array.from({ length: dots }).map((_, index) => (
                <Dot key={index} size={size} delay={index * 0.2} />
            ))}
        </DotsWrapper>
    )
}

export default PulsatingDots
