import React, { FC, useCallback } from 'react'
import {
    Container,
    InnerContainer,
    ReviewWrapper,
    ContentContainer,
    RatingWrapper,
    RatingStarContainer,
    RatingInnerContainer,
    StarIconContainer,
    IconContainer,
    RatingContainer,
    VillaIcon,
    WalletIcon,
    SmileIcon,
    MapPinIcon,
    StarIcon,
    DottedLine,
    ButtonsWrapper,
} from './styles'
import { useTranslation } from '../../../../hooks/locale'
import { Typography } from 'react-components'
import { FormattedRating } from '../../../../models/property-details-page/reviews-model'
import usePagination from '../../../../hooks/pagination'
import { StyledGhostButton, CTAButton } from '../../style'
import AnalyticsService from '../../../../services/analytics-service'
import { pdpPageActions } from '../../../../services/analytics-service/actions'
import { pdpPageCategories } from '../../../../services/analytics-service/categories'

type Props = {
    reviews: FormattedRating[]
}

const RATINGS_TO_DISPLAY = 2

const ReviewSection: FC<Props> = ({ reviews }) => {
    const { t } = useTranslation()

    const ratingsData = reviews.map(review => [
        { Icon: StarIcon, rating: review.avg, isStar: true },
        { Icon: VillaIcon, rating: review.features },
        { Icon: WalletIcon, rating: review.price },
        { Icon: SmileIcon, rating: review.service },
        { Icon: MapPinIcon, rating: review.ambience },
    ])

    const { cumulativeData, hasNextPage, hasPreviousPage, goToFirstPage, nextPage } = usePagination(
        reviews,
        RATINGS_TO_DISPLAY,
    )

    const goToNextPage = useCallback(() => {
        setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.SHOW_MORE_REVIEWS_BUTTON_CLICKED,
            })
        }, 0)
        nextPage()
    }, [nextPage])

    const goToInitialState = useCallback(() => {
        setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.HIDE_REVIEWS_CLICKED,
            })
        }, 0)
        goToFirstPage()
    }, [goToFirstPage])

    return (
        <Container>
            <InnerContainer>
                {cumulativeData.map((review, idx) => (
                    <ReviewWrapper key={idx}>
                        <ContentContainer>
                            <Typography variant="subtitle1" fontWeight={'bold'}>
                                {review.title}
                            </Typography>
                            <Typography variant="caption" color="tfwBlack40" fontWeight="semiBold">
                                {t('propertyRatings.reviewerDetials', {
                                    type: review.touristType || '',
                                    lastName: review.contact.lastName || '',
                                    city: review.contact.address.city,
                                    reviewDate: review.ratingDate || '',
                                })}
                            </Typography>
                        </ContentContainer>

                        <ContentContainer>
                            <Typography variant="subtitle2" fontWeight={'semiBold'}>{`${t('travelPeriod')}: ${
                                review.travelDate
                            }`}</Typography>
                            <Typography variant="caption" fontWeight="semiBold" color="tfwBlack40">
                                {`${t('travellingAs')}: ${review.touristType}`}{' '}
                            </Typography>
                        </ContentContainer>

                        <RatingWrapper>
                            {ratingsData[idx].map((data, index) =>
                                data.isStar ? (
                                    <RatingStarContainer key={index}>
                                        <StarIconContainer>
                                            <data.Icon />
                                        </StarIconContainer>
                                        <RatingContainer>
                                            <Typography variant="subtitle2" fontWeight={'semiBold'}>
                                                {data.rating}
                                            </Typography>
                                        </RatingContainer>
                                    </RatingStarContainer>
                                ) : (
                                    <RatingInnerContainer key={index}>
                                        <IconContainer>
                                            <data.Icon />
                                        </IconContainer>
                                        <RatingContainer>
                                            <Typography variant="subtitle2" fontWeight={'semiBold'}>
                                                {data.rating}
                                            </Typography>
                                        </RatingContainer>
                                    </RatingInnerContainer>
                                ),
                            )}
                        </RatingWrapper>

                        <ContentContainer>
                            <Typography variant="subtitle2" fontWeight="regular">
                                {review.text}
                            </Typography>
                        </ContentContainer>

                        {idx < cumulativeData.length - 1 && <DottedLine />}
                    </ReviewWrapper>
                ))}
            </InnerContainer>
            <ButtonsWrapper>
                {hasPreviousPage && (
                    <CTAButton onClick={goToInitialState}>
                        <StyledGhostButton isPrimary={false}>
                            <Typography variant="subtitle2" fontWeight="regular">
                                {t(`propertyRatings.hideRatings`)}
                            </Typography>
                        </StyledGhostButton>
                    </CTAButton>
                )}
                {hasNextPage && (
                    <CTAButton onClick={goToNextPage}>
                        <StyledGhostButton isPrimary={false}>
                            <Typography variant="subtitle2" fontWeight="regular">
                                {t(`propertyRatings.showMoreRatings`)}
                            </Typography>
                        </StyledGhostButton>
                    </CTAButton>
                )}
            </ButtonsWrapper>
        </Container>
    )
}

export default ReviewSection
