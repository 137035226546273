import styled from 'styled-components'

export const LocationTitle = styled.div`
    display: flex;
    flex: 1;
    padding: 16px 0 8px 0;
    font-family: Lato, sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #282828;
    margin-bottom: 8px;
`

export const BlurImg = styled.img`
    display: flex;
    flex: 1;
    height: 13.625rem;
    width: 100%;
`

export const MapContainer = styled.div<{ isVisible: boolean }>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
`
