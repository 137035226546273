import React, { FC, memo } from 'react'
import { StyledStatsBar, StatIconWrapper, StatIcon, StatWrapper } from './styles'
import BedroomIcon from '../../../assets/icons/amenities-icons/bedroom-icon.svg'
import ShowerIcon from '../../../assets/icons/amenities-icons/shower-icon.svg'
import GuestIcon from '../../../assets/icons/amenities-icons/guest-icon.svg'
import RoomArea from '../../../assets/icons/amenities-icons/room-area.svg'
import { Typography } from 'react-components'
import { FormattedStats } from '../../../models/property-details-page/stats-model'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

type StatsBarProps = {
    stats: FormattedStats | null
}

const statsConfig = [
    { key: 'stats.bedrooms', icon: BedroomIcon },
    { key: 'stats.bathrooms', icon: ShowerIcon },
    { key: 'stats.personCount', icon: GuestIcon },
    { key: 'stats.qmSize', icon: RoomArea },
]

const StatsBar: FC<StatsBarProps> = ({ stats }) => {
    if (!stats || isEmpty(get(stats, 'stats'))) {
        return null
    }

    return (
        <StyledStatsBar>
            {statsConfig.map(({ key, icon }) => {
                const value = get(stats, key)
                if (!value) {
                    return null
                }
                return (
                    <StatWrapper key={key}>
                        <StatIconWrapper>
                            <StatIcon as={icon} />
                        </StatIconWrapper>
                        <Typography variant="subtitle2">{value}</Typography>
                    </StatWrapper>
                )
            })}
        </StyledStatsBar>
    )
}

export default memo(StatsBar)
