import React, { FC } from 'react'
import {
    Container,
    RatingWrapper,
    RatingInnerContainer,
    IconContainer,
    RatingContainer,
    VillaIcon,
    WalletIcon,
    SmileIcon,
    MapPinIcon,
} from './styles'
import { useTranslation } from '../../../../hooks/locale'
import { Typography } from 'react-components'
import { FormattedRatingsBadgeTransport } from '../../../../models/property-details-page/reviews-model'

type Props = {
    rating: FormattedRatingsBadgeTransport
}

const RatingSection: FC<Props> = ({ rating }) => {
    const { t } = useTranslation()

    const ratingsData = [
        { Icon: VillaIcon, rating: rating.features, ratingCategory: t('propertyRatings.facilitiesCategory') },
        { Icon: WalletIcon, rating: rating.price, ratingCategory: t('propertyRatings.priceServicesCategory') },
        { Icon: SmileIcon, rating: rating.service, ratingCategory: t('propertyRatings.serviceCategory') },
        { Icon: MapPinIcon, rating: rating.ambience, ratingCategory: t('propertyRatings.surroundingsCategory') },
    ]

    return (
        <Container>
            <RatingWrapper>
                {ratingsData.map((data, index) => (
                    <RatingInnerContainer key={index}>
                        <IconContainer>
                            <data.Icon />
                        </IconContainer>
                        <RatingContainer>
                            <Typography variant="subtitle2" fontWeight={'semiBold'}>
                                {data.rating}
                            </Typography>
                            <Typography variant="caption" color="tfwBlack40" fontWeight={'regular'}>
                                {data.ratingCategory}
                            </Typography>
                        </RatingContainer>
                    </RatingInnerContainer>
                ))}
            </RatingWrapper>
        </Container>
    )
}

export default RatingSection
