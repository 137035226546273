import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { ThumbnailIcon } from './styles'
import LeftNaviagtor from '../../../assets/icons/left-navigator.svg'

const ThumbnailBack: FC<{}> = () => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex !== 0 ? <ThumbnailIcon as={LeftNaviagtor as AnyStyledComponent} left /> : null
}

export default memo(ThumbnailBack)
