import React, { useContext } from 'react'
import { SharePopoverHeader as StyledHeader } from './styles'
import { Typography } from 'react-components'
import { useTranslation } from '../../../../hooks/locale'
import CloseIcon from '../../../../assets/icons/close.svg'
import { AnyStyledComponent, ThemeContext } from 'styled-components'
import { ShareIconWrapper } from '../styles'

const SharePopoverHeader = ({ onClose }: { onClose: () => void }) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    return (
        <StyledHeader>
            <Typography variant="subtitle1" fontWeight="semiBold">
                {t('shareHeaderTitle')}
            </Typography>
            <ShareIconWrapper
                onClick={onClose}
                as={CloseIcon as AnyStyledComponent}
                alt="share-icon"
                width={theme.spacing.large}
                height={theme.spacing.large}
            />
        </StyledHeader>
    )
}

export default SharePopoverHeader
