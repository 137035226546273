import React from 'react'
import { Typography } from 'react-components'
import { PriceComponentSeparator } from './styles'
import { SectionTitleWrapper } from '../style'
import { useTranslation } from '../../../hooks/locale'
import styled from 'styled-components'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'

const OptionalCostWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    gap: ${({ theme }) => theme.spacing.small};
    margin-bottom: ${({ theme }) => theme.spacing.xxSmall};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

type OptionalCostsProps = {
    optionalCosts?: FormattedLivePricingDataTransport['optionalCosts']
}

const OptionalCosts: React.FC<OptionalCostsProps> = ({ optionalCosts }) => {
    const { t } = useTranslation()
    if (!optionalCosts || optionalCosts.length === 0) {
        return null
    }

    return (
        <>
            <PriceComponentSeparator />
            <SectionTitleWrapper>
                <Typography variant="subtitle1" fontWeight="semiBold">
                    {t('price.optionalCosts.title')}
                </Typography>
            </SectionTitleWrapper>
            {optionalCosts.map((cost, index) => (
                <OptionalCostWrapper key={index} className="optional-cost-item">
                    <Typography variant="subtitle2" fontWeight="regular">
                        {cost.name.replaceAll('~', ',').toString()}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                        {cost.price} {cost.unit || ''}
                    </Typography>
                </OptionalCostWrapper>
            ))}
        </>
    )
}

export default OptionalCosts
