import { TranslationFunction } from '../../common/types'

export interface FormattedSuitedItem {
    suitedId: number
    label: string
}

export interface FormattedSuitedMap {
    [key: number]: FormattedSuitedItem
}

export enum SuitedTypes {
    SUITED_FAMILY = 1,
    SUITED_WITH_DOG = 2,
    SUITED_WITH_BABY = 3,
    SUITED_NON_SMOKING = 4,
    SUITED_SMOKING = 5,
    SUITED_WHEELCHAIR = 6,
    SUITED_BARRIER_FREE = 7,
    SUITED_LONG_TERM = 8,
    SUITED_ALLERGY_FREE = 9,
    SUITED_FITTERS = 10,
}

export class Suited {
    private readonly suitedTranslationKeys: Record<number, string> = {
        [SuitedTypes.SUITED_FAMILY]: 'familySuitable',
        [SuitedTypes.SUITED_WITH_DOG]: 'dogsSuitable',
        [SuitedTypes.SUITED_WITH_BABY]: 'babiesSuitable',
        [SuitedTypes.SUITED_NON_SMOKING]: 'nonSmokingSuitable',
        [SuitedTypes.SUITED_SMOKING]: 'smokingSuitable',
        [SuitedTypes.SUITED_WHEELCHAIR]: 'wheelChairSuitable',
        [SuitedTypes.SUITED_BARRIER_FREE]: 'barrierFreeSuitable',
        [SuitedTypes.SUITED_LONG_TERM]: 'longTermHolidaySuitable',
        [SuitedTypes.SUITED_ALLERGY_FREE]: 'allergicSuitable',
        [SuitedTypes.SUITED_FITTERS]: 'fittersSuitable',
    }

    constructor(private __: TranslationFunction) {
        this.__ = __
    }

    public format(suitedIds: number[]): FormattedSuitedMap {
        const formattedMap: FormattedSuitedMap = {}

        suitedIds.forEach(suitedId => {
            const translationKey = this.suitedTranslationKeys[suitedId]
            const label = this.__(translationKey)
            formattedMap[suitedId] = {
                suitedId,
                label,
            }
        })

        return formattedMap
    }
}
