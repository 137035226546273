export enum RentNotesArrivalTime {
    BY_ARRANGEMENT = 1,
    ONE_OCLOCK = 2,
    TWO_OCLOCK = 3,
    THREE_OCLOCK = 4,
    FOUR_OCLOCK = 5,
    FIVE_OCLOCK = 6,
    SIX_OCLOCK = 7,
    SEVEN_OCLOCK = 8,
    EIGHT_OCLOCK = 9,
    NINE_OCLOCK = 10,
    TEN_OCLOCK = 11,
    ELEVEN_OCLOCK = 12,
    TWELVE_OCLOCK = 13,
    THIRTEEN_OCLOCK = 14,
    FOURTEEN_OCLOCK = 15,
    FIFTEEN_OCLOCK = 16,
    SIXTEEN_OCLOCK = 17,
    SEVENTEEN_OCLOCK = 18,
    EIGHTEEN_OCLOCK = 19,
    NINETEEN_OCLOCK = 20,
    TWENTY_OCLOCK = 21,
    TWENTY_ONE_OCLOCK = 22,
    TWENTY_TWO_OCLOCK = 23,
    TWENTY_THREE_OCLOCK = 24,
    ZERO_OCLOCK = 25,
}
