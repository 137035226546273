import React, { useState, useContext, useRef, useEffect } from 'react'
import {
    RightSubContainer,
    StickyContainer,
    HeaderBar,
    LeftSubContainer,
    HeaderBarContent,
} from '../../landing-page/header-section/header-section-styles'
import { Header, Typography, ScreenContext } from 'react-components'
import { StyledGhostButton, CTAButton } from '../style'
import { useTranslation } from '../../../hooks/locale'
import { BackIcon } from '../../common/icons'
import { PreviewTitleWrapper } from './styles'

interface Props {
    listingId: string
}

const PreviewHeader: React.FC<Props> = ({ listingId }) => {
    const { t } = useTranslation()
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const { isDesktop } = useContext(ScreenContext)
    const [isLoading, setIsLoading] = useState(false)

    const backLink = listingId
        ? `https://www.traum-ferienwohnungen.de/vermieterbereich/inserate/${listingId}/anzeigen/de/`
        : null

    const onBackLinkClick = (event: React.PointerEvent) => {
        if (backLink) {
            setIsLoading(true)
            event.preventDefault()
            setIsLoading(true)
            window.open(backLink, '_self')
            timeoutRef.current = setTimeout(() => {
                setIsLoading(false)
            }, 5000)
        }
    }

    // Clear timeout on unmount
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    return (
        <StickyContainer>
            <Header id="preview-header">
                <HeaderBar>
                    <HeaderBarContent>
                        <LeftSubContainer>
                            {!isDesktop && <BackIcon onClick={onBackLinkClick} />}
                            <PreviewTitleWrapper>
                                <Typography variant="subtitle1" fontWeight="semiBold">
                                    {t('preview.title')}
                                </Typography>
                            </PreviewTitleWrapper>
                        </LeftSubContainer>
                        {!!backLink && isDesktop && (
                            <RightSubContainer>
                                <CTAButton>
                                    <StyledGhostButton
                                        isPrimary
                                        href={backLink}
                                        disabled={isLoading}
                                        loading={isLoading}
                                        isContentWidth={false}
                                        buttonSize="large"
                                    >
                                        <Typography variant="subtitle2" fontWeight="semiBold">
                                            {t('preview.backButtonText')}
                                        </Typography>
                                    </StyledGhostButton>
                                </CTAButton>
                            </RightSubContainer>
                        )}
                    </HeaderBarContent>
                </HeaderBar>
            </Header>
        </StickyContainer>
    )
}

export default PreviewHeader
