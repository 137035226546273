import { TranslationFunction } from '../../common/types'
import {
    PropertyDetails,
    PropertyDetailsRatingsTransport,
    PropertyDetailsRatingSummary,
} from '../../transports/property-details-page-transport'

export class Trust {
    private __: TranslationFunction

    constructor(translationFunction: TranslationFunction) {
        this.__ = translationFunction
    }

    format(expose: {
        ratings: PropertyDetailsRatingsTransport
        general: PropertyDetails
    }): Record<string, string | null> {
        const extended: Record<string, string | null> = {}
        extended['recommendationPercentage'] = this.getRecommendationPercentage(expose.ratings.summary)
        extended['onlineSince'] = this.getReleaseDate(expose)
        extended['ratingsCount'] = this.getRatingsCount(expose.ratings.summary)
        return extended
    }

    private getRecommendationPercentage(ratings: PropertyDetailsRatingSummary): string | null {
        if (ratings.count > 0 && ratings.recommendations) {
            const percentage = Math.round((ratings.recommendations / ratings.count) * 1000) / 10
            if (percentage > 80) {
                return this.__('trustRecommendationPercentage', { percentage })
            }
        }
        return null
    }

    private getReleaseDate(expose: { general: PropertyDetails }): string | null {
        const releaseDate = expose.general.releaseDate

        if (releaseDate) {
            const diff = this.calculateDateDiff(new Date(), new Date(releaseDate))
            if (diff.years > 0) {
                return this.__('trustOnlineDuration', { count: diff.years })
            }
        }
        return null
    }

    private getRatingsCount(ratings: PropertyDetailsRatingSummary): string | null {
        if (ratings.average >= 4 && ratings.count > 0) {
            return this.__('ratingsCount', { count: ratings.count })
        }
        return null
    }

    private calculateDateDiff(date1: Date, date2: Date): { years: number; months: number; days: number } {
        const diff: { years: number; months: number; days: number } = { years: 0, months: 0, days: 0 }
        const duration = new Date(date1.getTime() - date2.getTime())
        diff.years = duration.getUTCFullYear() - 1970
        diff.months = duration.getUTCMonth()
        diff.days = duration.getUTCDate() - 1
        return diff
    }
}
