import React, { FC, useState } from 'react'
import { Typography } from 'react-components'
import { CTAButton, StyledGhostButton } from '../style'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import get from 'lodash/get'
import isInquiryButtonVisible from '../side-bar/utils/is-inquiry-button-visible'
import getChildrenAges from '../utils/get-children-ages'
import useDynamicQueryParams from '../hooks/use-dynamic-query-params'
import AnalyticsService from '../../../services/analytics-service'
import { pdpPageActions } from '../../../services/analytics-service/actions'
import { pdpPageCategories } from '../../../services/analytics-service/categories'
import { getSearchDate } from '../../../utils/miscellaneous-utils'
import updateDateRetentionCookies from '../utils/update-date-retention-cookies'
import { useQuery } from '../../../hooks/query'

type BookNowButtonProps = {
    bookingType: FormattedBookingTypeData | null
    searchbar: SearchbarFieldsReturn
    showShortTitle?: boolean
    position: 'gallery' | 'sidebar' | 'sticky_footer' | 'price_widget'
}

const BookNowButton: FC<BookNowButtonProps> = props => {
    const [isLoading, setIsLoading] = useState(false)
    const { bookingType, searchbar, position, showShortTitle = false } = props
    const query = useQuery()
    const dynamicQueryParams = JSON.parse(get(bookingType, 'dynamicQueryParams', '') || '')
    const childrenAges = getChildrenAges(searchbar.kids)
    const qpValues = { ...query, ...searchbar, childrenAges }
    const url = useDynamicQueryParams(bookingType, qpValues, dynamicQueryParams)
    const isPrimary = !isInquiryButtonVisible({ bookingType })
    const type = get(bookingType, 'type')
    const titleKey = showShortTitle ? 'shortTitle' : 'title'

    const onBookNowClick = (event: React.MouseEvent) => {
        setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.BOOK_NOW_BUTTON_CLICKED,
                label: position,
            })
        }, 0)
        const arrival = getSearchDate(searchbar.arrival)
        const departure = getSearchDate(searchbar.departure)
        const adultsCount = searchbar.adults
        const childrenCount = searchbar.kids
        updateDateRetentionCookies(arrival, departure, adultsCount, childrenCount, searchbar.location)
        if (type === 'redirect') {
            event.preventDefault()
            setIsLoading(true)
            window.open(url, '_blank')
            setIsLoading(false)
        } else {
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
            }, 5000)
        }
    }

    return (
        <CTAButton onClick={onBookNowClick}>
            <StyledGhostButton
                isPrimary={isPrimary}
                disabled={isLoading ? true : undefined}
                loading={isLoading ? true : undefined}
                href={url}
                buttonSize="large"
            >
                <Typography variant="subtitle2" fontWeight="semiBold">
                    {get(bookingType, titleKey)}
                </Typography>
            </StyledGhostButton>
        </CTAButton>
    )
}

export default BookNowButton
