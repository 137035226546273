import { BookingCancellationPolicy as BookingCancellationPolicyTransport } from '../../transports/property-details-page-transport'
import forEach from 'lodash/forEach'

// Constants for colors and icons
const Colors = {
    ERROR_RED: 'tfwNegativeMedium',
    GREEN: 'tfwPositiveMedium',
    YELLOW: 'tfwAlertMedium',
} as const

const Icons = {
    ALERT_TRIANGLE: 'alertTriangle',
    CHECKED_CIRCLE: 'checkedCircle',
    WARNING_TRIANGLE: 'warningTriangle',
} as const

export interface FormattedRule {
    daysFrom: number
    daysTo: number
    percent: number
    color: typeof Colors[keyof typeof Colors]
    icon: typeof Icons[keyof typeof Icons]
}

interface FormattedSlabRules {
    fullRefund?: FormattedRule[]
    noRefund?: FormattedRule[]
    partialRefund?: FormattedRule[]
}

interface FormattedRules {
    noRefund?: FormattedRule[]
    fullRefund?: FormattedRule[]
    slab?: FormattedSlabRules
}

export interface FormattedBookingCancellationPolicy {
    bookingCancellationPolicyId: number
    rules: FormattedRules
}

export class BookingCancellationPolicy {
    public format(
        bookingCancellationPolicy: BookingCancellationPolicyTransport | null,
    ): FormattedBookingCancellationPolicy | null {
        if (!bookingCancellationPolicy) {
            return null
        }

        const formattedRules: FormattedRules = {
            noRefund: [],
            fullRefund: [],
            slab: {
                fullRefund: [],
                noRefund: [],
                partialRefund: [],
            },
        }

        forEach(bookingCancellationPolicy.rules, rule => {
            const formattedRule = this.createFormattedRule(rule)

            if (rule.daysFrom === 0 && rule.daysTo === 9999) {
                // Handle noRefund and fullRefund rules
                if (rule.percent === 100) {
                    formattedRules.noRefund?.unshift(formattedRule)
                } else if (rule.percent === 0) {
                    formattedRules.fullRefund?.unshift(formattedRule)
                }
            } else {
                // Handle slab rules
                this.addSlabRule(formattedRules.slab!, formattedRule)
            }
        })

        return {
            bookingCancellationPolicyId: bookingCancellationPolicy.id,
            rules: formattedRules,
        }
    }

    private createFormattedRule(rule: { daysFrom: number; daysTo: number; percent: number }): FormattedRule {
        return {
            daysFrom: rule.daysFrom,
            daysTo: rule.daysTo,
            percent: rule.percent,
            color: this.getColor(rule.percent),
            icon: this.getIcon(rule.percent),
        }
    }

    private addSlabRule(slab: FormattedSlabRules, rule: FormattedRule): void {
        switch (rule.percent) {
            case 0:
                slab.fullRefund?.unshift(rule)
                break
            case 100:
                slab.noRefund?.unshift(rule)
                break
            default:
                slab.partialRefund?.unshift(rule)
                break
        }
    }

    private getColor(percent: number): typeof Colors[keyof typeof Colors] {
        if (percent === 100) {
            return Colors.ERROR_RED
        }
        if (percent === 0) {
            return Colors.GREEN
        }
        return Colors.YELLOW
    }

    private getIcon(percent: number): typeof Icons[keyof typeof Icons] {
        if (percent === 100) {
            return Icons.ALERT_TRIANGLE
        }
        if (percent === 0) {
            return Icons.CHECKED_CIRCLE
        }
        return Icons.WARNING_TRIANGLE
    }
}
