import { ExposeTransport, PropertyOverview } from '../../transports/property-details-page-transport' // Assuming Expose is already imported

export interface PropertyDescriptionTransport {
    text: string
    special: string
    leisure: string
    regionLabel: string
    surroundings: string
    journey: string
    additionalEquipment: string | null
}

export class PropertyDescription {
    private data: PropertyOverview // Assuming Expose contains description

    constructor(expose: ExposeTransport) {
        this.data = expose.objectDescription
    }

    /**
     * Format the property description details from Expose.
     *
     * @returns PropertyDescriptionData
     */
    public format(): PropertyDescriptionTransport {
        return {
            text: this.data.characteristics,
            special: this.data.specialFeatures,
            leisure: this.data.leisure,
            regionLabel: '',
            surroundings: this.data.surrounding,
            journey: this.data.arrival,
            additionalEquipment: this.data.additionalEquipment,
        }
    }
}
