import styled, { keyframes, css } from 'styled-components'
import { tabletBreakpoint, GhostButton } from 'react-components'
import CloseIcon from '../../assets/icons/close.svg'

export const ExposeContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    @media (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1080px;
        margin: auto;
        padding: 0 24px;
        flex-direction: row;
    }
`

export const ExposeMobileWrapper = styled.div`
    display: block;
    width: 100%;
    @media (min-width: ${tabletBreakpoint.min}px) {
        display: none;
    }
`

export const ExposeDesktopWrapper = styled.div`
    display: none;
    @media (min-width: ${tabletBreakpoint.min}px) {
        display: ${({ theme }) => theme.style.display.block};
    }
`

export const Content = styled.div`
    flex: 1 1 100%;
    margin-bottom: 32px;
    @media (min-width: ${tabletBreakpoint.min}px) {
        flex: 1 1 65%;
        max-width: 65%;
        margin-right: ${({ theme }) => theme.spacing.xSmall};
    }
`

export const Sidebar = styled.div`
    display: none;
    @media (min-width: ${tabletBreakpoint.min}px) {
        display: ${({ theme }) => theme.style.display.flex};
        flex-direction: ${({ theme }) => theme.style.flexDirection.column};
        flex: 1;
        position: sticky;
        top: 110px;
        height: fit-content;
        border: ${({ theme }) => `1px solid ${theme.colors.tfwBlack10}`};
        box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08), 0px 4px 16px 0px rgba(0, 0, 0, 0.08),
            0px 16px 48px 0px rgba(0, 0, 0, 0.08);
        padding: ${({ theme }) => `${theme.spacing.medium} 0`};
        margin-left: ${({ theme }) => theme.spacing.medium};
        border-radius: ${({ theme }) => theme.typography.border.radius.medium};
        margin-top: 110px;
        margin-bottom: 5rem;
    }
`

export const ComponentSeparator = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    height: 1px;
    background-color: #d3d3d3;
    margin: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.xxSmall} 0`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => `${theme.spacing.xLarge} 0 ${theme.spacing.medium} 0`};
    }
`

export const ExposeSectionTitleWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.xSmall}`};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    gap: ${({ theme }) => theme.spacing.xSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    }
`

export const SectionTitleWrapper = styled.div<{ size?: 'header' | undefined }>`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme, size }) => (size === 'header' ? `${theme.spacing.small} 0` : `${theme.spacing.xSmall} 0`)};
`

export const DottedSeparator = styled.div`
    border-bottom: 1px dotted ${({ theme }) => theme.colors.tfwBlack10};
    width: 100%;
    margin: ${({ theme }) => theme.spacing.xSmall} 0;
    height: 0;
`

type StyledGhostButtonProps = {
    isPrimary?: boolean
    isContentWidth?: boolean
    loading?: boolean
    buttonSize?: 'large' | 'medium'
}

// Define the keyframes for the spinner animation
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const StyledGhostButton = styled(GhostButton).attrs<StyledGhostButtonProps>(({ isPrimary, theme }) => ({
    hoverEffect: true,
    borderColor: isPrimary ? theme.colors.tfwYellow : theme.colors.tfwBlack87,
    backgroundColor: isPrimary ? theme.colors.tfwYellow : theme.colors.white,
    hoverColor: isPrimary ? theme.colors.tfwBlack87 : theme.colors.white,
    hoverBorderColor: isPrimary ? theme.colors.tfwYellowHover : theme.colors.tfwBlack87,
    hoverBackgroundColor: isPrimary ? theme.colors.tfwYellowHover : theme.colors.tfwBlack87,
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
}))<StyledGhostButtonProps>`
    display: flex;
    flex: ${props => (props.isContentWidth ? 'unset' : 1)};
    justify-content: center;
    align-items: center;
    padding: ${props => `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
    position: relative;
    height: ${props => (props.buttonSize === 'large' ? '3rem' : '2.5rem')};

    /* Hide button text when loading */
    & > span {
        visibility: ${props => (props.loading ? 'hidden' : 'visible')};
    }

    /* Add spinner when loading */
    &::after {
        content: '';
        display: ${props => (props.loading ? 'flex' : 'none')};
        width: 1.5rem;
        height: 1.5rem;
        border: 3px solid ${props => props.theme.colors.tfwBlack99};
        border-top-color: transparent;
        border-radius: 50%;
        animation: ${spin} 1s linear infinite;
        position: absolute;
        transform: translate(-50%, -50%);
    }

    &:hover {
        & > span {
            color: ${props => (props.isPrimary ? props.theme.colors.tfwBlack87 : props.theme.colors.white)};
        }
    }
`

export const CTAButton = styled.div<{ contentAlignment?: string; margin?: string }>`
    display: flex;
    flex: 1;
    width: 100%;
    min-width: fit-content;
    margin: ${props => (props.margin ? props.margin : 0)};
    justify-content: ${props => (props.contentAlignment ? props.contentAlignment : 'center')};
`

export const StyledCloseIcon = styled(CloseIcon)`
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
`

export const FooterWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.tfwWhite64};
        padding-bottom: 6.5rem;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            padding-bottom: 0;
        }
    `,
)
