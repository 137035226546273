import { Image } from '../../transports/property-details-page-transport'

export interface GalleryImages {
    src: string
    caption: string
    thumbnail: string
}

export class Gallery {
    private static thumbnailSize = 11 // Default thumbnail size

    public images: GalleryImages[] // This will hold the processed images

    /**
     * Constructor to process and store formatted gallery images.
     *
     * @param galleryImages - List of input images with URL and title
     */
    constructor(galleryImages: Image[]) {
        this.images = galleryImages.map(image => {
            const imageUrl = image.url
            const processedImage: GalleryImages = {
                src: imageUrl,
                caption: '',
                thumbnail: '',
            }

            processedImage.caption = image.title
            processedImage.thumbnail = imageUrl.replace(/\/46\//, `/${Gallery.thumbnailSize}/`)

            return processedImage
        })
    }
}
