export enum FinalPaymentTime {
    BY_ARRANGEMENT = 1,
    UPON_ARRIVAL = 2,
    AT_DEPARTURE = 3,
    DAYS_BEFORE_ARRIVAL = 10,
    WEEK_1_BEFORE_ARRIVAL = 4,
    WEEK_2_BEFORE_ARRIVAL = 5,
    WEEK_3_BEFORE_ARRIVAL = 6,
    WEEK_4_BEFORE_ARRIVAL = 7,
    WEEK_5_BEFORE_ARRIVAL = 8,
    WEEK_6_BEFORE_ARRIVAL = 9,
}
