export enum PrePaymentTime {
    BY_ARRANGEMENT = 1,
    AT_TIME_OF_BOOKING = 2,
    UPON_ARRIVAL = 3,
    ONE_WEEK_BEFORE_ARRIVAL = 4,
    TWO_WEEKS_BEFORE_ARRIVAL = 5,
    THREE_WEEKS_BEFORE_ARRIVAL = 6,
    FOUR_WEEKS_BEFORE_ARRIVAL = 7,
    FIVE_WEEKS_BEFORE_ARRIVAL = 8,
    SIX_WEEKS_BEFORE_ARRIVAL = 9,
    DAYS_DUE_AFTER_BOOKING = 10,
}
