import { TranslationFunction } from '../../common/types'

export enum PropertyTypeEnum {
    HOLIDAY_APARTMENT = 1,
    HOLIDAY_HOUSE = 2,
    VILLA = 3,
    APARTMENT = 4,
    CHALET = 5,
    FINCA = 6,
    BUNGALOW = 7,
    FARMHOUSE = 8,
    COTTAGE = 9,
    HOUSE_BOAT = 10,
    B_AND_B = 11,
    HOLIDAY_ROOM = 12,
    ALPINE_HUT = 13,
    PENSION = 14,
    CASTLE = 15,
    GUESTROOM = 16,
    RESIDENCE = 17,
    GUESTHOUSE = 18,
    CAMPING_SITE = 19,
    HOTEL = 20,
    SKI_LODGE = 21,
    VINEYARD = 22,
    HORSE_FARM = 23,
    SAILING_SHIP = 24,
    TRADE_FAIR_ROOM = 25,
    CARAVAN = 26,
    ROW_HOUSE = 27,
    CAVE_DWELLING = 28,
    BIO_FARM = 29,
    APARTHOTEL = 30,
    HOLIDAY_CONDO = 31,
    HOLIDAY_VILLAGE = 32,
    HOLIDAY_FARMHOUSE = 33,
    HOLIDAY_PARK = 34,
    HOLIDAY_APARTMENTS = 35,
    INN = 36,
    MANOR = 37,
    YOUTH_HOSTEL = 38,
    MOTEL = 39,
    HOLIDAY_HOUSE_PARK = 40,
    APARTMENTS = 41,
    HEALTH_RESORT = 42,
    SPA_HOTEL = 43,
}

export interface PropertyTypeConfig {
    label: string
    article: string
}

export class PropertyTypeConfigFactory {
    private static instance: PropertyTypeConfigFactory
    private __: TranslationFunction

    private constructor(translationFunction: TranslationFunction) {
        this.__ = translationFunction
    }

    public static getInstance(translationFunction: TranslationFunction): PropertyTypeConfigFactory {
        if (!PropertyTypeConfigFactory.instance) {
            PropertyTypeConfigFactory.instance = new PropertyTypeConfigFactory(translationFunction)
        }
        return PropertyTypeConfigFactory.instance
    }

    public getConfig(propertyType: PropertyTypeEnum): PropertyTypeConfig {
        const baseConfig = this.getBaseConfig(propertyType)

        return {
            ...baseConfig,
            label: this.__(baseConfig.label, { count: 1 }),
            article: this.__(baseConfig.article),
        }
    }

    private getBaseConfig(propertyType: PropertyTypeEnum): PropertyTypeConfig {
        switch (propertyType) {
            case PropertyTypeEnum.HOLIDAY_APARTMENT:
                return {
                    label: 'holidayApartment',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.HOLIDAY_HOUSE:
                return {
                    label: 'holidayHouse',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.VILLA:
                return {
                    label: 'villa',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.APARTMENT:
                return {
                    label: 'apartment',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.CHALET:
                return {
                    label: 'chalet',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.FINCA:
                return {
                    label: 'finca',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.BUNGALOW:
                return {
                    label: 'bungalow',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.FARMHOUSE:
                return {
                    label: 'farmhouse',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.COTTAGE:
                return {
                    label: 'cottage',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOUSE_BOAT:
                return {
                    label: 'houseBoat',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.B_AND_B:
                return {
                    label: 'bedAndBreakfast',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOLIDAY_ROOM:
                return {
                    label: 'holidayRoom',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.ALPINE_HUT:
                return {
                    label: 'mountainHut',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.PENSION:
                return {
                    label: 'pension',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.CASTLE:
                return {
                    label: 'castle',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.GUESTROOM:
                return {
                    label: 'guestroom',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.RESIDENCE:
                return {
                    label: 'residence',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.GUESTHOUSE:
                return {
                    label: 'guestHouse',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.CAMPING_SITE:
                return {
                    label: 'campingSite',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.HOTEL:
                return {
                    label: 'hotel',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.SKI_LODGE:
                return {
                    label: 'skiLodge',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.VINEYARD:
                return {
                    label: 'vineyard',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.HORSE_FARM:
                return {
                    label: 'horseFarm',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.SAILING_SHIP:
                return {
                    label: 'sailingShip',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.TRADE_FAIR_ROOM:
                return {
                    label: 'tradeFairRoom',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.CARAVAN:
                return {
                    label: 'caravan',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.ROW_HOUSE:
                return {
                    label: 'rowHouse',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.CAVE_DWELLING:
                return {
                    label: 'caveApartment',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.BIO_FARM:
                return {
                    label: 'bioFarm',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.APARTHOTEL:
                return {
                    label: 'aparthotel',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOLIDAY_CONDO:
                return {
                    label: 'holidayCondo',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOLIDAY_VILLAGE:
                return {
                    label: 'holidayVillage',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOLIDAY_FARMHOUSE:
                return {
                    label: 'holidayFarm',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.HOLIDAY_PARK:
                return {
                    label: 'holidayPark',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.HOLIDAY_APARTMENTS:
                return {
                    label: 'holidayApartments',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.INN:
                return {
                    label: 'inn',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.MANOR:
                return {
                    label: 'manor',
                    article: 'propertyTypeArticles.masculineThe',
                }

            case PropertyTypeEnum.YOUTH_HOSTEL:
                return {
                    label: 'youthHostel',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.MOTEL:
                return {
                    label: 'motel',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.HOLIDAY_HOUSE_PARK:
                return {
                    label: 'holidayPark',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.APARTMENTS:
                return {
                    label: 'apartments',
                    article: 'propertyTypeArticles.feminineThe',
                }

            case PropertyTypeEnum.HEALTH_RESORT:
                return {
                    label: 'healthResort',
                    article: 'propertyTypeArticles.neutralThe',
                }

            case PropertyTypeEnum.SPA_HOTEL:
                return {
                    label: 'wellnessHotel',
                    article: 'propertyTypeArticles.neutralThe',
                }

            default:
                throw new Error(`No configuration found for property type: ${propertyType}`)
        }
    }
}

export class PropertyType {
    private __: TranslationFunction
    public id: PropertyTypeEnum
    private config: PropertyTypeConfig

    constructor(id: PropertyTypeEnum, translationFunction: TranslationFunction) {
        this.__ = translationFunction
        this.id = id
        this.config = PropertyTypeConfigFactory.getInstance(this.__).getConfig(id)
    }

    getLabel(): string {
        return this.config.label
    }

    getConfig(): PropertyTypeConfig {
        return this.config
    }
}
