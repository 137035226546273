import { PropertyLocationDetails, SurroundingLocation } from '../../transports/property-details-page-transport'

export class Surrounding {
    /**
     * Format the surrounding locations.
     *
     * @param expose - The expose object containing location data.
     * @returns Formatted surroundings array.
     */
    format(location: PropertyLocationDetails): Array<SurroundingLocation> {
        const surroundings: Array<SurroundingLocation> = []
        const surroundingLocations = location?.surroundings?.locations || []

        surroundingLocations.forEach(sl => {
            if (sl && typeof sl.type === 'string' && typeof sl.distance === 'number') {
                const unit = sl.distance >= 1000 ? 'km' : 'm'
                const distance = sl.distance >= 1000 ? (sl.distance / 1000).toFixed(1) : sl.distance.toString()
                surroundings.push({
                    type: sl.type,
                    distance: `${Number.parseFloat(distance)} ${unit}`,
                })
            }
        })

        return surroundings
    }
}
