import React from 'react'
import { Typography } from 'react-components'
import { DottedSeparator, SectionTitleWrapper } from '../style'
import { useTranslation } from '../../../hooks/locale'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'
import { PriceCostContentWrapper } from './styles'

type InclusiveCostsProps = {
    inclusiveCosts?: FormattedLivePricingDataTransport['inclusiveCosts']
}

const InclusiveCosts: React.FC<InclusiveCostsProps> = ({ inclusiveCosts }) => {
    const { t } = useTranslation()
    if (!inclusiveCosts || inclusiveCosts.length === 0) {
        return null
    }
    const costsString = inclusiveCosts
        .map(function (cost) {
            return cost.name
        })
        .join(', ')

    return (
        <>
            <DottedSeparator />
            <SectionTitleWrapper>
                <Typography variant="subtitle1" fontWeight="bold">
                    {t('price.inclusiveCosts.title')}
                </Typography>
            </SectionTitleWrapper>
            <PriceCostContentWrapper>
                <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                    {t('price.inclusiveCosts.description', { costs: costsString })}
                </Typography>
            </PriceCostContentWrapper>
        </>
    )
}

export default InclusiveCosts
