import { useState, useEffect, useMemo, useCallback } from 'react'
import PricingClient from '../clients/pricing-client'
import LivePricingModel from '../models/pricing/live-pricing-model'
import LivePricingResponseTransport, { LivePricingParamsTransport } from '../transports/live-pricing-transport'
import { getModel } from '../utils/model-utils'
import { isValidPeriod, isDateInArray } from '../utils/date-utils'
import isEmpty from 'lodash/isEmpty'
import { FormatDateFunction, TranslationFunction } from '../common/types'
import LivePricingFormatter, { FormattedLivePricingDataTransport } from '../models/pricing/live-pricing-formatter'
import isNumber from 'lodash/isNumber'
import { PriceFormatter } from '../hooks/price'

interface UseLivePricingState {
    pricingDetails: FormattedLivePricingDataTransport | null
    isLoading: boolean
    error: number | null
}

enum LPS_ERROR_ENUM {
    GENERAL_ERROR = 0,
    INVALID_ARRIVAL_DAY = 61,
    INVALID_DEPARTURE_DAY = 62,
    TOO_MANY_PERSONS = 43,
    INVALID_ARRIVAL_DEPARTURE_DATE = 48,
}

const isValidAvailabilityData = (params: {
    availableArrivalDates: Date[] | null
    availableDepartureDates: Date[] | null
}): boolean => {
    return !isEmpty(params.availableArrivalDates) && !isEmpty(params.availableDepartureDates)
}

const validatePricingParams = (
    params: LivePricingParamsTransport,
    availableArrivalDates: Date[] | null,
    availableDepartureDates: Date[] | null,
    maxAllowedPerson: number,
): { isValid: boolean; error: number | null } => {
    const arrivalDate = params.arrival
    const departureDate = params.departure
    const totalPersons = params.adults + params.childrenAges.length

    if (totalPersons > maxAllowedPerson) {
        return { isValid: false, error: LPS_ERROR_ENUM.TOO_MANY_PERSONS }
    }
    if (!arrivalDate || !departureDate) {
        return { isValid: false, error: null }
    }
    if (!isValidPeriod(arrivalDate, departureDate)) {
        return { isValid: false, error: LPS_ERROR_ENUM.INVALID_ARRIVAL_DEPARTURE_DATE }
    }
    if (!isValidAvailabilityData({ availableArrivalDates, availableDepartureDates })) {
        return { isValid: false, error: LPS_ERROR_ENUM.INVALID_ARRIVAL_DEPARTURE_DATE }
    }
    if (!isDateInArray(arrivalDate, availableArrivalDates)) {
        return { isValid: false, error: LPS_ERROR_ENUM.INVALID_ARRIVAL_DAY }
    }
    if (!isDateInArray(departureDate, availableDepartureDates)) {
        return { isValid: false, error: LPS_ERROR_ENUM.INVALID_DEPARTURE_DAY }
    }

    return { isValid: true, error: null }
}

export const useLivePricing = (
    params: LivePricingParamsTransport,
    availableArrivalDates: Date[] | null,
    availableDepartureDates: Date[] | null,
    maxAllowedPerson = 12,
    formatDate: FormatDateFunction,
    t: TranslationFunction,
    formatPrice: PriceFormatter,
) => {
    const [state, setState] = useState<UseLivePricingState>({
        pricingDetails: null,
        isLoading: false,
        error: null,
    })

    const livePricingFormatter = new LivePricingFormatter(formatDate, t, formatPrice)
    const paramsKey = useMemo(() => JSON.stringify(params), [params])
    const arrivalDatesKey = useMemo(() => JSON.stringify(availableArrivalDates), [availableArrivalDates])
    const departureDatesKey = useMemo(() => JSON.stringify(availableDepartureDates), [availableDepartureDates])

    const fetchLivePricing = useCallback(async () => {
        const { isValid, error } = validatePricingParams(
            params,
            availableArrivalDates,
            availableDepartureDates,
            maxAllowedPerson,
        )

        if (!isValid) {
            setState({
                pricingDetails: null,
                isLoading: false,
                error,
            })
            return
        }

        setState(prevState => ({ ...prevState, isLoading: true, error: null }))

        try {
            const response = await PricingClient.getLivePrices({ params })

            if (response?.data) {
                const livePricingModel = getModel<LivePricingResponseTransport, LivePricingModel>(
                    response?.data,
                    response.meta,
                )

                const formattedLivePricingModel = livePricingFormatter.getAllFormattedData(livePricingModel)
                setState({
                    pricingDetails: formattedLivePricingModel,
                    isLoading: false,
                    error: null,
                })
            } else {
                throw new Error('No pricing data available.')
            }
        } catch (error: any) {
            setState({
                pricingDetails: null,
                isLoading: false,
                error: isNumber(error.message) ? error.message : LPS_ERROR_ENUM.GENERAL_ERROR,
            })
        }
    }, [paramsKey, arrivalDatesKey, departureDatesKey, maxAllowedPerson])

    useEffect(() => {
        fetchLivePricing()
    }, [fetchLivePricing])
    return state
}
