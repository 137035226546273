import React, { FC, useState, useCallback, useMemo } from 'react'
import { useTranslation } from '../../../hooks/locale'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import Distances from './distances'
import NearBySection from './near-by'
import { Typography } from 'react-components'
import { ComponentSeparator, ExposeDesktopWrapper, ExposeSectionTitleWrapper } from '../style'
import { SurroundingLocation, Location as LocationTransport } from '../../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { SECTION_IDS } from '../constants'
import config from '../../../common/config'
import { BlurImg, MapContainer } from './styles' // Add a styled component for the map container
import { getBasePath } from '../../../utils/miscellaneous-utils'
import LocationBar from '../location-bar'
import { FormattedBreadcrumbTransport } from '../../../models/property-details-page/breadcrumb-model'

type LocationProps = {
    location: LocationTransport
    surroundings: Array<SurroundingLocation>
    activities: string[]
    leisure: string
    journey: string
    surroundingLocation: string
    regionLabel: string
    region: FormattedBreadcrumbTransport | null
}

const googleMapsApiKey = config.gMapKey

const Location: FC<LocationProps> = ({
    location,
    surroundings,
    activities,
    leisure,
    surroundingLocation,
    journey,
    regionLabel,
    region,
}) => {
    const { t } = useTranslation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey,
    })

    const containerStyle = {
        height: '13.625rem',
    }

    const latitude = get(location, 'latitude')
    const longitude = get(location, 'longitude')

    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false) // Track if the map is fully loaded

    const center = useMemo(() => {
        return {
            lat: latitude,
            lng: longitude,
        }
    }, [latitude, longitude])

    const onLoad = useCallback(
        (map: google.maps.Map) => {
            map.setCenter(center)
            map.setZoom(15)
            setMap(map) // Set the map state
            setIsMapLoaded(true) // Mark the map as fully loaded
        },
        [center],
    )

    const onUnmount = useCallback(() => {
        setMap(null)
        setIsMapLoaded(false)
    }, [])

    const hasSurroundings = !isEmpty(surroundings)
    const hasActivities = !isEmpty(activities)
    const hasJourneyInfo = !isEmpty(journey)

    // If no relevant data is available, don't render the component
    if (!hasSurroundings && !hasActivities && !hasJourneyInfo) {
        return null
    }

    return (
        <section id={SECTION_IDS.DISTANCES_SECTION_ID}>
            <ExposeSectionTitleWrapper>
                <Typography variant="h6" fontWeight="semiBold" element="h2">
                    {t('locationTitle')}
                </Typography>
            </ExposeSectionTitleWrapper>

            {/* Show loader until the map is fully loaded */}
            {!isMapLoaded && <BlurImg src={getBasePath('/assets/images/hero/map-preview.jpg')} alt={'Loading map'} />}

            {/* Render the Google Map only if the API is loaded and coordinates are valid */}
            {isLoaded && latitude !== null && longitude !== null && (
                <MapContainer isVisible={isMapLoaded}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                </MapContainer>
            )}

            <ExposeDesktopWrapper>
                <LocationBar showMapHyperlink={false} region={region} />
            </ExposeDesktopWrapper>

            {/* Render distances if surroundings data is available */}
            {hasSurroundings && <Distances distances={surroundings} />}

            {/* Render nearby section if activities or journey info is available */}
            {(hasActivities || hasJourneyInfo) && (
                <NearBySection
                    activities={activities}
                    leisure={leisure}
                    surroundings={surroundingLocation}
                    journey={journey}
                    regionLabel={regionLabel}
                />
            )}

            <ComponentSeparator />
        </section>
    )
}

export default Location
