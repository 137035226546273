import { TranslationFunction } from '../../../common/types'
import { PrePaymentTime } from '../enums/pre-payment-time'

export const getPrePaymentTimeLabel = (id: PrePaymentTime, translate: TranslationFunction): string => {
    switch (id) {
        case PrePaymentTime.BY_ARRANGEMENT:
            return translate('travelNotes.paymentTime.byArrangement')
        case PrePaymentTime.UPON_ARRIVAL:
            return translate('travelNotes.paymentTime.uponArrival')
        case PrePaymentTime.AT_TIME_OF_BOOKING:
            return translate('travelNotes.paymentTime.atTimeOfBooking')
        case PrePaymentTime.DAYS_DUE_AFTER_BOOKING:
            return translate('travelNotes.paymentTime.daysAfterBookingDue')
        case PrePaymentTime.ONE_WEEK_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 1 })
        case PrePaymentTime.TWO_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 2 })
        case PrePaymentTime.THREE_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 3 })
        case PrePaymentTime.FOUR_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 4 })
        case PrePaymentTime.FIVE_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 5 })
        case PrePaymentTime.SIX_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 6 })
        default:
            return ''
    }
}
