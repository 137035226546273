import React, { FC, memo } from 'react'
import { FormattedOfferTransport } from '../../../../models/property-details-page/offers-model'
import { OfferWrapper, IconContainer, Icon } from './styles'
import OfferIcon from '../../../../assets/icons/offer-icon.svg'
import { AnyStyledComponent } from 'styled-components'
import { SearchbarFieldsReturn } from '../../hooks/use-searchbar'
import { Typography } from 'react-components'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from '../../../../hooks/locale'
import { FormattedLivePricingDataTransport } from '../../../../models/pricing/live-pricing-formatter'

type OfferProps = {
    offer: FormattedOfferTransport | null
    searchbar: SearchbarFieldsReturn
    specialOffer: FormattedLivePricingDataTransport['specialOffer']
}

const isWithinPeriod = (
    arrival: Date | null,
    departure: Date | null,
    offeStartDate: string,
    offerEndDate: string,
): boolean => {
    if (!arrival || !departure || !offeStartDate || !offerEndDate) {
        return false
    }

    const offerStart = new Date(offeStartDate)
    const offerEnd = new Date(offerEndDate)

    return arrival >= offerStart && departure <= offerEnd
}

const Offers: FC<OfferProps> = ({ offer, searchbar, specialOffer }) => {
    const { t } = useTranslation()
    const applicableOffer = !isEmpty(specialOffer) ? specialOffer : offer
    const label = `${get(applicableOffer, 'label')}:`
    const discount = get(applicableOffer, 'discount')
    const preposition = get(applicableOffer, 'periodPreposition')
    const period = get(applicableOffer, 'period')
    const offerStartDate = get(applicableOffer, 'validFromDate', '')
    const offerEndDate = get(applicableOffer, 'validToDate', '')
    const subText = isWithinPeriod(searchbar.arrival, searchbar.departure, offerStartDate, offerEndDate)
        ? t('offers.discount.discountIncludedMatch')
        : t('offers.discount.discountIncludedMatch')

    if (isEmpty(applicableOffer) || isEmpty(applicableOffer?.discount)) {
        return null
    }

    return (
        <OfferWrapper>
            <IconContainer>
                <Icon as={OfferIcon as AnyStyledComponent} />
            </IconContainer>
            <Typography variant="caption" fontWeight="semiBold" color="tfwPositiveMedium">
                {label}
                <Typography variant="caption" fontWeight="regular" color="tfwPositiveMedium">
                    {`  ${discount} ${preposition} ${period} ${subText}`}
                </Typography>
            </Typography>
        </OfferWrapper>
    )
}

export default memo(Offers)
