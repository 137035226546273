import WifiIcon from '../../assets/icons/amenities-icons/wifi.svg'
import TVIcon from '../../assets/icons/amenities-icons/tv.svg'
import PetsIcon from '../../assets/icons/amenities-icons/pet.svg'
import DishwasherIcon from '../../assets/icons/amenities-icons/dish-washer.svg'
import WashingMachineIcon from '../../assets/icons/amenities-icons/washing-machine.svg'
import StairsIcon from '../../assets/icons/amenities-icons/stairs.svg'
import BedroomIcon from '../../assets/icons/amenities-icons/bedroom-icon.svg'
import ShowerIcon from '../../assets/icons/amenities-icons/shower-icon.svg'
import GuestIcon from '../../assets/icons/amenities-icons/guest-icon.svg'
import RoomArea from '../../assets/icons/amenities-icons/room-area.svg'
import SmokingIcon from '../../assets/icons/feature-icons/smoking.svg'
import AlertTriangle from '../../assets/icons/alert-triangle.svg'
import CheckedCircle from '../../assets/icons/checked-circle.svg'
import WarningTriangle from '../../assets/icons/warning-triangle.svg'
import Elevator from '../../assets/icons/elevator.svg'
import GroundLevel from '../../assets/icons/ground-level.svg'
import Sauna from '../../assets/icons/sauna.svg'
import WheelChair from '../../assets/icons/wheelchair.svg'
import Pool from '../../assets/icons/pool.svg'
import ChargingStation from '../../assets/icons/charging-station.svg'

const IconMapping: Record<string, string> = {
    wifi: WifiIcon,
    tv: TVIcon,
    television: TVIcon,
    smoking: SmokingIcon,
    pets: PetsIcon,
    dishwasher: DishwasherIcon,
    washingMachine: WashingMachineIcon,
    stairs: StairsIcon,
    groundLevel: GroundLevel,
    elevator: Elevator,
    sauna: Sauna,
    beds: BedroomIcon,
    bathrooms: ShowerIcon,
    guests: GuestIcon,
    area: RoomArea,
    alertTriangle: AlertTriangle,
    checkedCircle: CheckedCircle,
    warningTriangle: WarningTriangle,
    barrierFree: WheelChair,
    wheelChairWithBarrier: WheelChair,
    pool: Pool,
    chargingStation: ChargingStation,
}

export default IconMapping
