import React, { FC, memo } from 'react'
import { ContactContainer, ContactInfoWrapper, OwnerInfo, OwnerDetailsWrapper, OwnerDetailItem } from './styles'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from '../../../hooks/locale'
import { Typography } from 'react-components'
import { ExposeSectionTitleWrapper, ComponentSeparator } from '../style'
import { CommunicationTypes, FormattedContactDetails } from '../../../models/property-details-page/contact-model'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import CommunicationList from './communication-list'
import OwnerDetails from './owner-details'
import ContactButton from './contact-button'
import OwnerImage from './owner-image'
import { PropertyDetails } from '../../../transports/property-details-page-transport'
import { SECTION_IDS } from '../constants'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'

export interface ContactProps {
    contact: FormattedContactDetails
    bookingType: FormattedBookingTypeData
    objectId?: string | number
    providerId: PropertyDetails['providerId']
    searchbar: SearchbarFieldsReturn
}

const Contact: FC<ContactProps> = ({ contact, bookingType, providerId, searchbar }) => {
    const { t } = useTranslation()
    const isEnquiry = get(bookingType, 'subType.type') === 'inquiry'
    const name = get(contact, 'name', '')
    const languages = get(contact, 'languages', '')
    const objectReferenceNumbers = get(contact, 'objectReferenceNumbers', [])
    const providerInfo = get(contact, 'providerInfo', '')
    const operatingHours = get(contact, 'operatingHours', '')
    const communication = get(contact, 'communication', [])
    const imageUrl = get(contact, 'photo')
    const gender = get(contact, 'gender')
    const phoneCommunications = get(contact, 'communication', []).filter(
        comm => Number(comm.type) !== CommunicationTypes.COMMUNICATION_EMAIL,
    )

    return (
        <section id={SECTION_IDS.CONTACT_SECTION_ID}>
            <ContactContainer>
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('contact.contactTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>

                <ContactInfoWrapper>
                    <OwnerImage imageUrl={imageUrl} gender={gender} name={name} providerId={providerId} />
                    <OwnerInfo>
                        {!isEmpty(name) && (
                            <Typography variant="subtitle1" fontWeight="semiBold">
                                {name}
                            </Typography>
                        )}
                        {!isEmpty(languages) && (
                            <Typography variant="subtitle2" color="tfwBlack40">
                                {languages}
                            </Typography>
                        )}
                        {objectReferenceNumbers.map(number => (
                            <div key={`${number.label}-${number.value}`}>
                                <Typography variant="subtitle2" color="tfwBlack40">
                                    {number.label}: {number.value}
                                </Typography>
                            </div>
                        ))}
                    </OwnerInfo>
                </ContactInfoWrapper>

                <OwnerDetailsWrapper>
                    <OwnerDetails label={t('contact.contactProviderInformation')} value={providerInfo} />
                    <OwnerDetails label={t('contact.contactOperatingHours')} value={operatingHours} />

                    {!isEnquiry && !isEmpty(phoneCommunications) && (
                        <OwnerDetailItem>
                            <Typography variant="subtitle1" fontWeight="semiBold">
                                {t('contact.phoneNumbers')}
                            </Typography>
                            <CommunicationList communications={communication} />
                        </OwnerDetailItem>
                    )}

                    {isEnquiry && (
                        <OwnerDetailItem>
                            <Typography variant="subtitle1" fontWeight="semiBold">
                                {t('contact.contactHostPhoneNumber')}
                            </Typography>
                            <Typography variant="subtitle2" color="tfwBlack40">
                                {t('contact.contactDetails')}
                            </Typography>
                            <ContactButton searchbar={searchbar} bookingType={bookingType} />
                        </OwnerDetailItem>
                    )}
                </OwnerDetailsWrapper>
            </ContactContainer>
            <ComponentSeparator />
        </section>
    )
}

export default memo(Contact)
