import { TranslationFunction } from '../../common/types'
import { PropertyDetails, PropertyRoom, Bedrooms } from '../../transports/property-details-page-transport'

export enum RoomTypeBathroomEnum {
    RoomTypeBadEnSuite = 3,
    RoomTypeBathroom = 4,
}

export interface BedroomStats {
    bedrooms?: string
    livingBedroom?: string
    kidsBedroom?: string
    personCount?: string
    qmSize?: string
    bathrooms?: string
}

export interface FormattedStats {
    stats: BedroomStats
    roomCount: number
    properBedrooms: number
    livingRoomBedrooms: number
    childrenBedrooms: number
    maxPersons: number
    apartmentSize: number
    bathrooms: number
    improperBedrooms: number
}

export class Stats {
    private translate: TranslationFunction

    constructor(translate: TranslationFunction) {
        this.translate = translate
    }

    format(general: PropertyDetails, rooms: PropertyRoom[], improperBedrooms = 0): FormattedStats | null {
        const bedrooms = general.bedrooms

        if (!bedrooms || rooms.length === 0) {
            return null
        }

        return {
            stats: this.getStats(general, bedrooms, rooms),
            roomCount: bedrooms.total,
            properBedrooms: bedrooms.proper,
            livingRoomBedrooms: bedrooms.living,
            childrenBedrooms: bedrooms.children,
            maxPersons: general.persons.total,
            apartmentSize: general.squareMeters,
            bathrooms: this.getBathroomCount(rooms),
            improperBedrooms,
        }
    }

    private getRoomDescription(bedrooms: Bedrooms): BedroomStats {
        const rooms: BedroomStats = {}

        if (this.isDefaultBedroom(bedrooms)) {
            rooms.bedrooms = this.translate('properBedRoomCount', {
                count: bedrooms.proper,
            })
        } else {
            if (bedrooms.proper) {
                rooms.bedrooms = this.translate('properBedRoomCount', {
                    count: bedrooms.proper,
                })
            }
            if (bedrooms.living) {
                rooms.livingBedroom = this.translate('livingBedRoomCount', {
                    count: bedrooms.living,
                })
            }
            if (bedrooms.children) {
                rooms.kidsBedroom = this.translate('childrenRoomCount', {
                    count: bedrooms.children,
                })
            }
        }

        return rooms
    }

    private isDefaultBedroom(bedrooms: Bedrooms): boolean {
        const otherRoomCount = bedrooms.proper + bedrooms.living + bedrooms.children
        return bedrooms.total < 2 && otherRoomCount === 0
    }

    private getStats(general: PropertyDetails, bedrooms: Bedrooms, rooms: PropertyRoom[]): BedroomStats {
        const stats: BedroomStats = this.getRoomDescription(bedrooms)

        if (general.persons.total) {
            stats.personCount = this.translate('maxGuestCount', {
                count: general.persons.total,
            })
        }

        if (general.squareMeters) {
            stats.qmSize = this.translate('meterSquareSize', {
                size: general.squareMeters,
            })
        }

        const bathroomCount = this.getBathroomCount(rooms)
        if (bathroomCount > 0) {
            stats.bathrooms = this.translate('bathroomCount', { count: bathroomCount })
        }

        return stats
    }

    private isBathroom(room: PropertyRoom): boolean {
        return Object.values(RoomTypeBathroomEnum).includes(room.type)
    }

    private getBathroomCount(rooms: PropertyRoom[]): number {
        return rooms.reduce((count, room) => {
            if (!this.isBathroom(room)) {
                return count
            }
            return count + (room.count || 1)
        }, 0)
    }
}
