import React, { FC, memo } from 'react'
import { CommunicationItem, CommunicationWrapper } from './styles'
import { FormattedContactDetails, CommunicationTypes } from '../../../models/property-details-page/contact-model'
import { Typography } from 'react-components'
import isEmpty from 'lodash/isEmpty'

interface CommunicationListProps {
    communications: FormattedContactDetails['communication']
}

const CommunicationList: FC<CommunicationListProps> = ({ communications }) => {
    if (isEmpty(communications)) {
        return null
    }

    return (
        <CommunicationWrapper>
            {communications?.map((comm, index) => {
                if (Number(comm.type) !== CommunicationTypes.COMMUNICATION_EMAIL) {
                    return (
                        <CommunicationItem key={`${comm.value}-${index}`}>
                            <Typography variant="subtitle2" color="tfwBlack40">
                                {comm.value}
                            </Typography>
                        </CommunicationItem>
                    )
                }
                return null
            })}
        </CommunicationWrapper>
    )
}

export default memo(CommunicationList)
