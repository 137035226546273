export const SECTION_IDS = {
    DESCRIPTION_SECTION_ID: 'description',
    DISTANCES_SECTION_ID: 'distances',
    CONTACT_SECTION_ID: 'contact',
    RATING_REVIEWS_SECTION_ID: 'ratings',
    AVAILABILITY_SECTION_ID: 'availability',
    PRICE_SECTION_ID: 'price',
    SIDEBAR_SECTION_ID: 'sidebar',
    FOOTER: 'footer',
}

export const STICKY_FOOTER_SCROLL_THRESHOLD = 300

export enum PdpToCheckoutPosition {
    GALLERY = 'gallery',
    STICKY_FOOTER = 'sticky_footer',
}
