import React, { useState } from 'react'
import { AccordionHeader, AccordionContent, AccordionItem, ChevronIconWrapper, ChevronIcon } from './styles'
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg'
import { AnyStyledComponent } from 'styled-components'
import { Typography } from 'react-components'
import upperFirst from 'lodash/upperFirst'

interface AccordionProps {
    items: {
        heading: string
        content: string | React.ReactNode
    }[]
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null)

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    return (
        <React.Fragment>
            {items.map((item, index) => (
                <AccordionItem key={index}>
                    <AccordionHeader onClick={() => toggleAccordion(index)}>
                        <Typography variant="subtitle2" fontWeight="regular">
                            {item.heading}
                        </Typography>
                        <ChevronIconWrapper isOpen={index === openIndex}>
                            <ChevronIcon as={ChevronDownIcon as AnyStyledComponent} />
                        </ChevronIconWrapper>
                    </AccordionHeader>
                    <AccordionContent isOpen={openIndex === index}>
                        {typeof item.content === 'string' ? (
                            <Typography
                                variant="subtitle2"
                                color="tfwBlack40"
                                whiteSpace="pre-line"
                                fontWeight={'regular'}
                            >
                                {upperFirst(item.content)}
                            </Typography>
                        ) : (
                            item.content
                        )}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </React.Fragment>
    )
}

export default Accordion
