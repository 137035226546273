import React, { FC, useCallback, useState, useContext } from 'react'
import {
    NoRatingContainer,
    TotalRatingContainer,
    TotalRating,
    RatingBadge,
    DisplayRating,
    StarRatingContainer,
    RatingCountContainer,
    FullStarIcon,
    HalfStarIcon,
    EmptyStarIcon,
} from './styles'
import { useTranslation } from '../../../hooks/locale'
import { StarRating, Typography, ScreenContext } from 'react-components'
import RatingSection from './rating'
import ReviewSection from './review'
import { ComponentSeparator, CTAButton, ExposeSectionTitleWrapper, StyledGhostButton } from '../style'
import { FormattedRatingTransport } from '../../../models/property-details-page/reviews-model'
import { PropertyTypeConfig } from '../../../models/property-details-page/property-type-model'
import { SECTION_IDS } from '../constants'
import AnalyticsService from '../../../services/analytics-service'
import { pdpPageActions } from '../../../services/analytics-service/actions'
import { pdpPageCategories } from '../../../services/analytics-service/categories'

export interface ReviewProps {
    ratings: FormattedRatingTransport
    propertyTypeConfig: PropertyTypeConfig
}

const Review: FC<ReviewProps> = ({ ratings, propertyTypeConfig }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const noReviews = ratings.ratingsCount === 0
    const { isMobile } = useContext(ScreenContext)
    let title = noReviews
        ? t('propertyRatings.noRatingHeading', { label: propertyTypeConfig.label, article: propertyTypeConfig.article })
        : t('propertyRatings.title')
    title = title.charAt(0).toUpperCase() + title.slice(1)

    const giveReviewClick = useCallback(() => {
        window.location.href = ratings?.addRatingRoute
        setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.GIVE_REVIEW_CLICKED,
            })
        }, 0)
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 5000)
    }, [ratings?.addRatingRoute])

    return (
        <section id={SECTION_IDS.RATING_REVIEWS_SECTION_ID}>
            <ExposeSectionTitleWrapper>
                <Typography variant="h6" fontWeight="semiBold" element="h2">
                    {title}
                </Typography>
                <CTAButton onClick={giveReviewClick} contentAlignment={isMobile ? 'left' : 'right'}>
                    <StyledGhostButton
                        isPrimary={false}
                        isContentWidth
                        disabled={isLoading ? true : undefined}
                        loading={isLoading ? true : undefined}
                    >
                        <Typography variant="subtitle2" fontWeight="regular">
                            {t(`propertyRatings.giveReview`)}
                        </Typography>
                    </StyledGhostButton>
                </CTAButton>
            </ExposeSectionTitleWrapper>
            {noReviews ? (
                <NoRatingContainer>
                    <Typography variant="subtitle2" color="tfwBlack40">
                        {t('propertyRatings.noRatingDescription')}
                    </Typography>
                </NoRatingContainer>
            ) : (
                <>
                    <TotalRatingContainer>
                        <TotalRating>
                            <Typography variant="h4" fontWeight={'semiBold'}>
                                {ratings.badge.avg}
                            </Typography>
                            <RatingBadge>
                                <Typography variant="caption" fontWeight="semiBold">
                                    {t('propertyRatings.ratingMaximumText')}
                                </Typography>
                            </RatingBadge>
                        </TotalRating>
                        <DisplayRating>
                            <StarRatingContainer>
                                <StarRating
                                    actualRating={Number(ratings.badge.avg)}
                                    maxRating={5}
                                    customFullStar={FullStarIcon}
                                    customHalfStar={HalfStarIcon}
                                    customEmptyStar={EmptyStarIcon}
                                    showNumericRating={false}
                                    shouldCenterAlignIcons
                                />
                            </StarRatingContainer>
                            <RatingCountContainer>
                                <Typography variant="subtitle2" fontWeight={'semiBold'}>
                                    {`(${t('ratingsCount', { count: ratings.ratingsCount })})`}
                                </Typography>
                            </RatingCountContainer>
                        </DisplayRating>
                    </TotalRatingContainer>
                    <RatingSection rating={ratings.badge} />
                    <ReviewSection reviews={ratings.ratings} />
                </>
            )}
            <ComponentSeparator />
        </section>
    )
}

export default Review
