import styled from 'styled-components'
import Star from '../../../assets/icons/star.svg'

export const StyledStarIcon = styled(Star)`
    width: 1.25rem;
    height: 1.25rem;
`

export const StyledRatingBar = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    padding: ${({ theme }) => `${theme.spacing.xxxSmall} 0`};
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`
export const StarIconWrapper = styled.div`
    height: ${({ theme }) => theme.layout.xSmall};
    width: ${({ theme }) => theme.layout.xSmall};
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const Ratings = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
`

export const TotalRating = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
    border-bottom: ${props => `1px solid ${props.theme.colors.tfwBlack40}`};
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
`

export const RecommendationPercentage = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
    padding-right: ${({ theme }) => theme.spacing.xSmall};
    padding-left: ${({ theme }) => theme.spacing.xSmall};
    border-left: ${props => `1px solid ${props.theme.colors.tfwBlack10}`};
`
