import { TranslationFunction } from '../../../common/types'
import { FinalPaymentTime } from '../enums/final-payment-time'

export const getFinalPaymentTimeLabel = (id: FinalPaymentTime, translate: TranslationFunction): string => {
    switch (id) {
        case FinalPaymentTime.BY_ARRANGEMENT:
            return translate('travelNotes.paymentTime.byArrangement')
        case FinalPaymentTime.UPON_ARRIVAL:
            return translate('travelNotes.paymentTime.uponArrival')
        case FinalPaymentTime.AT_DEPARTURE:
            return translate('travelNotes.paymentTime.atDeparture')
        case FinalPaymentTime.DAYS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.daysBeforeArrival')
        case FinalPaymentTime.WEEK_1_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 1 })
        case FinalPaymentTime.WEEK_2_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 2 })
        case FinalPaymentTime.WEEK_3_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 3 })
        case FinalPaymentTime.WEEK_4_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 4 })
        case FinalPaymentTime.WEEK_5_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 5 })
        case FinalPaymentTime.WEEK_6_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 6 })
        default:
            return ''
    }
}
