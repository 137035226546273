import styled, { AnyStyledComponent } from 'styled-components'
import FullStar from '../../../assets/icons/star.svg'
import EmptyStar from '../../../assets/icons/empty_star.svg'
import HalfStar from '../../../assets/icons/half-star.svg'

export const NoRatingContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding-bottom: ${({ theme }) => theme.spacing.small};
`

export const TotalRatingContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
`

export const TotalRating = styled.div`
    margin: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.small}`};
    padding-right: ${({ theme }) => theme.spacing.large};
    border-right: 1px dotted ${({ theme }) => theme.colors.tfwBlack10};
`

export const RatingBadge = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    justify-content: ${props => props.theme.style.justifyContent.center};
    align-items: ${props => props.theme.style.alignItems.center};
    background-color: #f5f5f5;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    padding: ${({ theme }) => theme.spacing.xxSmall};
`

export const FullStarIcon = styled(FullStar as AnyStyledComponent)``

export const EmptyStarIcon = styled(FullStarIcon).attrs({ as: EmptyStar })``

export const HalfStarIcon = styled(FullStarIcon).attrs({ as: HalfStar })``

export const DisplayRating = styled.div`
    padding: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.medium} ${theme.spacing.medium}`};
`

export const StarRatingContainer = styled.div`
    zoom: 1.5;
`

export const RatingCountContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xSmall};
`
