import styled from 'styled-components'

export const NotesWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 2px 0;
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex-wrap: wrap;
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
`

export const ListItem = styled.li`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    min-width: 100%;
`

export const Bullet = styled.span`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const StyledTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const Separator = styled.div`
    border-top: ${({ theme }) => `1px dotted ${theme.colors.tfwBlack10}`};
    margin: ${({ theme }) => `${theme.spacing.small} 0`};
`

export const NotesSectionTitleWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => `${theme.spacing.xxSmall} 0`};
`

export const NoteContentWrapepr = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    padding: 2px 0;
`
