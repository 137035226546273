import { addMonths, format, getYear, getMonth } from 'date-fns'
import { Week, MonthData } from '../../../transports/availability-transport'

const generateSkeletonData = (): Array<MonthData> => {
    // Get current date and next month
    const now = new Date()
    const currentMonth = now
    const nextMonth = addMonths(currentMonth, 1)

    // Create the skeleton data structure
    const skeletonData = {
        result: {
            [format(currentMonth, 'yyyy-MM')]: generateMonthSkeleton(currentMonth),
            [format(nextMonth, 'yyyy-MM')]: generateMonthSkeleton(nextMonth),
        },
        firstAvailableMonth: format(currentMonth, 'yyyy-MM'),
    }

    // Convert to array of month data
    const monthsData = skeletonData.result
        ? Object.values(skeletonData.result).map(month => ({
              ...month,
              rows: month.rows as Week[],
          }))
        : []

    return monthsData
}

/**
 * Generates a skeleton structure for a month's calendar view
 */
const generateMonthSkeleton = (date: Date): MonthData => {
    const month = getMonth(date) + 1
    const year = getYear(date)

    // Generate a 6x7 grid (6 weeks, 7 days per week)
    const rows: Week[] = Array.from({ length: 6 }).map(() => Array.from({ length: 7 }).map(() => ({})))

    return {
        label: '',
        month,
        year,
        rows,
    }
}

export default generateSkeletonData
