import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const StyledStatsBar = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-wrap: wrap;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    gap: ${({ theme }) => theme.spacing.xSmall};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
`

export const StatWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    width: 48%;
    padding: ${({ theme }) => `${theme.spacing.xxxSmall} 0`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex: 1;
        width: unset;
    }
`

export const StatIconWrapper = styled.div`
    height: ${({ theme }) => theme.layout.xSmall};
    width: ${({ theme }) => theme.layout.xSmall};
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
`

export const StatIcon = styled.img``
