import { TranslationFunction } from '../../../common/types'
import { TotalPaymentTime } from '../enums/total-payment-time'

export const getTotalPaymentTimeLabel = (id: TotalPaymentTime, translate: TranslationFunction): string => {
    switch (id) {
        case TotalPaymentTime.BY_ARRANGEMENT:
            return translate('travelNotes.paymentTime.byArrangement')
        case TotalPaymentTime.UPON_ARRIVAL:
            return translate('travelNotes.paymentTime.uponArrival')
        case TotalPaymentTime.AT_TIME_OF_BOOKING:
            return translate('travelNotes.paymentTime.atTimeOfBooking')
        case TotalPaymentTime.DAYS_DUE_AFTER_BOOKING:
            return translate('travelNotes.paymentTime.daysAfterBookingDue')
        case TotalPaymentTime.ONE_WEEK_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 1 })
        case TotalPaymentTime.TWO_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 2 })
        case TotalPaymentTime.THREE_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 3 })
        case TotalPaymentTime.FOUR_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 4 })
        case TotalPaymentTime.FIVE_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 5 })
        case TotalPaymentTime.SIX_WEEKS_BEFORE_ARRIVAL:
            return translate('travelNotes.paymentTime.xWeeksBeforeArrival', { count: 6 })
        default:
            return ''
    }
}
