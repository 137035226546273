import React, { FC, useState, useCallback, memo } from 'react'
import { AmenitiesWrapper } from './styles'
import { useTranslation } from '../../../hooks/locale'
import Features from '../features'
import { Typography } from 'react-components'
import { ComponentSeparator, ExposeSectionTitleWrapper, StyledGhostButton, CTAButton } from '../style'
import { Features as FeaturesTransport } from '../../../models/property-details-page/features-model'
import { FormattedRoomsResult } from '../../../models/property-details-page/rooms-model'
import { FormattedSuitableFeatures } from '../../../models/property-details-page/suitables-model'
import isEmpty from 'lodash/isEmpty'

type AmenitiesProps = {
    features: FeaturesTransport
    rooms: FormattedRoomsResult[]
    extendedFeatures: FormattedSuitableFeatures | null
}

const Amenities: FC<AmenitiesProps> = ({ features, rooms, extendedFeatures }) => {
    const { t } = useTranslation()
    const [isAmenitiesPopupOpen, setAmenitiesPopupVisibility] = useState(false)
    const [AmenitiesPopup, setAmenitiesPopup] = useState<React.ComponentType<any> | null>(null)
    const [amenitiesPopupLoading, setAmenitiesPopupLoad] = useState(false)

    const loadAmenitiesPopup = useCallback(async () => {
        const module = await import('./amenities-popup')
        setAmenitiesPopup(() => module.default)
        setAmenitiesPopupLoad(false)
    }, [])

    const showAmenitiesPopup = useCallback(() => {
        if (!AmenitiesPopup) {
            setAmenitiesPopupLoad(true)
            loadAmenitiesPopup()
        }
        setAmenitiesPopupVisibility(true)
    }, [AmenitiesPopup, loadAmenitiesPopup, setAmenitiesPopupLoad])

    const hideAmenitiesPopup = useCallback(() => setAmenitiesPopupVisibility(false), [])

    const hasFeatures = !isEmpty(features)
    const hasRooms = !isEmpty(rooms)
    const hasExtendedFeatures = !isEmpty(extendedFeatures)

    if (!hasFeatures && !hasRooms && !hasExtendedFeatures) {
        return null
    }

    return (
        <>
            <AmenitiesWrapper>
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('amenitiesTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>
                {hasFeatures && <Features features={features} />}
                <CTAButton onClick={showAmenitiesPopup} contentAlignment="flex-start">
                    <StyledGhostButton
                        isPrimary={false}
                        isContentWidth
                        loading={amenitiesPopupLoading ? true : undefined}
                    >
                        <Typography variant="subtitle2" fontWeight="regular">
                            {t('viewAllAmenities')}
                        </Typography>
                    </StyledGhostButton>
                </CTAButton>
                {AmenitiesPopup && (
                    <AmenitiesPopup
                        isOpen={isAmenitiesPopupOpen}
                        onClose={hideAmenitiesPopup}
                        rooms={rooms}
                        extendedFeatures={extendedFeatures}
                    />
                )}
            </AmenitiesWrapper>
            <ComponentSeparator />
        </>
    )
}

export default memo(Amenities)
