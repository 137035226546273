import {
    ExposeTransport,
    PropertyOverview,
    PropertyDetails as PropertyDetailsGeneralTransport,
    SurroundingLocation,
    Location,
    PropertyObjectTransport,
    CustomerObjectsTransport,
    BreadcrumbTransport,
} from '../../transports/property-details-page-transport'
import { Gallery, GalleryImages } from './gallery-model'
import { PropertyDescription, PropertyDescriptionTransport } from './property-description-model'
import { TranslationFunction, FormatDateFunction, RoutingFunction } from '../../common/types'
import { Trust } from './trust-model'
import { PropertyType, PropertyTypeConfig } from './property-type-model'
import { FormattedStats, Stats } from './stats-model'
import { PropertyDetailsGeneralModel } from './property-details-general-model'
import { Surrounding } from './surrounding-model'
import { Accessibility, FormattedAccessibilityTransport } from './accessibility-model'
import { FormattedSuitableFeatures, FormattedSuitablesMap, SuitablesModel } from './suitables-model'
import { FormattedSuitedMap, Suited } from './suited-model'
import { FeatFilter, FormattedFeatFilterMap } from './featfilter-model'
import { Features, FeaturesFormatter } from './features-model'
import { FormattedRoomsResult, Rooms } from './rooms-model'
import { ContactFormatter, FormattedContactDetails } from './contact-model'
import { BookingType, FormattedBookingTypeData } from './booking-type-model'
import { FormattedRatingTransport, Reviews } from './reviews-model'
import { FormattedTravelNote, TravelNotes } from './notes-model'
import { BookingCancellationPolicy, FormattedBookingCancellationPolicy } from './booking-cancellation-policy-model'
import { FormattedImproperBedroom, ImproperBedrooms } from './improper-bedroom'
import { CustomerObjects, FormattedCustomerObjectsTransport } from './customer-objects-model'
import { FormattedOfferTransport, Offer } from './offers-model'
import { PriceFormatter } from '../../hooks/price'
import { Footer, FormattedFooterTransport } from './footer-model'
import { BreadcrumbList, FormattedBreadcrumbTransport } from './breadcrumb-model'

export interface FormattedPropertyData {
    general: PropertyDetailsGeneralTransport
    id: string
    externalObjectId: string
    title: string
    fullTitle: string
    gallery: GalleryImages[]
    objectDescription: PropertyOverview
    description: PropertyDescriptionTransport
    trust: Record<string, string | null>
    stats: FormattedStats | null
    surroundings: Array<SurroundingLocation>
    location: Location
    activities: string[]
    accessibility: FormattedAccessibilityTransport | null
    suitables: FormattedSuitablesMap
    suited: FormattedSuitedMap
    filterFeatMap: FormattedFeatFilterMap
    features: Features
    rooms: FormattedRoomsResult[]
    extendedFeatures: FormattedSuitableFeatures | null
    contact: FormattedContactDetails
    bookingType: FormattedBookingTypeData
    reviews: FormattedRatingTransport
    propertyTypeConfig: PropertyTypeConfig
    notes: FormattedTravelNote[]
    bookingCancellationPolicy: FormattedBookingCancellationPolicy | null
    improperBedrooms: FormattedImproperBedroom | null
    customerObjects: FormattedCustomerObjectsTransport | null
    offers: FormattedOfferTransport | null
    footer: FormattedFooterTransport
    breadcrumbs: FormattedBreadcrumbTransport[]
    propertyRegion: FormattedBreadcrumbTransport | null
}

export class PropertyDetails {
    private readonly formattedData: FormattedPropertyData
    private readonly FEATURES_COUNT_TO_SHOW = 15

    constructor(
        translationFunction: TranslationFunction,
        r: RoutingFunction,
        formatDate: FormatDateFunction,
        priceFormatter: PriceFormatter,
        transport: ExposeTransport,
        custObjects: CustomerObjectsTransport,
        breadcrumbs: BreadcrumbTransport[],
        object?: PropertyObjectTransport,
    ) {
        const general = new PropertyDetailsGeneralModel(transport.objectGeneral)
        const propertyType = new PropertyType(transport.objectGeneral.propertyTypeId, translationFunction)
        const accessibility = new Accessibility(translationFunction).format(general.accessibility)
        const suitables = new SuitablesModel(translationFunction).format(transport.objectSuitables)
        const suited = new Suited(translationFunction).format(general.suitedIds)
        const filterFeatMap = new FeatFilter(translationFunction).format(general.featIds)
        const improperBedrooms = object?.bedrooms
            ? new ImproperBedrooms(translationFunction).format(object?.bedrooms)
            : null

        const contact = new ContactFormatter(translationFunction).format(transport)
        const fullTitle = this.createFullTitle(propertyType.getLabel(), transport.objectDescription.title)
        const breadcrumbList = new BreadcrumbList(translationFunction)

        this.formattedData = {
            general,
            id: general.tfwObjectId,
            externalObjectId: general.externalObjectId,
            title: transport.objectDescription.title,
            fullTitle,
            gallery: new Gallery(transport.objectImages.images).images,
            objectDescription: transport.objectDescription,
            description: new PropertyDescription(transport).format(),
            trust: new Trust(translationFunction).format({
                ratings: transport.objectRatings,
                general: transport.objectGeneral,
            }),
            stats: new Stats(translationFunction).format(
                general,
                transport.objectRooms.rooms,
                improperBedrooms?.total ?? 0,
            ),
            surroundings: new Surrounding().format(transport.objectLocation),
            location: transport.objectLocation.location,
            activities: transport?.objectActivities?.activities,
            accessibility,
            suitables,
            suited,
            filterFeatMap,
            features: new FeaturesFormatter(translationFunction).format(
                general,
                transport.objectRooms,
                accessibility,
                suitables,
                suited,
                filterFeatMap,
                object?.bedrooms.total || 0,
                improperBedrooms?.total || 0,
                this.FEATURES_COUNT_TO_SHOW,
                true,
                !general.isComboListing || !general.isEntity,
            ),
            rooms: new Rooms(translationFunction).format(transport.objectRooms, transport.objectFeatures.features),
            extendedFeatures: new SuitablesModel(translationFunction).formatedSuitableFeatures(
                transport.objectSuitables,
            ),
            contact,
            bookingType: new BookingType(translationFunction, r).format(
                general.bookingType,
                general.tfwObjectId,
                general.providerId,
                general.externalObjectId,
                contact.hasEmail,
                general.traumPaymentsActivated,
            ),
            reviews: new Reviews(translationFunction, formatDate, r).format(
                transport.objectRatings,
                general.providerId,
                transport.objectContact,
                general.tfwObjectId,
            ),
            propertyTypeConfig: propertyType.getConfig(),
            notes: new TravelNotes(translationFunction, priceFormatter).format(
                transport.objectRentNotes,
                !general.traumPaymentsActivated,
            ),
            bookingCancellationPolicy: new BookingCancellationPolicy().format(general.bookingCancellationPolicy),
            improperBedrooms,
            customerObjects: new CustomerObjects(translationFunction, r).format(
                custObjects,
                general.objectOverviewLink,
            ),
            offers: new Offer(translationFunction, formatDate).format(transport.objectOffer),
            footer: new Footer(translationFunction, r).format(transport, fullTitle),
            breadcrumbs: breadcrumbList.format(breadcrumbs, fullTitle),
            propertyRegion: breadcrumbList.getLastRegionLink(breadcrumbs, fullTitle),
        }
    }

    private createFullTitle(label: string, title: string): string {
        if (!title) {
            return label
        }
        if (title.includes(label)) {
            return title
        }
        return `${label} ${title}`
    }

    getData(): FormattedPropertyData {
        return this.formattedData
    }
}
