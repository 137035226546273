import { BaseTransport } from '../transports'
import { RequestUserConfig } from '../common/types'
import { NetworkService } from '../services'
import {
    AvailabilityResponseTransport,
    AvailabilityCalendarResponseTransport,
} from '../transports/availability-transport'

class AvailabilityClient {
    public getAvailableArrivalDates({
        user,
        params,
        signal,
    }: {
        user: RequestUserConfig
        params: any
        signal?: AbortSignal | null
    }): Promise<BaseTransport<AvailabilityResponseTransport>> {
        const { listingId, month, duration } = params
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: `/api/v1/availability/listings/${listingId}/arrivals/${month}`,
            headers,
            params: { duration },
            restConfig: { signal },
        })
    }

    public getAvailableDepartureDates({
        user,
        params,
        signal,
    }: {
        user: RequestUserConfig
        params: any
        signal: AbortSignal | null
    }): Promise<BaseTransport<AvailabilityResponseTransport>> {
        const { listingId, arrivalDate, month, duration } = params
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: `/api/v1/availability/listings/${listingId}/departures/${arrivalDate}/${month}`,
            headers,
            params: { duration },
            restConfig: { signal },
        })
    }

    public getCalendarAvailability({
        user,
        listingId,
    }: {
        user: RequestUserConfig
        listingId: string
    }): Promise<BaseTransport<AvailabilityCalendarResponseTransport>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: `/api/v1/availability/listings/${listingId}`,
            headers,
        })
    }
}

export default new AvailabilityClient()
