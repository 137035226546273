import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'
import { useCurrentSlideIndex } from '../../../hooks/carousel'
import { ThumbnailIcon } from './styles'
import RightNavigator from '../../../assets/icons/right-navigator.svg'

interface Props {
    numberOfSlides: number
    visibleSlides: number
}

const ThumbnailNext: FC<Props> = ({ numberOfSlides, visibleSlides }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex !== numberOfSlides - visibleSlides && numberOfSlides >= visibleSlides ? (
        <ThumbnailIcon as={RightNavigator as AnyStyledComponent} />
    ) : null
}

export default memo(ThumbnailNext)
