import React, { FC, useState, useCallback, memo, useEffect, useRef, useMemo } from 'react'
import Costs from './costs'
import SearchbarDatePicker from '../../search-result-listing/header-section/searchbar/date-picker'
import SearchbarGuestPicker from '../../search-result-listing/header-section/searchbar/guest-picker'
import { DatePickerAlignment } from '../../common/date-picker/types'
import { PriceInputWrapper, PriceInput } from './styles'
import TitleBar from './titlebar'
import { ComponentSeparator, DottedSeparator, ExposeSectionTitleWrapper } from '../style'
import { Typography } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import BookNowButton from '../book-now-button'
import EnquiryButton from '../enquiry-button'
import Notes from './notes'
import { FormattedTravelNote } from '../../../models/property-details-page/notes-model'
import { FormattedBookingCancellationPolicy } from '../../../models/property-details-page/booking-cancellation-policy-model'
import { SearchbarFieldsReturn, UpdateSearchBarTransport } from '../hooks/use-searchbar'
import get from 'lodash/get'
import InclusiveCosts from './inclusive-costs'
import OptionalCosts from './optional-costs'
import UsageBoundCosts from './usage-bound-costs'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import isInquiryButtonVisible from '../side-bar/utils/is-inquiry-button-visible'
import isDirectBookingButtonVisible from '../side-bar/utils/is-direct-booking-button-visible'
import is24HourBookingButtonVisible from '../side-bar/utils/is-24-hour-booking-button-visible'
import BindingInquiryButton from '../binding-inquiry-button'
import Offers from './offers'
import { FormattedOfferTransport } from '../../../models/property-details-page/offers-model'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'
import { SECTION_IDS } from '../constants'
import isNumber from 'lodash/isNumber'
import AnalyticsService from '../../../services/analytics-service'
import { pdpPageActions } from '../../../services/analytics-service/actions'
import { pdpPageCategories } from '../../../services/analytics-service/categories'
import { pdpPageEventLabels } from '../../../services/analytics-service/labels'
import { useDateFormatter } from '../../../hooks/date'

interface PriceProps {
    layout: 'compact' | 'stretched'
    datePickerAlignment?: DatePickerAlignment
    showPriceTitle?: boolean
    position?: 'sidebar' | 'content'
    notes?: FormattedTravelNote[]
    bookingCancellationPolicy?: FormattedBookingCancellationPolicy | null
    searchbar: SearchbarFieldsReturn
    updateSearchbar: UpdateSearchBarTransport
    availableDates: Date[] | null
    isAvailabilityLoading: boolean
    pricingDetails: FormattedLivePricingDataTransport | null
    isPricingLoading: boolean
    pricingErrorCode: number | null
    bookingType: FormattedBookingTypeData | null
    offers: FormattedOfferTransport | null
    showVatTax: boolean
    isTraumPaymentEnabled: boolean
    showCalendar: boolean
    hideExtraInfo?: boolean
    maxTotalGuests?: number
}

const Price: FC<PriceProps> = ({
    layout = 'compact',
    datePickerAlignment = DatePickerAlignment.RIGHT,
    showPriceTitle = false,
    position = 'content',
    notes,
    bookingCancellationPolicy,
    searchbar,
    updateSearchbar,
    availableDates,
    isAvailabilityLoading,
    pricingDetails,
    pricingErrorCode,
    isPricingLoading,
    bookingType,
    offers,
    showVatTax,
    isTraumPaymentEnabled,
    showCalendar,
    hideExtraInfo = false,
    maxTotalGuests,
}) => {
    const [isDatePickerOpen, setDatePickerOpen] = useState(false)
    const [isPersonSelectorOpen, setPersonSelectorOpen] = useState(false)
    const { t } = useTranslation()
    const autoCorrectionRef = useRef<number>(1) // Default to 1
    const { format } = useDateFormatter()

    useEffect(() => {
        // If autoCorrection.adults is a valid number, store it in the ref
        if (isNumber(pricingDetails?.autoCorrection?.adults)) {
            autoCorrectionRef.current = pricingDetails.autoCorrection.adults
        }
    }, [pricingDetails]) // Runs when pricingDetails changes

    const toggleDatePicker = useCallback((isOpen: boolean) => setDatePickerOpen(isOpen), [])
    const togglePersonSelector = useCallback((isOpen: boolean) => setPersonSelectorOpen(isOpen), [])
    const inclusiveCosts = get(pricingDetails, 'inclusiveCosts', [])
    const optionalCosts = get(pricingDetails, 'optionalCosts', [])
    const usageBoundCosts = get(pricingDetails, 'usageBoundCosts', [])
    const specialOffer = get(pricingDetails, 'specialOffer', null)

    const handleSearchbarUpdate = useCallback(
        (field: keyof SearchbarFieldsReturn, value: any) => {
            updateSearchbar(field, value)
        },
        [updateSearchbar],
    )

    const trackArrivalDateChange = useCallback(
        (startDate: Date | null, endDate?: Date | null) => {
            if (startDate) {
                setTimeout(() => {
                    AnalyticsService.trackEvent({
                        category: pdpPageCategories.PDP_PAGE,
                        action: pdpPageActions.DATE_PICKER_START_DATE_CHANGED,
                        label:
                            position === 'content'
                                ? pdpPageEventLabels.WIDGETS.PRICE_WIDGET
                                : pdpPageEventLabels.WIDGETS.SIDEBAR,
                        value: format(startDate, 'dd.MM.yyyy'),
                    })
                    if (endDate) {
                        AnalyticsService.trackEvent({
                            category: pdpPageCategories.PDP_PAGE,
                            action: pdpPageActions.DATES_SELECTED,
                            value: `${format(startDate, 'dd.MM.yyyy')}_${format(endDate, 'dd.MM.yyyy')}`,
                        })
                    }
                }, 0)
            }
        },
        [format, position],
    )

    const openPersonSelector = useCallback(() => togglePersonSelector(true), [togglePersonSelector])
    const closePersonSelector = useCallback(() => togglePersonSelector(false), [togglePersonSelector])
    const openDateSelector = useCallback(() => toggleDatePicker(true), [toggleDatePicker])
    const closeDateSelector = useCallback(() => toggleDatePicker(false), [toggleDatePicker])

    const trackDepartureDateChange = useCallback(
        (startDate: Date | null, endDate?: Date | null) => {
            if (endDate) {
                setTimeout(() => {
                    AnalyticsService.trackEvent({
                        category: pdpPageCategories.PDP_PAGE,
                        action: pdpPageActions.DATE_PICKER_END_DATE_CHANGED,
                        label:
                            position === 'content'
                                ? pdpPageEventLabels.WIDGETS.PRICE_WIDGET
                                : pdpPageEventLabels.WIDGETS.SIDEBAR,
                        value: format(endDate, 'dd.MM.yyyy'),
                    })
                    if (startDate) {
                        AnalyticsService.trackEvent({
                            category: pdpPageCategories.PDP_PAGE,
                            action: pdpPageActions.DATES_SELECTED,
                            value: `${format(startDate, 'dd.MM.yyyy')}_${format(endDate, 'dd.MM.yyyy')}`,
                        })
                    }
                }, 0)
            }
        },
        [format, position],
    )

    const handleDepartureDateChange = useCallback(
        (date: Date | null) => {
            handleSearchbarUpdate('departure', date)
            if (searchbar?.arrival && date) {
                toggleDatePicker(false)
            }
        },
        [handleSearchbarUpdate, searchbar?.arrival, toggleDatePicker],
    )

    const handleArrivalDateChange = useCallback(
        (date: Date | null) => {
            handleSearchbarUpdate('arrival', date)
        },
        [handleSearchbarUpdate],
    )

    const handleAdultsChange = useCallback(
        (count: number) => {
            handleSearchbarUpdate('adults', count)
            setTimeout(() => {
                AnalyticsService.trackEvent({
                    category: pdpPageCategories.PDP_PAGE,
                    action: pdpPageActions.ADULTS_COUNT_CHANGED,
                    value: count.toString(),
                })
            }, 0)
        },
        [handleSearchbarUpdate],
    )

    const handleKidsChange = useCallback(
        (count: number) => {
            handleSearchbarUpdate('kids', count)
            setTimeout(() => {
                AnalyticsService.trackEvent({
                    category: pdpPageCategories.PDP_PAGE,
                    action: pdpPageActions.CHILDREN_COUNT_CHANGED,
                    value: count.toString(),
                })
            }, 0)
        },
        [handleSearchbarUpdate],
    )

    const adultsCount = useMemo(() => Math.max(searchbar.adults, autoCorrectionRef.current), [searchbar.adults])

    return (
        <section id={position === 'content' ? SECTION_IDS.PRICE_SECTION_ID : SECTION_IDS.SIDEBAR_SECTION_ID}>
            {showPriceTitle && (
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('price.priceSectionTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>
            )}
            <TitleBar pricingErrorCode={pricingErrorCode} searchbar={searchbar} />
            {(isPricingLoading || pricingDetails) && (
                <Costs
                    searchbar={searchbar}
                    pricingDetails={pricingDetails}
                    pricingErrorCode={pricingErrorCode}
                    isPricingLoading={isPricingLoading}
                    showVatTax={showVatTax}
                    showCalendar={showCalendar}
                />
            )}
            <Offers searchbar={searchbar} offer={offers} specialOffer={specialOffer} />
            <PriceInputWrapper layout={layout}>
                <PriceInput layout={layout}>
                    <SearchbarDatePicker
                        isOpen={isDatePickerOpen}
                        onOpen={openDateSelector}
                        onClose={closeDateSelector}
                        startDate={searchbar.arrival}
                        endDate={searchbar.departure}
                        showInputInModal
                        onStartDateChange={handleArrivalDateChange}
                        onEndDateChange={handleDepartureDateChange}
                        alignmentPosition={datePickerAlignment}
                        enabledDates={availableDates ? availableDates : undefined}
                        trackStartDateChange={trackArrivalDateChange}
                        trackEndDateChange={trackDepartureDateChange}
                        disabledByDefault
                        loading={isAvailabilityLoading}
                    />
                </PriceInput>

                <PriceInput layout={layout}>
                    <SearchbarGuestPicker
                        isOpen={isPersonSelectorOpen}
                        onOpen={openPersonSelector}
                        onClose={closePersonSelector}
                        adultsCount={adultsCount}
                        childrenCount={searchbar.kids}
                        showInputInModal
                        onAdultsCountChange={handleAdultsChange}
                        onChildrenCountChange={handleKidsChange}
                        minAdults={autoCorrectionRef.current}
                        maxTotalGuests={maxTotalGuests}
                    />
                </PriceInput>
                {isNumber(pricingDetails?.autoCorrection?.adults) && (
                    <Typography variant="caption" fontWeight="regular" color="tfwNegativeMedium">
                        {t(`livePriceErrors.66`, { guests: pricingDetails?.autoCorrection?.adults })}
                    </Typography>
                )}
            </PriceInputWrapper>

            <PriceInputWrapper layout={layout}>
                {isInquiryButtonVisible({ bookingType }) && (
                    <PriceInput layout={layout}>
                        <EnquiryButton
                            bookingType={bookingType}
                            searchbar={searchbar}
                            position={position === 'content' ? 'price_widget' : 'sidebar'}
                        />
                    </PriceInput>
                )}
                {isDirectBookingButtonVisible({ bookingType }) && (
                    <PriceInput layout={layout}>
                        <BookNowButton
                            bookingType={bookingType}
                            searchbar={searchbar}
                            position={position === 'content' ? 'price_widget' : 'sidebar'}
                        />
                    </PriceInput>
                )}
                {is24HourBookingButtonVisible({ bookingType }) && (
                    <PriceInput layout={layout}>
                        <BindingInquiryButton
                            bookingType={bookingType}
                            searchbar={searchbar}
                            position={position === 'content' ? 'price_widget' : 'sidebar'}
                        />
                    </PriceInput>
                )}
            </PriceInputWrapper>
            {position === 'content' && !hideExtraInfo && (
                <>
                    <UsageBoundCosts usageBoundCosts={usageBoundCosts} />
                    <InclusiveCosts inclusiveCosts={inclusiveCosts} />
                    <OptionalCosts optionalCosts={optionalCosts} />
                    <DottedSeparator />
                    <Notes
                        notes={notes}
                        bookingCancellationPolicy={bookingCancellationPolicy}
                        arrivalDate={searchbar.arrival}
                        isTraumPaymentEnabled={isTraumPaymentEnabled}
                    />
                    <ComponentSeparator />
                </>
            )}
            {hideExtraInfo && <ComponentSeparator />}
        </section>
    )
}

export default memo(Price)
