import React, { FC, memo } from 'react'
import { Typography } from 'react-components'
import { SECTION_IDS } from '../constants'
import { FormattedFooterTransport } from '../../../models/property-details-page/footer-model'
import { ExposeSectionTitleWrapper } from '../style'
import { useTranslation } from '../../../hooks/locale'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import ExternalLink from '../../common/external-link'
import { FooterItem, FooterTitle, FooterWrapper, FooterSeoRating } from './style'
import { getDomain } from '../../../utils/request-utils'

type FooterProps = {
    footer: FormattedFooterTransport
}

const Footer: FC<FooterProps> = ({ footer }) => {
    const { t } = useTranslation()
    const filteredItems = Object.values(footer).filter(item => !isEmpty(item?.title) && isString(item?.data))
    const domain = typeof globalThis?.window !== 'undefined' ? window.location.origin : getDomain()

    return (
        <section id={SECTION_IDS.FOOTER}>
            <ExposeSectionTitleWrapper>
                <Typography variant="h6" fontWeight="semiBold" element="h2">
                    {t('propertyDetailsFooter.title')}
                </Typography>
            </ExposeSectionTitleWrapper>
            <FooterWrapper>
                {!!footer.imprint?.data?.link && (
                    <FooterItem key={footer.imprint.title}>
                        <FooterTitle>
                            <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                                {`${footer.imprint.title} :`}
                            </Typography>
                        </FooterTitle>
                        <ExternalLink href={footer.imprint?.data?.link} target="_self">
                            <Typography variant="subtitle2" fontWeight="semiBold" textDecoration="underline">
                                {t('propertyDetailsFooter.providerInformation')}
                            </Typography>
                        </ExternalLink>
                    </FooterItem>
                )}
                {filteredItems.map(item => {
                    return (
                        <FooterItem key={item.title}>
                            <FooterTitle>
                                <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                                    {`${item.title} :`}
                                </Typography>
                            </FooterTitle>
                            {item.type === 'link' ? (
                                <ExternalLink href={item.data} target="_self">
                                    <Typography variant="subtitle2" fontWeight="semiBold" textDecoration="underline">
                                        {`${domain}${item.data}`}
                                    </Typography>
                                </ExternalLink>
                            ) : (
                                <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                                    {item.data}
                                </Typography>
                            )}
                        </FooterItem>
                    )
                })}
                <FooterSeoRating>
                    {!!footer?.seoRating?.data && (
                        <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                            {footer?.seoRating?.data}
                        </Typography>
                    )}
                </FooterSeoRating>
            </FooterWrapper>
        </section>
    )
}

export default memo(Footer)
