import { TranslationFunction } from '../../../common/types'
import { RentNotesArrivalTime } from '../enums/arrival-time'

function getHourFromEnum(value: RentNotesArrivalTime): number {
    if (value === RentNotesArrivalTime.ZERO_OCLOCK) {
        return 0 // Midnight
    }
    return value - 1 // Enum starts at 1, hours start at 0
}

function formatTimeFromEnum(value: RentNotesArrivalTime): string {
    const hour = getHourFromEnum(value)
    const date = new Date()
    return date.setHours(hour, 0, 0).toString() // Set the hour, reset minutes and seconds
}

export const getArrivalTimeLabel = (id: RentNotesArrivalTime, translate: TranslationFunction): string => {
    switch (id) {
        case RentNotesArrivalTime.BY_ARRANGEMENT:
            return translate('travelNotes.paymentTime.byArrangement')
        default:
            return translate('meridianTime', { time: formatTimeFromEnum(id) })
    }
}
