import styled from 'styled-components'

export const FooterItem = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    align-items: ${props => props.theme.style.alignItems.center};
    padding: ${props => props.theme.spacing.xxSmall} 0;
`

export const FooterTitle = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.row};
    align-items: ${props => props.theme.style.alignItems.center};
    margin-right: ${props => props.theme.spacing.medium};
`

export const FooterWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.column};
`

export const FooterSeoRating = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    padding-top: ${props => props.theme.spacing.medium};
`
