import React, { useMemo, memo } from 'react'
import { DistancesWrapper, DistancesTitle, DistancesItemsWrapper, DistancesItem } from './styles'
import { useTranslation } from '../../../../hooks/locale'
import { Typography } from 'react-components'
import { SurroundingLocation } from '../../../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'

interface DistancesProps {
    distances?: Array<SurroundingLocation> | null
}

const Distances: React.FC<DistancesProps> = ({ distances }) => {
    const { t } = useTranslation()

    const hasValidDistances = useMemo(() => {
        return distances && !isEmpty(distances)
    }, [distances])

    const renderedDistances = useMemo(() => {
        if (!hasValidDistances) {
            return null
        }

        return distances?.map((item, index) => {
            if (!item || !item.type || !item.distance) {
                return null
            }

            return (
                <DistancesItem key={index}>
                    <Typography variant="overline" color="tfwBlack40">
                        {item.type}:
                    </Typography>
                    <Typography variant="subtitle1">{item.distance}</Typography>
                </DistancesItem>
            )
        })
    }, [hasValidDistances, distances])

    if (!hasValidDistances) {
        return null
    }

    return (
        <DistancesWrapper>
            <DistancesTitle>
                <Typography variant="subtitle1" fontWeight="highlight">
                    {t('distances')}
                </Typography>
            </DistancesTitle>
            <DistancesItemsWrapper>{renderedDistances}</DistancesItemsWrapper>
        </DistancesWrapper>
    )
}

export default memo(Distances)
