import styled, { css } from 'styled-components'
import { CarouselProvider, Slider } from 'pure-react-carousel'

export const CustomerObjectsSectionWrapper = styled.section`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.column};
`

export const ImageWrapper = styled.div`
    height: 10rem;
    width: 12.625rem;
    border-radius: ${props => props.theme.typography.border.radius.xSmall};
    margin-right: ${props => props.theme.spacing.medium};
`

export const SliderContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    max-width: fit-content;
    overflow: hidden;
`

export const StyledCarouselProvider = styled(CarouselProvider)<{ margin?: string }>`
    width: 100%;
    margin: ${({ margin }) => (!!margin ? margin : 0)};
    li {
        list-style: none;
    }
`

export const StyledSlider = styled(Slider)<{ $transitionTime?: number }>(
    ({ $transitionTime }) => css`
        padding: 0;
        margin: ${props => props.theme.spacing.medium} 0;
        ul {
            transition: transform ${$transitionTime ? `${$transitionTime}s` : `0.5s`};
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
        }
    `,
)
