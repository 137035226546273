import React, { FC, memo, useCallback, useMemo } from 'react'
import {
    StyledRatingBar,
    StarIconWrapper,
    Ratings,
    TotalRating,
    StyledStarIcon,
    RecommendationPercentage,
} from './styles'
import { useTranslation } from '../../../hooks/locale'
import { Typography } from 'react-components'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { SECTION_IDS } from '../constants'
import { scrollToSection } from '../navigation-bar'

interface RatingsBarProps {
    ratingCount: number
    averageRating: string
    trust?: Record<string, string | null>
}

const RatingsBar: FC<RatingsBarProps> = ({ ratingCount, averageRating, trust }) => {
    const { t } = useTranslation()

    const ratingsText = useMemo(() => t('ratingsCount', { count: ratingCount }), [t, ratingCount])

    const scrollToReviews = useCallback((): void => {
        scrollToSection(SECTION_IDS.RATING_REVIEWS_SECTION_ID)
    }, [])

    if (Number(averageRating) === 0 || Number(ratingCount) === 0) {
        return null
    }

    return (
        <StyledRatingBar>
            <StarIconWrapper>
                <StyledStarIcon />
            </StarIconWrapper>
            <Ratings>
                <Typography variant="subtitle2">{`${averageRating}/5`}</Typography>
            </Ratings>
            <TotalRating onClick={scrollToReviews}>
                <Typography variant="subtitle2" color="tfwBlack40">
                    {ratingsText}
                </Typography>
            </TotalRating>
            {!isEmpty(get(trust, 'recommendationPercentage')) && (
                <RecommendationPercentage>
                    <Typography variant="subtitle2" color="tfwBlack40">
                        {get(trust, 'recommendationPercentage')}
                    </Typography>
                </RecommendationPercentage>
            )}
        </StyledRatingBar>
    )
}

export default memo(RatingsBar)
