import styled from 'styled-components'

export const StyledTitleBar = styled.div`
    display: flex;
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing.xSmall}`};
`

export const SideBarPaddingWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${({ theme }) => `0 ${theme.spacing.medium}`};
`
