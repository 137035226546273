import { NetworkService } from '../services'
import { BaseTransport } from '../transports'
import LivePricingResponseTransport, { LivePricingParamsTransport } from '../transports/live-pricing-transport'

class PricingClient {
    public getLivePrices({
        params,
    }: {
        params: LivePricingParamsTransport
    }): Promise<BaseTransport<LivePricingResponseTransport>> {
        return NetworkService.get({
            url: `/api/v1/prices/listings/${params.listingId}/live`,
            params,
        })
    }
}

export default new PricingClient()
