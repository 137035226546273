import { useCallback } from 'react'
import { useLocale, useTranslation } from './locale'
import { IntlMessageFormat } from 'intl-messageformat'

const VALUE_UNDEFINED = 999_999

export type PriceFormatter = (
    value?: number | null,
    currency?: string,
    isVariable?: boolean,
    isLivePriceFeasible?: boolean,
    returnNull?: boolean,
) => string | null

const usePriceFormatter = (): PriceFormatter => {
    const { t } = useTranslation()
    const { locale } = useLocale()

    return useCallback(
        (
            value?: number | null,
            currency?: string,
            isVariable = false,
            isLivePriceFeasible = false,
            returnNull = false,
        ): string | null => {
            if (!value || value === VALUE_UNDEFINED) {
                if (isLivePriceFeasible) {
                    return ''
                }
                if (returnNull) {
                    return null
                }
                return t('price.priceOnRequest')
            }

            if (!currency) {
                currency = 'EUR'
            }

            const message = new IntlMessageFormat(`{value, number, ::currency/${currency}}`, locale)
            const formattedValue = message.format({ value, currency })

            const variablePrefix = isVariable ? `${t('price.ab')} ` : ''

            return `${variablePrefix}${formattedValue}`
        },
        [t, locale],
    )
}

export default usePriceFormatter
