import { PropertyDetails } from '../../transports/property-details-page-transport'

export class PropertyDetailsGeneralModel {
    private _tfwObjectId: PropertyDetails['tfwObjectId']
    private _objectKey: PropertyDetails['objectKey']
    private _status: PropertyDetails['status']
    private _externalObjectId: PropertyDetails['externalObjectId']
    private _externalCustomerId: PropertyDetails['externalCustomerId']
    private _listingUsercode: PropertyDetails['listingUsercode']
    private _hasAdvancedPricingFeature: PropertyDetails['hasAdvancedPricingFeature']
    private _propertyTypeId: PropertyDetails['propertyTypeId']
    private _squareMeters: PropertyDetails['squareMeters']
    private _persons: PropertyDetails['persons']
    private _regionIds: PropertyDetails['regionIds']
    private _regionNodeIds: PropertyDetails['regionNodeIds']
    private _attractionIds: PropertyDetails['attractionIds']
    private _generalEquipments: PropertyDetails['generalEquipments']
    private _bedrooms: PropertyDetails['bedrooms']
    private _classification: PropertyDetails['classification']
    private _topicIds: PropertyDetails['topicIds']
    private _suitedIds: PropertyDetails['suitedIds']
    private _featIds: PropertyDetails['featIds']
    private _providerId: PropertyDetails['providerId']
    private _customerId: PropertyDetails['customerId']
    private _accessibility: PropertyDetails['accessibility']
    private _bookingType: PropertyDetails['bookingType']
    private _releaseDate: PropertyDetails['releaseDate']
    private _lastUpdate: PropertyDetails['lastUpdate']
    private _objectOverviewLink: PropertyDetails['objectOverviewLink']
    private _locales: PropertyDetails['locales']
    private _showAvailabilityCalendar: PropertyDetails['showAvailabilityCalendar']
    private _lastUpdateAvailability: PropertyDetails['lastUpdateAvailability']
    private _maxPets: PropertyDetails['maxPets']
    private _housingProtectionNumber: PropertyDetails['housingProtectionNumber']
    private _onlineStatus: PropertyDetails['onlineStatus']
    private _showIncludedVatInformation: PropertyDetails['showIncludedVatInformation']
    private _activatedFreeCancellation: PropertyDetails['activatedFreeCancellation']
    private _freeCancellationInDays: PropertyDetails['freeCancellationInDays']
    private _bookingCancellationPolicy: PropertyDetails['bookingCancellationPolicy']
    private _traumPaymentsActivated: PropertyDetails['traumPaymentsActivated']
    private _entityId: PropertyDetails['entityId']
    private _totalUnits: PropertyDetails['totalUnits']
    private _isComboListing: boolean
    private _isEntity: boolean

    constructor(data: PropertyDetails) {
        this._tfwObjectId = data.tfwObjectId
        this._objectKey = data.objectKey
        this._status = data.status
        this._externalObjectId = data.externalObjectId
        this._externalCustomerId = data.externalCustomerId
        this._listingUsercode = data.listingUsercode
        this._hasAdvancedPricingFeature = data.hasAdvancedPricingFeature
        this._propertyTypeId = data.propertyTypeId
        this._squareMeters = data.squareMeters
        this._persons = data.persons
        this._regionIds = data.regionIds
        this._regionNodeIds = data.regionNodeIds
        this._attractionIds = data.attractionIds
        this._generalEquipments = data.generalEquipments
        this._bedrooms = data.bedrooms
        this._classification = data.classification
        this._topicIds = data.topicIds
        this._suitedIds = data.suitedIds
        this._featIds = data.featIds
        this._providerId = data.providerId
        this._customerId = data.customerId
        this._accessibility = data.accessibility
        this._bookingType = data.bookingType
        this._releaseDate = data.releaseDate
        this._lastUpdate = data.lastUpdate
        this._objectOverviewLink = data.objectOverviewLink
        this._locales = data.locales
        this._showAvailabilityCalendar = data.showAvailabilityCalendar
        this._lastUpdateAvailability = data.lastUpdateAvailability
        this._maxPets = data.maxPets
        this._housingProtectionNumber = data.housingProtectionNumber
        this._onlineStatus = data.onlineStatus
        this._showIncludedVatInformation = data.showIncludedVatInformation
        this._activatedFreeCancellation = data.activatedFreeCancellation
        this._freeCancellationInDays = data.freeCancellationInDays
        this._bookingCancellationPolicy = data.bookingCancellationPolicy
        this._traumPaymentsActivated = data.traumPaymentsActivated
        this._entityId = data.entityId
        this._totalUnits = data.totalUnits
        this._isComboListing = data.entityId !== null
        this._isEntity = data.entityId !== null && this.tfwObjectId === this.entityId
    }

    // Getters
    public get tfwObjectId(): PropertyDetails['tfwObjectId'] {
        return this._tfwObjectId
    }
    public get objectKey(): PropertyDetails['objectKey'] {
        return this._objectKey
    }
    public get status(): PropertyDetails['status'] {
        return this._status
    }
    public get externalObjectId(): PropertyDetails['externalObjectId'] {
        return this._externalObjectId
    }
    public get externalCustomerId(): PropertyDetails['externalCustomerId'] {
        return this._externalCustomerId
    }
    public get listingUsercode(): PropertyDetails['listingUsercode'] {
        return this._listingUsercode
    }
    public get hasAdvancedPricingFeature(): PropertyDetails['hasAdvancedPricingFeature'] {
        return this._hasAdvancedPricingFeature
    }
    public get propertyTypeId(): PropertyDetails['propertyTypeId'] {
        return this._propertyTypeId
    }
    public get squareMeters(): PropertyDetails['squareMeters'] {
        return this._squareMeters
    }
    public get persons(): PropertyDetails['persons'] {
        return this._persons
    }
    public get regionIds(): PropertyDetails['regionIds'] {
        return this._regionIds
    }
    public get regionNodeIds(): PropertyDetails['regionNodeIds'] {
        return this._regionNodeIds
    }
    public get attractionIds(): PropertyDetails['attractionIds'] {
        return this._attractionIds
    }
    public get generalEquipments(): PropertyDetails['generalEquipments'] {
        return this._generalEquipments
    }
    public get bedrooms(): PropertyDetails['bedrooms'] {
        return this._bedrooms
    }
    public get classification(): PropertyDetails['classification'] {
        return this._classification
    }
    public get topicIds(): PropertyDetails['topicIds'] {
        return this._topicIds
    }
    public get suitedIds(): PropertyDetails['suitedIds'] {
        return this._suitedIds
    }
    public get featIds(): PropertyDetails['featIds'] {
        return this._featIds
    }
    public get providerId(): PropertyDetails['providerId'] {
        return this._providerId
    }
    public get customerId(): PropertyDetails['customerId'] {
        return this._customerId
    }
    public get accessibility(): PropertyDetails['accessibility'] {
        return this._accessibility
    }
    public get bookingType(): PropertyDetails['bookingType'] {
        return this._bookingType
    }
    public get releaseDate(): PropertyDetails['releaseDate'] {
        return this._releaseDate
    }
    public get lastUpdate(): PropertyDetails['lastUpdate'] {
        return this._lastUpdate
    }
    public get objectOverviewLink(): PropertyDetails['objectOverviewLink'] {
        return this._objectOverviewLink
    }
    public get locales(): PropertyDetails['locales'] {
        return this._locales
    }
    public get showAvailabilityCalendar(): PropertyDetails['showAvailabilityCalendar'] {
        return this._showAvailabilityCalendar
    }
    public get lastUpdateAvailability(): PropertyDetails['lastUpdateAvailability'] {
        return this._lastUpdateAvailability
    }
    public get maxPets(): PropertyDetails['maxPets'] {
        return this._maxPets
    }
    public get housingProtectionNumber(): PropertyDetails['housingProtectionNumber'] {
        return this._housingProtectionNumber
    }
    public get onlineStatus(): PropertyDetails['onlineStatus'] {
        return this._onlineStatus
    }
    public get showIncludedVatInformation(): PropertyDetails['showIncludedVatInformation'] {
        return this._showIncludedVatInformation
    }
    public get activatedFreeCancellation(): PropertyDetails['activatedFreeCancellation'] {
        return this._activatedFreeCancellation
    }
    public get freeCancellationInDays(): PropertyDetails['freeCancellationInDays'] {
        return this._freeCancellationInDays
    }
    public get bookingCancellationPolicy(): PropertyDetails['bookingCancellationPolicy'] {
        return this._bookingCancellationPolicy
    }
    public get traumPaymentsActivated(): PropertyDetails['traumPaymentsActivated'] {
        return this._traumPaymentsActivated
    }
    public get entityId(): PropertyDetails['entityId'] {
        return this._entityId
    }
    public get totalUnits(): PropertyDetails['totalUnits'] {
        return this._totalUnits
    }

    public get isComboListing(): boolean {
        return this._isComboListing
    }

    public get isEntity(): boolean {
        return this._isEntity
    }
}
