import React, { FC, memo } from 'react'

import { ArrowContainerBack, ArrowLeftIcon } from './styles'

const AvailabilityCalendarBack: FC = () => {
    return (
        <ArrowContainerBack>
            <ArrowLeftIcon />
        </ArrowContainerBack>
    )
}

export default memo(AvailabilityCalendarBack)
