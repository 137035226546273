import React, { FC, useContext } from 'react'
import { Typography, ScreenContext } from 'react-components'
import { FormattedCustomerObjectsTransport } from '../../../models/property-details-page/customer-objects-model'
import { ComponentSeparator, ExposeSectionTitleWrapper } from '../style'
import { useTranslation } from '../../../hooks/locale'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import ExternalLink from '../../common/external-link'
import {
    CustomerObjectsSectionWrapper,
    ImageWrapper,
    StyledCarouselProvider,
    StyledSlider,
    SliderContainer,
} from './styles'
import { FormattedContactDetails } from '../../../models/property-details-page/contact-model'
import ImageLoadingComponent from '../../app-components/image-loading-component'

type CustomerObjectsSectionProps = {
    customerObjects: FormattedCustomerObjectsTransport | null
    contact: FormattedContactDetails | null
}

const CustomerObjectsSection: FC<CustomerObjectsSectionProps> = ({ customerObjects, contact }) => {
    const { t } = useTranslation()
    const { isDesktop } = useContext(ScreenContext)

    const objects = get(customerObjects, 'objects', [])
    const objectOverviewLink = get(customerObjects, 'objectOverviewLink', '')
    const isHMSObject = get(contact, 'isHMSObject', false)

    if (isEmpty(objects) || isHMSObject) {
        return null
    }

    return (
        <>
            <CustomerObjectsSectionWrapper>
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('accomodations.customerObjectsSectionTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>
                <SliderContainer>
                    <StyledCarouselProvider
                        totalSlides={objects.length}
                        visibleSlides={isDesktop ? 3 : 1}
                        naturalSlideWidth={1}
                        naturalSlideHeight={1}
                        isIntrinsicHeight
                        lockOnWindowScroll
                        dragStep={1}
                        infinite={false}
                    >
                        <Typography variant="subtitle1" fontWeight="regular">
                            {t('accomodations.customerObjectsTitle', { name: get(contact, 'name', '') })}
                        </Typography>
                        <StyledSlider $transitionTime={0.2}>
                            {!isEmpty(objects) &&
                                objects.map((custObject, index) => {
                                    const imageUrl = get(custObject, 'imageUrl', '')
                                    const exposeUrl = get(custObject, 'exposeUrl', '')
                                    const title = get(custObject, 'title')

                                    if (!imageUrl) {
                                        return null
                                    }
                                    return (
                                        <ExternalLink as="a" href={exposeUrl} key={`${title}_${index}`}>
                                            <ImageWrapper>
                                                <ImageLoadingComponent src={imageUrl} alt={title} borderRadius="8px" />
                                            </ImageWrapper>
                                        </ExternalLink>
                                    )
                                })}
                        </StyledSlider>
                        {objectOverviewLink && (
                            <ExternalLink as="a" href={objectOverviewLink}>
                                <Typography variant="subtitle2" fontWeight="regular" textDecoration="underline">
                                    {t('accomodations.showAllAccomodations')}
                                </Typography>
                            </ExternalLink>
                        )}
                    </StyledCarouselProvider>
                </SliderContainer>
            </CustomerObjectsSectionWrapper>
            <ComponentSeparator />
        </>
    )
}

export default CustomerObjectsSection
