import styled from 'styled-components'
import { desktopBreakpoint } from 'react-components'

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex-wrap: wrap;
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
`

export const ListItem = styled.li`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    min-width: 48%;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        min-width: 30%;
    }
`

export const Bullet = styled.span`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const StyledTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`
