import React, { useCallback, memo } from 'react'
import { LocationBarContainer, MapAnchor, MapPinIcon, StyledLink } from './styles'
import { Typography } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import { FormattedBreadcrumbTransport } from '../../../models/property-details-page/breadcrumb-model'
import { SECTION_IDS } from '../constants'

// TODO Replace color with tfwHyperlink

interface LocationBarProps {
    showMapHyperlink?: boolean
    region: FormattedBreadcrumbTransport | null
}

const LocationBar: React.FC<LocationBarProps> = ({ showMapHyperlink = true, region }) => {
    const { t } = useTranslation()

    const scrollToMap = useCallback((): void => {
        const element = document.getElementById(SECTION_IDS.DISTANCES_SECTION_ID)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [])

    if (!region || !region.title || !region.url) {
        return null
    }
    return (
        <LocationBarContainer>
            <MapPinIcon />
            <StyledLink href={region.url}>
                <Typography variant="subtitle2" fontWeight="highlight">
                    {region.title}
                </Typography>
            </StyledLink>
            {!!showMapHyperlink && (
                <MapAnchor onClick={scrollToMap}>
                    <Typography variant="subtitle2" fontWeight="highlight" color="tfwBlue12">
                        {t('viewOnMap')}
                    </Typography>
                </MapAnchor>
            )}
        </LocationBarContainer>
    )
}

export default memo(LocationBar)
