import React, { FC, memo, useCallback, useMemo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import {
    BreadCrumbIcon,
    BreadCrumbLink,
    BreadCrumbListItem,
    BreadCrumbsContainer,
    BreadCrumbsList,
    BreadCrumbsListMobile,
} from './breadcrumb-section-style'
import { getBreadcrumbSchemaDetail } from '../../utils/miscellaneous-utils'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { commonPageEventActions } from '../../services/analytics-service/actions'
import AnalyticsService from '../../services/analytics-service'
import BreadcrumbTransport from '../../transports/common/breadcrumb-transport'
import { useTranslation } from '../../hooks/locale'
import { useRouting } from '../../hooks/routes'

import VectorIcon from '../../assets/icons/vector.svg'

interface Props {
    breadcrumbs: BreadcrumbTransport[]
    url: string
    hideMobile?: boolean
    containerPadding?: string
}

const BreadcrumbSection: FC<Props> = ({ breadcrumbs, url, hideMobile = false, containerPadding }) => {
    const { t } = useTranslation()
    const { r } = useRouting()
    const breadcrumbSchemaHtml = useMemo(() => getBreadcrumbSchemaDetail(breadcrumbs), [breadcrumbs])
    const breadCrumbClickHandler = useCallback(
        item => {
            const eventCategory = `${landingPageEventCategories.searchSection.BREADCRUMB} - ${url}`
            const eventAction = commonPageEventActions.breadcrumbSection.BREADCRUMB
            AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: String(item.url) })
        },
        [url],
    )

    const homePageBreadcrumbIndex = 0
    const holidayIdeaBreadcrumbIndex = 1
    const holidayIdeaTypeBreadcrumbIndex = 2
    const regionBreadcrumbIndex = breadcrumbs.length - 1

    if (
        breadcrumbs.length > 4 &&
        breadcrumbs[holidayIdeaBreadcrumbIndex].title === t('vacationIdeasBreadcrumbTitle') &&
        breadcrumbs[regionBreadcrumbIndex]?.title === t('kroatienRegionBreadcrumbTitle')
    ) {
        const kroatienRegionBreadcrumb: BreadcrumbTransport = {
            title: t('kroatienRegionBreadcrumbTitle'),
            url: `${t('traumBaseUrl')}${r('kroatienUrl')}`,
        }

        const testingBreadcrumb: BreadcrumbTransport[] = [
            breadcrumbs[homePageBreadcrumbIndex],
            kroatienRegionBreadcrumb,
            breadcrumbs[holidayIdeaTypeBreadcrumbIndex],
        ]

        breadcrumbs = testingBreadcrumb
    }

    return (
        <BreadCrumbsContainer containerPadding={containerPadding}>
            <script type="application/ld+json" dangerouslySetInnerHTML={breadcrumbSchemaHtml} />
            {!hideMobile ? (
                <BreadCrumbsListMobile>
                    {breadcrumbs.map((item, index) =>
                        index === 0 && breadcrumbs.length > 3 ? (
                            '. . .'
                        ) : index >= breadcrumbs.length - 3 ? (
                            <BreadCrumbListItem key={`${url}_${index}`} onClick={() => breadCrumbClickHandler(item)}>
                                {index !== 0 && <BreadCrumbIcon as={VectorIcon as AnyStyledComponent} />}
                                <BreadCrumbLink title={item.title} href={item.url}>
                                    {item.title}
                                </BreadCrumbLink>
                            </BreadCrumbListItem>
                        ) : (
                            ''
                        ),
                    )}
                </BreadCrumbsListMobile>
            ) : null}
            <BreadCrumbsList>
                {breadcrumbs.map((item, index) => (
                    <BreadCrumbListItem key={`${url}_${index}`} onClick={() => breadCrumbClickHandler(item)}>
                        {index !== 0 && <BreadCrumbIcon as={VectorIcon as AnyStyledComponent} />}
                        <BreadCrumbLink title={item.title} href={item.url}>
                            {item.title}
                        </BreadCrumbLink>
                    </BreadCrumbListItem>
                ))}
            </BreadCrumbsList>
        </BreadCrumbsContainer>
    )
}

export default memo(BreadcrumbSection)
