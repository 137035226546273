import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'
import { CarouselProvider, Slider } from 'pure-react-carousel'
import CloseIcon from '../../../assets/icons/close.svg'

export const GalleryWrapper = styled.div`
    width: 100%;
    aspect-ratio: 4 / 3;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: ${({ theme }) => theme.spacing.medium};
    }
`

export const ThumnailImageWrapper = styled.div<{ isActive: boolean }>`
    display: flex;
    flex: 1;
    width: 6.5rem;
    height: 4.875rem;
    background-color: ${({ theme }) => theme.colors.tfwBlack40};
    margin: ${({ theme }) => `${theme.spacing.small} 0`};
    border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.tfwBlack40}` : 'none')};
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
`

export const ThumbnailIcon = styled.div<{ left: boolean }>`
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ left }) => (left ? 'left: -8px;' : 'right: -8px;')}
`

export const StyledSlider = styled(Slider)`
    padding: 0;
`

export const StyledCarouselProvider = styled(CarouselProvider)`
    position: relative;
    height: 100px;
    padding-left: 8px;
    li {
        list-style: none;
    }
`

export const GalleryHeaderWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    padding: ${props => props.theme.spacing.small};
    align-items: ${props => props.theme.style.alignItems.center};
    justify-content: ${props => props.theme.style.justifyContent.spaceBetween};
    padding-left: 10%;
    padding-right: 10%;
    margin: 0 auto;
`

export const GalleryFooterWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.column};
    gap: ${({ theme }) => theme.spacing.small};
    padding: ${props => props.theme.spacing.small};
    align-items: ${props => props.theme.style.alignItems.center};
    justify-content: ${props => props.theme.style.justifyContent.spaceBetween};
    @media only screen and (min-width: ${tabletBreakpoint.max}px) {
        display: none;
    }
`

export const GalleryButtonWrapper = styled.div`
    display: none;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex-wrap: ${({ theme }) => theme.style.flexWrap.wrap};
    gap: ${({ theme }) => theme.spacing.small};
    margin-right: ${({ theme }) => theme.spacing.small};
    justify-content: ${({ theme }) => theme.style.justifyContent.flexEnd};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    @media only screen and (min-width: ${tabletBreakpoint.max}px) {
        display: ${({ theme }) => theme.style.display.flex};
        flex: 1;
    }
`

export const GalleryButton = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
`

export const GalleryFooterButton = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    width: 100%;
`

export const StyledCloseIcon = styled(CloseIcon)`
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }
`
