import React, { FC } from 'react'
import { DescriptionBoxWrapper, ReadMoreWrapper } from './styles'
import { ReadMore, Typography } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import SpecialFeatures from '../special-features'
import { ExposeSectionTitleWrapper, ComponentSeparator } from '../style'
import { PropertyDescriptionTransport } from '../../../models/property-details-page/property-description-model'
import isEmpty from 'lodash/isEmpty'
import { SECTION_IDS } from '../constants'

interface DescriptionProps {
    description: PropertyDescriptionTransport
}

const Description: FC<DescriptionProps> = ({ description }) => {
    const { t } = useTranslation()
    const shouldRenderDescription = !isEmpty(description.text) || !isEmpty(description.special)
    if (!shouldRenderDescription) {
        return null
    }
    return (
        <section id={SECTION_IDS.DESCRIPTION_SECTION_ID}>
            <DescriptionBoxWrapper>
                <ExposeSectionTitleWrapper>
                    <Typography variant="h6" fontWeight="semiBold" element="h2">
                        {t('descriptionTitle')}
                    </Typography>
                </ExposeSectionTitleWrapper>
                <ReadMoreWrapper>
                    <ReadMore
                        text={description.text}
                        readLessLabel={t('readLessLabel')}
                        readMoreLabel={t('readMoreLabel')}
                        typographyProps={{
                            variant: 'subtitle2',
                        }}
                    />
                </ReadMoreWrapper>
                {!isEmpty(description.special) && <SpecialFeatures feature={description.special} />}
            </DescriptionBoxWrapper>
            <ComponentSeparator />
        </section>
    )
}

export default Description
