import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const StyledSearchBarWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    padding: 0;
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${props => `${props.theme.spacing.xxSmall} ${props.theme.spacing.large}`};
    }
`
