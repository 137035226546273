import React, { FC, memo, useMemo } from 'react'
import { FreeCancellationWrapper, FreeCancellationHeader, FreeCancellationImage } from './styles'
import { useTranslation } from '../../../hooks/locale'
import { Typography } from 'react-components'
import { ComponentSeparator } from '../style'
import CancellationItem from './cancellation-item'

interface FreeCancellationProps {
    isFreeCancellationAvailable: boolean
    freeCancellationDays: number
}

const FreeCancellation: FC<FreeCancellationProps> = ({ isFreeCancellationAvailable, freeCancellationDays }) => {
    const { t } = useTranslation()

    // Memoize the translated content to avoid unnecessary re-renders
    const title = useMemo(() => t('freeCancellations.title'), [t])
    const daysText = useMemo(
        () => t('freeCancellations.days', { freeCancellationDays: freeCancellationDays }),
        [t, freeCancellationDays],
    )

    const description = useMemo(() => t('freeCancellations.description'), [t])

    // Early return if free cancellation is not available or days are not provided
    if (!isFreeCancellationAvailable || !freeCancellationDays) {
        return null
    }

    return (
        <>
            <FreeCancellationWrapper>
                <FreeCancellationHeader>
                    <FreeCancellationImage />
                    <Typography variant="h6" fontWeight="semiBold">
                        {title}
                    </Typography>
                </FreeCancellationHeader>
                <CancellationItem text={daysText} />
                <CancellationItem text={description} />
            </FreeCancellationWrapper>
            <ComponentSeparator />
        </>
    )
}

export default memo(FreeCancellation)
