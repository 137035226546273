import styled from 'styled-components'

export const SpeacialFeatureWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.small}`};
    border-radius: ${({ theme }) =>
        `${theme.typography.border.radius.medium} 0 0 ${theme.typography.border.radius.medium}`};
    border-left: 2px solid #8055c3;
    background: #f9f5ff;
    opacity: 0px;
`

export const SpecialFeatureTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => `${theme.spacing.xxSmall} 0`};
`
