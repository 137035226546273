type PathParams = Record<string, string | number>
type QueryParams = Record<string, string | number | boolean | null | undefined>

/**
 * Builds a URL path by replacing path parameters and appending query parameters
 * @param pattern - The route pattern (e.g., "user/:id/profile")
 * @param pathParams - Object containing values for path parameters
 * @param queryParams - Optional object containing query parameters
 * @returns Formatted URL string
 */
export const buildPath = (pattern: string, pathParams: PathParams = {}, queryParams: QueryParams = {}): string => {
    // Handle optional parameters marked with ?
    const processOptionalParams = (path: string): string => {
        return path.replace(/\/{(\w+)\?}/g, (_, param) => {
            return pathParams[param] ? `/${pathParams[param]}` : ''
        })
    }

    // Replace required path parameters
    const processRequiredParams = (path: string): string => {
        return path.replace(/{(\w+)}/g, (_, param) => {
            if (!pathParams[param]) {
                throw new Error(`Missing required path parameter: ${param}`)
            }
            return String(pathParams[param])
        })
    }

    // Build query string from parameters
    const buildQueryString = (params: QueryParams): string => {
        const validParams = Object.entries(params)
            .filter(([_, value]) => value !== null && value !== undefined)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
            .join('&')

        return validParams ? `?${validParams}` : ''
    }

    // Process the pattern
    let result = pattern
    result = processOptionalParams(result)
    result = processRequiredParams(result)
    result += buildQueryString(queryParams)

    return result
}
