import styled from 'styled-components'

export const SharePopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const SharePopoverHeader = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    flex: 1;
    padding: ${({ theme }) => theme.spacing.medium};
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.tfwBlack10};
`

export const ShareContentContainer = styled.div`
    padding: ${({ theme }) => theme.spacing.medium};
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    gap: ${({ theme }) => theme.spacing.medium};
    justify-content: center;
    align-items: center;
    min-width: 370px;
    width: 100%;
`
