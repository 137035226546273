import styled from 'styled-components'

export const DistancesWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`

export const DistancesTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: ${({ theme }) => theme.spacing.xSmall} 0;
`

export const DistancesItemsWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex-wrap: ${({ theme }) => theme.style.flexWrap.wrap};
    justify-content: ${({ theme }) => theme.style.justifyContent.flexStart};
`

export const DistancesItem = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    flex: 1;
    padding: ${({ theme }) => theme.spacing.xSmall} 0;
    min-width: 30%;
    width: 30%;
    max-width: 30%;
`
