import React, { FC } from 'react'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { GalleryFooterButton, GalleryFooterWrapper } from './styles'
import EnquiryButton from '../enquiry-button'
import BookNowButton from '../book-now-button'
import BindingInquiryButton from '../binding-inquiry-button'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import isInquiryButtonVisible from '../side-bar/utils/is-inquiry-button-visible'
import isDirectBookingButtonVisible from '../side-bar/utils/is-direct-booking-button-visible'
import is24HourBookingButtonVisible from '../side-bar/utils/is-24-hour-booking-button-visible'

interface GalleryFooterProps {
    bookingType: FormattedBookingTypeData | null
    searchbar: SearchbarFieldsReturn
}

const GalleryFooter: FC<GalleryFooterProps> = ({ bookingType, searchbar }) => {
    return (
        <GalleryFooterWrapper>
            {isInquiryButtonVisible({ bookingType }) && (
                <GalleryFooterButton>
                    <EnquiryButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                </GalleryFooterButton>
            )}
            {isDirectBookingButtonVisible({ bookingType }) && (
                <GalleryFooterButton>
                    <BookNowButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                </GalleryFooterButton>
            )}
            {is24HourBookingButtonVisible({ bookingType }) && (
                <GalleryFooterButton>
                    <BindingInquiryButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                </GalleryFooterButton>
            )}
        </GalleryFooterWrapper>
    )
}

export default GalleryFooter
