import styled, { AnyStyledComponent } from 'styled-components'
import { tabletBreakpoint } from 'react-components'
import Villa from '../../../../assets/icons/villa.svg'
import Wallet from '../../../../assets/icons/wallet.svg'
import Smile from '../../../../assets/icons/smile.svg'
import MapPin from '../../../../assets/icons/map-pin.svg'

export const Container = styled.div`
    background-color: #f5f5f5;
    padding: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.medium}`};
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`

export const RatingWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.xSmall};
    width: 100%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: flex;
        justify-content: space-between;
    }
`

export const RatingInnerContainer = styled.div`
    display: flex;
    flex: 1;
`

export const IconContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.flexStart};
    padding: ${({ theme }) => `${theme.spacing.xxxSmall} 0`};
    margin-right: ${({ theme }) => theme.spacing.small};
    width: ${({ theme }) => theme.layout.xSmall};
`

export const RatingContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    padding: ${({ theme }) => `${theme.spacing.xxxSmall} 0`};
`

export const VillaIcon = styled(Villa as AnyStyledComponent)`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const WalletIcon = styled(VillaIcon).attrs({ as: Wallet })`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const SmileIcon = styled(VillaIcon).attrs({ as: Smile })`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`

export const MapPinIcon = styled(VillaIcon).attrs({ as: MapPin })`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`
