import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useRequestUserConfigContext } from './request'
import availabilityClient from '../clients/availability-client'
import {
    FetchArrivalDatesByMonthRequest,
    FetchDepartureDatesByMonthRequest,
    AvailabilityCalendarResponseTransport,
} from '../transports/availability-transport'
import { SearchbarFieldsReturn } from '../components/property-details/hooks/use-searchbar'

import format from 'date-fns/format'

const AVAILABILITY_DURATION = 23

export const useAvailabilityForDates = () => {
    const user = useRequestUserConfigContext()
    const [availableDates, setAvailableDates] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const abortControllerRef = useRef<AbortController | null>(null)
    const requestInProgressRef = useRef<string | null>(null)

    const fetchDates = useCallback(
        async (
            params: FetchArrivalDatesByMonthRequest | FetchDepartureDatesByMonthRequest,
            type: 'arrival' | 'departure',
        ) => {
            // Create a cache key
            const cacheKey = `${type}-${JSON.stringify(params)}`

            // Check if this exact request is already in progress
            if (requestInProgressRef.current === cacheKey) {
                return
            }

            // Cancel previous request
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }

            // Set the new request as in progress
            requestInProgressRef.current = cacheKey
            abortControllerRef.current = new AbortController()

            setIsLoading(true)
            setError(null)

            try {
                const response =
                    type === 'arrival'
                        ? await availabilityClient.getAvailableArrivalDates({
                              user,
                              params,
                              signal: abortControllerRef.current.signal,
                          })
                        : await availabilityClient.getAvailableDepartureDates({
                              user,
                              params,
                              signal: abortControllerRef.current.signal,
                          })

                const dates = response?.data?.dates || []

                setAvailableDates(dates)
            } catch (error_) {
                if (error_ instanceof Error && error_.name !== 'AbortError') {
                    setError(error_ as Error)
                }
            } finally {
                setIsLoading(false)
                // Clear the in-progress flag
                if (requestInProgressRef.current === cacheKey) {
                    requestInProgressRef.current = null
                }
            }
        },
        [user],
    )

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }
    }, [])

    return {
        availableDates,
        isLoading,
        error,
        fetchDates,
    }
}

interface UseDynamicAvailabilityProps {
    searchbarData: SearchbarFieldsReturn
    listingId: string
}

export const useDynamicAvailability = ({ searchbarData, listingId }: UseDynamicAvailabilityProps) => {
    const [arrivalBasedAvailableDates, setArrivalBasedAvailableDates] = useState<Date[] | null>(null)
    const [departureBasedAvailableDates, setDepartureBasedAvailableDates] = useState<Date[] | null>(null)

    // Use refs to track previous values to prevent unnecessary fetches
    const prevPropsRef = useRef({
        arrivalDate: null as string | null,
        listingId: null as string | null,
        month: null as string | null,
    })

    const {
        availableDates: arrivalAvailableDates,
        isLoading: isArrivalLoading,
        error: arrivalError,
        fetchDates: fetchArrivalDates,
    } = useAvailabilityForDates()

    const {
        availableDates: departureAvailableDates,
        isLoading: isDepartureLoading,
        error: departureError,
        fetchDates: fetchDepartureDates,
    } = useAvailabilityForDates()

    const convertToDateArray = useCallback((dates: string[]): Date[] => dates.map(dateStr => new Date(dateStr)), [])

    // Memoize date-related values to prevent recalculations
    const dateValues = useMemo(() => {
        const referenceDate = searchbarData?.arrival ? new Date(searchbarData.arrival) : new Date()
        return {
            currentMonth: format(referenceDate, 'yyyy-MM'),
            currentArrivalDate: searchbarData?.arrival ? format(searchbarData.arrival, 'yyyy-MM-dd') : null,
        }
    }, [searchbarData?.arrival])

    // Fetch arrival dates only when necessary
    useEffect(() => {
        const { currentMonth } = dateValues

        // Skip if data already loaded, or if listing and month haven't changed
        if (
            arrivalBasedAvailableDates !== null &&
            prevPropsRef.current.listingId === listingId &&
            prevPropsRef.current.month === currentMonth
        ) {
            return
        }

        const arrivalParams = {
            month: currentMonth,
            duration: AVAILABILITY_DURATION,
            listingId,
        }

        fetchArrivalDates(arrivalParams, 'arrival')

        // Update prev props
        prevPropsRef.current.listingId = listingId
        prevPropsRef.current.month = currentMonth
    }, [dateValues.currentMonth, listingId, fetchArrivalDates, arrivalBasedAvailableDates, dateValues])

    // Fetch departure dates only when necessary
    useEffect(() => {
        const { currentMonth, currentArrivalDate } = dateValues

        // Skip if no arrival date or if arrival date hasn't changed
        if (
            !currentArrivalDate ||
            (prevPropsRef.current.arrivalDate === currentArrivalDate && prevPropsRef.current.listingId === listingId)
        ) {
            return
        }

        const departureParams = {
            month: currentMonth,
            arrivalDate: currentArrivalDate,
            duration: AVAILABILITY_DURATION,
            listingId,
        }

        fetchDepartureDates(departureParams, 'departure')

        // Update prev props
        prevPropsRef.current.arrivalDate = currentArrivalDate
        prevPropsRef.current.listingId = listingId
    }, [dateValues.currentArrivalDate, dateValues.currentMonth, listingId, fetchDepartureDates, dateValues])

    // Update state when arrival dates change
    useEffect(() => {
        if (arrivalAvailableDates.length > 0) {
            setArrivalBasedAvailableDates(convertToDateArray(arrivalAvailableDates))
        }
    }, [arrivalAvailableDates, convertToDateArray])

    // Update state when departure dates change
    useEffect(() => {
        if (departureAvailableDates.length > 0) {
            setDepartureBasedAvailableDates(convertToDateArray(departureAvailableDates))
        }
    }, [departureAvailableDates, convertToDateArray])

    // Memoize the active dates to prevent unnecessary recalculations
    const activeAvailableDates = useMemo(() => {
        return searchbarData?.arrival && !searchbarData?.departure
            ? departureBasedAvailableDates
            : arrivalBasedAvailableDates
    }, [searchbarData?.arrival, searchbarData?.departure, departureBasedAvailableDates, arrivalBasedAvailableDates])

    return {
        departureBasedAvailableDates,
        arrivalBasedAvailableDates,
        availableDates: activeAvailableDates,
        isLoading: isArrivalLoading || isDepartureLoading,
        error: arrivalError || departureError,
    }
}

// The custom hook to fetch calendar availability
const useCalendarAvailability = ({ listingId }: { listingId: string }) => {
    const user = useRequestUserConfigContext()
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<AvailabilityCalendarResponseTransport | null>(null)
    const [error, setError] = useState<boolean | null>(false)

    useEffect(() => {
        const fetchAvailability = async () => {
            setLoading(true)
            setError(false)
            try {
                const response = await availabilityClient.getCalendarAvailability({
                    user,
                    listingId,
                })

                setData(response.data)
            } catch {
                setError(true)
            } finally {
                setLoading(false)
            }
        }

        fetchAvailability()
    }, [listingId, user])

    return { loading, data, error }
}

export default useCalendarAvailability
