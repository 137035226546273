import React, { FC, memo, useCallback, useContext, useState, useEffect } from 'react'
import { ScreenContext, Typography } from 'react-components'
import { FooterContainer, PriceDetailsWrapper, ButtonsWrapper, AmountWrapper } from './styles'
import { STICKY_FOOTER_SCROLL_THRESHOLD, PdpToCheckoutPosition } from '../constants'
import EnquiryButton from '../enquiry-button'
import isInquiryButtonVisible from '../side-bar/utils/is-inquiry-button-visible'
import BindingInquiryButton from '../binding-inquiry-button'
import BookNowButton from '../book-now-button'
import is24HourBookingButtonVisible from '../side-bar/utils/is-24-hour-booking-button-visible'
import isDirectBookingButtonVisible from '../side-bar/utils/is-direct-booking-button-visible'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'
import { getDateRange } from '../../../utils/date-utils'
import { useTranslation } from '../../../hooks/locale'
import { useDateFormatter } from '../../../hooks/date'

interface StickyFooterProps {
    bookingType: FormattedBookingTypeData | null
    searchbar: SearchbarFieldsReturn
    pricingDetails: FormattedLivePricingDataTransport | null
}
const StickyFooter: FC<StickyFooterProps> = ({ bookingType, searchbar, pricingDetails }) => {
    const { isMobile } = useContext(ScreenContext)
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()
    const { format } = useDateFormatter()

    const handleScroll = useCallback(() => {
        if (window.scrollY > STICKY_FOOTER_SCROLL_THRESHOLD) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    const totalPersons = searchbar.adults + searchbar.kids
    const dateRange =
        searchbar.arrival && searchbar.departure
            ? getDateRange({ startDate: searchbar.arrival, endDate: searchbar.departure, format })
            : null

    const price = pricingDetails?.price

    if (!isMobile) {
        return null
    }

    return (
        <FooterContainer id="sticky-footer" visible={visible}>
            {totalPersons && dateRange && price && (
                <PriceDetailsWrapper>
                    {dateRange && totalPersons && (
                        <Typography variant="subtitle1" color="tfwBlack40" fontWeight={'regular'}>
                            {`${dateRange}, ${t('guestsCount', { count: totalPersons })}`}
                        </Typography>
                    )}
                    <AmountWrapper>
                        {price && (
                            <Typography variant="subtitle1" fontWeight="semiBold">
                                {price}
                            </Typography>
                        )}
                    </AmountWrapper>
                </PriceDetailsWrapper>
            )}
            <ButtonsWrapper>
                {isInquiryButtonVisible({ bookingType }) && (
                    <EnquiryButton
                        bookingType={bookingType}
                        searchbar={searchbar}
                        position={PdpToCheckoutPosition.STICKY_FOOTER}
                        showShortTitle
                    />
                )}
                {isDirectBookingButtonVisible({ bookingType }) && (
                    <BookNowButton
                        bookingType={bookingType}
                        searchbar={searchbar}
                        position={PdpToCheckoutPosition.STICKY_FOOTER}
                        showShortTitle
                    />
                )}
                {is24HourBookingButtonVisible({ bookingType }) && (
                    <BindingInquiryButton
                        bookingType={bookingType}
                        searchbar={searchbar}
                        position={PdpToCheckoutPosition.STICKY_FOOTER}
                        showShortTitle
                    />
                )}
            </ButtonsWrapper>
        </FooterContainer>
    )
}

export default memo(StickyFooter)
