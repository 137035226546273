import React from 'react'
import { List, Bullet, ListItem, StyledTitle } from './styles'
import { Typography } from 'react-components'
import upperFirst from 'lodash/upperFirst'

interface ActivitiesProps {
    activities: string[]
}

const Activities: React.FC<ActivitiesProps> = ({ activities }) => {
    if (!activities) {
        return null
    }

    return (
        <List>
            {activities.map((activity, index) => (
                <ListItem key={index}>
                    <Bullet>
                        <Typography variant="subtitle2" color="tfwBlack40" fontWeight="regular">
                            •
                        </Typography>
                    </Bullet>
                    <StyledTitle>
                        <Typography variant="subtitle2" color="tfwBlack40" fontWeight="regular">
                            {upperFirst(activity)}
                        </Typography>
                    </StyledTitle>
                </ListItem>
            ))}
        </List>
    )
}

export default Activities
