import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const WishlistShareBarContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    gap: ${({ theme }) => theme.spacing.xSmall};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    position: ${({ theme }) => theme.style.position.absolute};
    justify-content: ${({ theme }) => theme.style.justifyContent.flexEnd};
    width: 100%;
    padding: 0 16px;
    top: 62px;
    z-index: 1;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 100%;
        max-width: 1080px;
        padding: 0 24px;
        top: 166px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }
`
