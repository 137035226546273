import { BreadcrumbTypeEnum } from '../../common/enum'
import { TranslationFunction } from '../../common/types'
import { BreadcrumbTransport } from '../../transports/property-details-page-transport'

export interface FormattedBreadcrumbTransport {
    title: string
    url: string
}

export class Breadcrumb {
    private readonly label?: string
    private readonly link?: string
    private readonly isRegionLink: boolean
    private readonly type: BreadcrumbTypeEnum

    constructor(type: BreadcrumbTypeEnum, label?: string, link?: string, isRegionLink = false) {
        this.label = label
        this.link = link
        this.isRegionLink = isRegionLink
        this.type = type
    }

    public getIsRegionLink(): boolean {
        return this.isRegionLink
    }

    public format(translationFunction: TranslationFunction, exposeLabel: string): FormattedBreadcrumbTransport {
        let title: string
        let url: string | undefined

        switch (this.type) {
            case BreadcrumbTypeEnum.HOME:
                title = this.label ?? translationFunction('homePage')
                url = this.link ?? '/'
                break
            case BreadcrumbTypeEnum.EXPOSE_LABEL:
                title = this.label ?? exposeLabel
                url = this.link ?? ''
                break
            default:
                title = this.label ?? 'N/A' // This case should never happen add more implementations if needed
                url = this.link ?? ''
                break
        }

        return {
            title,
            url,
        }
    }
}

export class BreadcrumbList {
    private readonly __: TranslationFunction

    constructor(translationFunction: TranslationFunction) {
        this.__ = translationFunction
    }

    format(breadcrumbs: BreadcrumbTransport[] = [], exposeLabel: string): FormattedBreadcrumbTransport[] {
        return breadcrumbs.map(b => {
            const breadcrumb = new Breadcrumb(b.type, b.label, b.link, b.isRegionLink)
            return breadcrumb.format(this.__, exposeLabel)
        })
    }

    public getLastRegionLink(
        breadcrumbs: BreadcrumbTransport[],
        exposeLabel: string,
    ): FormattedBreadcrumbTransport | null {
        for (let i = breadcrumbs.length - 1; i >= 0; i--) {
            const breadcrumb = new Breadcrumb(
                breadcrumbs[i].type,
                breadcrumbs[i].label,
                breadcrumbs[i].link,
                breadcrumbs[i].isRegionLink,
            )

            if (breadcrumb.getIsRegionLink()) {
                return breadcrumb.format(this.__, exposeLabel)
            }
        }
        return null
    }
}
