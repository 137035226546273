import React from 'react'
import { Provider, Gender } from '../../../models/property-details-page/contact-model'
import { StyledImageContainer, StyledMaleIcon, StyledFemaleIcon } from './styles'
import isEmpty from 'lodash/isEmpty'

interface OwnerImageProps {
    imageUrl?: string | null
    gender?: Gender
    providerId?: Provider
    width?: string
    height?: string
    borderRadius?: string
    marginRight?: string
    name?: string
}

const OwnerImage: React.FC<OwnerImageProps> = ({
    imageUrl,
    gender = Gender.MALE,
    providerId,
    width,
    height,
    borderRadius,
    marginRight,
    name,
}) => {
    providerId = Number(providerId)
    const finalImageSrc =
        providerId === Provider.PROVIDER_BELVILLA ||
        providerId === Provider.PROVIDER_TUI ||
        providerId === Provider.PROVIDER_DANCENTER ||
        providerId === Provider.PROVIDER_HOMETOGO
            ? ''
            : imageUrl

    if (isEmpty(finalImageSrc)) {
        return gender === Gender.MALE ? (
            <StyledMaleIcon width={width} height={height} borderRadius={borderRadius} marginRight={marginRight} />
        ) : (
            <StyledFemaleIcon width={width} height={height} borderRadius={borderRadius} marginRight={marginRight} />
        )
    }

    return (
        <>
            <StyledImageContainer
                src={finalImageSrc || ''}
                width={width}
                height={height}
                borderRadius={borderRadius}
                marginRight={marginRight}
                alt={name}
            />
        </>
    )
}

export default OwnerImage
