import React from 'react'
import { ShareContentContainer } from './styles'
import { Typography } from 'react-components'
import { useTranslation } from '../../../../hooks/locale'
import { StyledGhostButton, CTAButton } from '../../style'

const handleShareButtonClick = async ({ url, key, onClose }: { url: string; key: string; onClose: () => void }) => {
    onClose()
    if (key === 'page_url') {
        try {
            await navigator.clipboard.writeText(url)
        } catch (error) {
            console.error('Failed to copy the link:', error)
        }
        return
    }
    if (url.startsWith('mailto:') || url.startsWith('whatsapp:')) {
        window.location.href = url
        return
    } else {
        window.open(url, '_blank', 'noopener,noreferrer')
        return
    }
}

const SharePopoverContent = ({
    shareLinks,
    onClose,
}: {
    shareLinks: { [key: string]: { url: string; translationKey: string; iconKey: string } }
    onClose: () => void
}) => {
    const { t } = useTranslation()

    return (
        <ShareContentContainer>
            {Object.entries(shareLinks).map(([key, value]) => (
                <CTAButton
                    key={key}
                    onClick={async () => {
                        await handleShareButtonClick({ url: value.url, key, onClose })
                    }}
                >
                    <StyledGhostButton isPrimary={false} isContentWidth={false}>
                        <Typography variant="subtitle2" fontWeight="regular">
                            {t(value.translationKey)}
                        </Typography>
                    </StyledGhostButton>
                </CTAButton>
            ))}
        </ShareContentContainer>
    )
}

export default SharePopoverContent
