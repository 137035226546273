function getShareLinks(shareUrl: string): { [key: string]: { url: string; translationKey: string; iconKey: string } } {
    const encode = encodeURIComponent

    return {
        mail: {
            url: `mailto:?to=&subject=${encode('Check out this listing')}&body=${encode(
                'Here is the link: ' + shareUrl,
            )}`,
            translationKey: 'shareMail',
            iconKey: 'shareMail',
        },
        whatsapp: {
            url: `whatsapp://send?text=${encode(shareUrl)}`,
            translationKey: 'shareWhatsapp',
            iconKey: 'shareWhatsapp',
        },
        facebook: {
            url: `https://www.facebook.com/sharer.php?u=${encode(shareUrl)}`,
            translationKey: 'shareFacebook',
            iconKey: 'shareFacebook',
        },
        messenger: {
            url: `fb-messenger://share/?link=${encode(shareUrl)}`,
            translationKey: 'shareMessenger',
            iconKey: 'shareMessenger',
        },
        page_url: {
            url: shareUrl,
            translationKey: 'shareLink',
            iconKey: 'shareLink',
        },
    }
}

export default getShareLinks
