import styled, { css } from 'styled-components'

export const OwnerInfoWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.column};
        padding: 0 ${theme.spacing.medium};
    `,
)

export const OwnerInfoContent = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing.small} 0`};
    padding-bottom: 0;
`

export const OwnerDetails = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    flex: 1;
`
