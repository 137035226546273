import React, { FC, memo, useContext, useState } from 'react'
import { StyledGhostButton, CTAButton } from '../style'
import { ThemeContext } from 'styled-components'
import { useTranslation } from '../../../hooks/locale'
import { Typography } from 'react-components'
import get from 'lodash/get'
import useDynamicQueryParams from '../hooks/use-dynamic-query-params'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import getChildrenAges from '../utils/get-children-ages'
import AnalyticsService from '../../../services/analytics-service'
import { pdpPageActions } from '../../../services/analytics-service/actions'
import { pdpPageCategories } from '../../../services/analytics-service/categories'
import { useQuery } from '../../../hooks/query'

type ContactButtonProps = {
    bookingType: FormattedBookingTypeData | null
    searchbar: SearchbarFieldsReturn
}

const ContactButton: FC<ContactButtonProps> = props => {
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()
    const query = useQuery()
    const { bookingType, searchbar } = props
    const [isLoading, setIsLoading] = useState(false)
    const dynamicQueryParams = JSON.parse(get(bookingType, 'subType.dynamicQueryParams', '{}'))
    const childrenAges = getChildrenAges(searchbar.kids)
    const qpValues = { ...query, ...searchbar, childrenAges }
    const url = useDynamicQueryParams(bookingType?.subType, qpValues, dynamicQueryParams)
    const type = get(bookingType, 'subType.type')

    const onContactButtonClick = (event: React.MouseEvent) => {
        setTimeout(() => {
            AnalyticsService.trackEvent({
                category: pdpPageCategories.PDP_PAGE,
                action: pdpPageActions.CONTACT_ENTER_DETAILS_BUTTON_CLICKED,
            })
        }, 0)
        if (type === 'redirect') {
            event.preventDefault()
            setIsLoading(true)
            window.open(url, '_blank')
            setIsLoading(false)
        } else {
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
            }, 5000)
        }
    }

    return (
        <CTAButton
            contentAlignment={theme.style.justifyContent.flexStart}
            margin={'1rem 0 0 0'}
            onClick={onContactButtonClick}
        >
            <StyledGhostButton
                isPrimary={false}
                isContentWidth
                disabled={isLoading ? true : undefined}
                loading={isLoading ? true : undefined}
                href={url}
            >
                <Typography variant="subtitle2" fontWeight="regular">
                    {t('contact.contactEnterDetails')}
                </Typography>
            </StyledGhostButton>
        </CTAButton>
    )
}

export default memo(ContactButton)
