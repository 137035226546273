import React, { useContext, useMemo, memo } from 'react'
import MonthCalendar from './month-calendar'
import {
    AvailabilityBoxWrapper,
    CalendarNavigationBar,
    DescriptionBar,
    LegendWrapper,
    Legend,
    LegendBar,
} from './styles'
import { useTranslation } from '../../../hooks/locale'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { ScreenContext, Typography } from 'react-components'
import AvailabilityCalendarBack from './availabily-calendar-back'
import AvailabilityCalendarNext from './availability-calendar-next'
import { ExposeSectionTitleWrapper, StyledGhostButton, CTAButton } from '../style'
import { SECTION_IDS } from '../constants'
import generateSkeletonData from './generate-skeleton-data'

interface SkeletonAvailabilityCalendarProps {
    isLoading?: boolean
    error?: Error | null
    pastDelay?: boolean
}

const SkeletonAvailabilityCalendar: React.FC<SkeletonAvailabilityCalendarProps> = () => {
    const { t } = useTranslation()
    const { isMobile, isTablet } = useContext(ScreenContext)

    const monthsData = useMemo(() => {
        return generateSkeletonData()
    }, [])

    return (
        <section id={SECTION_IDS.AVAILABILITY_SECTION_ID}>
            <CarouselProvider
                totalSlides={monthsData.length}
                visibleSlides={isMobile || isTablet ? 1 : 2}
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                isIntrinsicHeight
                lockOnWindowScroll
                dragStep={1}
                step={1}
            >
                <AvailabilityBoxWrapper>
                    <ExposeSectionTitleWrapper>
                        <Typography variant="h6" fontWeight="semiBold" element="h2">
                            {t('availabilityTitle')}
                        </Typography>
                    </ExposeSectionTitleWrapper>
                    <CalendarNavigationBar>
                        <AvailabilityCalendarBack />
                        <CTAButton>
                            <StyledGhostButton isPrimary={false} isContentWidth>
                                <Typography variant="subtitle2" fontWeight="regular">
                                    {t('calendarViewTypeMonth')}
                                </Typography>
                            </StyledGhostButton>
                        </CTAButton>
                        <AvailabilityCalendarNext numberOfSlides={monthsData.length} />
                    </CalendarNavigationBar>
                    <Slider>
                        {monthsData.map((month, index) => (
                            <Slide key={month.label} index={index}>
                                <MonthCalendar data={month} />
                            </Slide>
                        ))}
                    </Slider>
                    <DescriptionBar>
                        <LegendBar>
                            <Typography variant="subtitle1" fontWeight="highlight">
                                {t('legendTranslation')}:
                            </Typography>
                            <LegendWrapper>
                                <Legend available />
                                <Typography variant="subtitle2">{t('freeLabel')}</Typography>
                            </LegendWrapper>
                            <LegendWrapper>
                                <Legend available={false} />
                                <Typography variant="subtitle2">{t('occupiedLabel')}</Typography>
                            </LegendWrapper>
                        </LegendBar>
                    </DescriptionBar>
                </AvailabilityBoxWrapper>
            </CarouselProvider>
        </section>
    )
}

export default memo(SkeletonAvailabilityCalendar)
