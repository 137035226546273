import styled from 'styled-components'

export const RuleContainer = styled.div``

export const Rule = styled.div`
    margin: ${({ theme }) => theme.spacing.xSmall} 0 0 0;
`

export const RuleHeader = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    gap: ${({ theme }) => theme.spacing.medium};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const IconContainer = styled.div`
    height: ${({ theme }) => theme.layout.xSmall};
    width: ${({ theme }) => theme.layout.xSmall};
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
`

export const Icon = styled.img``

export const RuleItemsWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: 0 0 0.125rem 3.25rem;
`
