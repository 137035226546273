import { tabletBreakpoint } from 'react-components'
import styled from 'styled-components'

export const PriceInputWrapper = styled.div<{ layout: 'compact' | 'stretched' }>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex-wrap: ${({ theme }) => theme.style.flexWrap.wrap};
    flex: 1;
    gap: ${({ theme, layout }) => (layout === 'stretched' ? theme.spacing.small : 0)};
    margin-bottom: ${({ theme }) => theme.spacing.small};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const PriceInput = styled.div<{ layout: 'compact' | 'stretched' }>`
    display: ${({ theme }) => theme.style.display.flex};
    min-width: ${({ layout }) => (layout === 'stretched' ? '100%' : '49%')};
`

export const PriceComponentSeparator = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    height: 1px;
    border: 1px dotted #d3d3d3;
    margin: ${({ theme }) => `${theme.spacing.small} 0`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => `${theme.spacing.medium} 0`};
    }
`

export const PriceCostContentWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    padding: 2px 0;
`

export const CTAButton = styled.div`
    display: flex;
    flex: 1;
    background: ${props => props.theme.colors.white};
`
export const ButtonText = styled.div``
