import LivePricingModel from './live-pricing-model'
import {
    OptionalCostTransport,
    InclusiveCostTransport,
    ExclusiveCostTransport,
    SpecialOfferTransport,
    AdditionalServiceTransport,
    ArticleTransport,
    TaxTransport,
    AutoCorrectionTransport,
} from '../../transports/live-pricing-transport'
import { FormatDateFunction, TranslationFunction } from '../../common/types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { PriceFormatter } from '../../hooks/price'

export interface FormattedLivePricingDataTransport {
    fid: string | null
    autoCorrection: AutoCorrectionTransport | null
    startDate: string
    endDate: string
    adults: number
    childrenAges: number[]
    fromPriceActive: boolean | null
    pets: number
    price: string
    billingAmount: string
    onSiteAmount: string
    currency: string
    optionalCosts: FormattedOptionalCostTransport[] | null
    inclusiveCosts: FormattedInclusiveCostTransport[] | null
    exclusiveCosts: ExclusiveCostTransport[] | null
    usageBoundCosts: string[] | null
    terms: string | null
    redirectUrl: string | null
    specialOffer: FormattedSpecialOffer | null
    additionalServices: AdditionalServiceTransport[] | null
    selectedOptionServices: string[] | null
    optionalServicesQuantity: number[] | null
    articles: ArticleTransport[] | null
    taxes: TaxTransport[] | null
}

export interface FormattedInclusiveCostTransport {
    name: string
    price: string
    addedToPresentPrice: boolean
    includedInBillingAmount: boolean
}

export interface FormattedSpecialOffer {
    discount: string | null
    label: string
    offerInfo: string
    periodPreposition: string
    validToDate: string
    validFromDate: string
    period: string
}

export interface FormattedOptionalCostTransport {
    name: string
    price: string
    totalPrice: string
    unit: string
    maxQuantity: number | null
}

class LivePricingFormatter {
    private p: PriceFormatter
    private formatDate: FormatDateFunction
    private t: TranslationFunction

    constructor(formatDate: FormatDateFunction, t: TranslationFunction, p: PriceFormatter) {
        this.formatDate = formatDate
        this.t = t
        this.p = p
    }

    private formatSpecialOffers = (specialOffers: SpecialOfferTransport[] | null): FormattedSpecialOffer | null => {
        if (isEmpty(specialOffers)) {
            return null
        }
        const specialOfferData = specialOffers ? specialOffers[0] : {}
        if (isEmpty(specialOfferData)) {
            return null
        }
        const specialOfferStartDate = get(specialOfferData, 'startDate')
        const specialOfferEndDate = get(specialOfferData, 'endDate')
        return {
            discount: this.t('propertyOffers.discountPercentage', {
                percentage: get(specialOfferData, 'discountAsPercent'),
            }),
            periodPreposition: this.t('propertyOffers.periodPreposition'),
            label: get(specialOfferData, 'offerType'),
            period:
                specialOfferStartDate && specialOfferEndDate
                    ? `${this.formatDate(new Date(specialOfferStartDate), 'dd.MM.yyyy')} - ${this.formatDate(
                          new Date(specialOfferEndDate),
                          'dd.MM.yyyy',
                      )}`
                    : '',
            validFromDate: specialOfferStartDate ? this.formatDate(new Date(specialOfferStartDate), 'MM-dd-yyyy') : '',
            validToDate: specialOfferEndDate ? this.formatDate(new Date(specialOfferEndDate), 'MM-dd-yyyy') : '',
            offerInfo: get(specialOfferData, 'id'),
        }
    }

    private getFormattedPrice = ({
        value,
        currency,
        variable,
    }: {
        value: number
        currency: string
        variable: boolean
    }): string => {
        return this.p(value, currency, variable) || ''
    }

    private transformInclusiveCosts(costs: InclusiveCostTransport[] | null, currency: string) {
        if (!costs || isEmpty(costs)) {
            return []
        }

        return costs.map(cost => {
            const formattedPrice = this.p(cost.price, currency, false)
            return {
                name: this.t(`price.costItems.${cost.name}`),
                price: formattedPrice || '',
                includedInBillingAmount: cost.includedInBillingAmount,
                addedToPresentPrice: cost.addedToPresentPrice,
            }
        })
    }

    private formatOptionalCosts(priceData: {
        optionalCosts?: OptionalCostTransport[] | null
        currency: string
    }): FormattedOptionalCostTransport[] {
        if (!priceData.optionalCosts || isEmpty(priceData.optionalCosts)) {
            return []
        }
        const currency = get(priceData, 'currency', 'EUR')
        return priceData.optionalCosts.map(optionalCost => {
            const unit = get(optionalCost, 'unit') === 'no_transformation' ? '' : get(optionalCost, 'unit')
            const needToFormatPrice = unit !== 'no_transformation'
            const costName = this.t(`price.costItems.${optionalCost.name}`)
            return 'value' in optionalCost && optionalCost.value !== undefined
                ? {
                      name: costName,
                      price: optionalCost.value.toString() || '',
                      totalPrice: optionalCost.value.toString() || '',
                      unit: 'no_transformation',
                      maxQuantity: optionalCost.maxQuantity ?? null,
                  }
                : {
                      name: costName,
                      price:
                          (needToFormatPrice
                              ? this.p(Number(optionalCost.price), currency, false)
                              : optionalCost.price.toString()) || '',
                      totalPrice:
                          (needToFormatPrice
                              ? this.p(Number(optionalCost.totalPrice), currency, false)
                              : optionalCost.totalPrice.toString()) || '',
                      unit: !isEmpty(optionalCost.unit)
                          ? this.t(`price.costItems.${optionalCost.unit}`)
                          : optionalCost.unit,
                      maxQuantity: optionalCost.maxQuantity ?? null,
                  }
        })
    }

    private formatTaxes(taxes: TaxTransport[] | null): TaxTransport[] {
        if (!taxes || isEmpty(taxes)) {
            return []
        }

        return taxes
            .filter(tax => this.validateTax(tax))
            .map(tax => ({
                feeType: tax.feeType,
                amount: tax.amount,
                included: tax.included,
            }))
    }

    private validateTax(tax: TaxTransport | null): boolean {
        return !!(tax && tax.feeType !== undefined && tax.amount !== undefined && tax.included !== undefined)
    }

    public getAllFormattedData(model: LivePricingModel): FormattedLivePricingDataTransport {
        return {
            fid: model.fid || null,
            autoCorrection: model.autoCorrection || null,
            startDate: model.startDate,
            endDate: model.endDate,
            adults: model.adults,
            childrenAges: model.childrenAges,
            fromPriceActive: model.fromPriceActive || null,
            pets: model.pets,
            price: this.getFormattedPrice({
                value: model.price,
                currency: model.currency,
                variable: model.fromPriceActive || false,
            }),
            billingAmount: this.getFormattedPrice({
                value: model.billingAmount,
                currency: model.currency,
                variable: model.fromPriceActive || false,
            }),
            onSiteAmount: this.getFormattedPrice({
                value: model.onSiteAmount,
                currency: model.currency,
                variable: model.fromPriceActive || false,
            }),
            currency: model.currency,
            optionalCosts: this.formatOptionalCosts(model),
            inclusiveCosts: this.transformInclusiveCosts(model.inclusiveCosts, model.currency),
            exclusiveCosts: model.exclusiveCosts || null,
            usageBoundCosts: model.usageBoundCosts || null,
            terms: model.terms || null,
            redirectUrl: model.redirectUrl || null,
            specialOffer: this.formatSpecialOffers(model.specialOffers),
            additionalServices: model.additionalServices || null,
            selectedOptionServices: model.selectedOptionServices || null,
            optionalServicesQuantity: model.optionalServicesQuantity || null,
            articles: model.articles || null,
            taxes: this.formatTaxes(model.taxes),
        }
    }
}

export default LivePricingFormatter
