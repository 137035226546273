import { useState, useCallback, useEffect } from 'react'
import { CookieManagerService } from '../../../services'

export interface SearchbarFields {
    adults: number | null
    kids: number | null
    arrival: Date | string | null
    departure: Date | string | null
    location: string
}

export interface SearchbarFieldsReturn {
    adults: number
    kids: number
    arrival: Date | null
    departure: Date | null
    location: string
}

type DateFields = 'arrival' | 'departure'
type NumberFields = 'adults' | 'kids'
type StringFields = 'location'

type UpdateFieldValue<T extends keyof SearchbarFields> = T extends DateFields
    ? Date | string | null
    : T extends NumberFields
    ? number
    : T extends StringFields
    ? string
    : never

export type UpdateSearchBarTransport = <T extends keyof SearchbarFields>(field: T, value: UpdateFieldValue<T>) => void

interface UseSearchbarReturn {
    searchbar: SearchbarFieldsReturn
    updateSearchbar: UpdateSearchBarTransport
}

export interface SearchbarProps {
    location?: string
    query?: {
        arrival?: string | Date | null
        departure?: string | Date | null
        kids?: string | number | null
        persons?: string | number | null
    }
}

const DEFAULT_VALUES: SearchbarFieldsReturn = {
    adults: 2,
    kids: 0,
    arrival: null,
    departure: null,
    location: '',
} as const

const convertToDate = (value?: Date | string | null): Date | null => {
    if (!value) {
        return null
    }
    return value instanceof Date ? value : new Date(value)
}

const useSearchbar = ({ location = '', query = {} }: SearchbarProps = {}): UseSearchbarReturn => {
    const searchParams = CookieManagerService.getCookie(CookieManagerService.SEARCH_PARAMS)

    const getSearchbarFields = useCallback((): SearchbarFieldsReturn => {
        const cookieValues = searchParams ? searchParams.split('|') : []

        return {
            arrival:
                query.arrival !== undefined && query.arrival !== null
                    ? convertToDate(query.arrival)
                    : cookieValues.length >= 5
                    ? convertToDate(cookieValues[0])
                    : DEFAULT_VALUES.arrival,

            departure:
                query.departure !== undefined && query.departure !== null
                    ? convertToDate(query.departure)
                    : cookieValues.length >= 5
                    ? convertToDate(cookieValues[1])
                    : DEFAULT_VALUES.departure,

            adults:
                query.persons && query.kids !== undefined
                    ? Number(query.persons) - Number(query.kids)
                    : query.persons !== undefined
                    ? Number(query.persons)
                    : cookieValues.length >= 5 && cookieValues[2]
                    ? Number(cookieValues[2])
                    : DEFAULT_VALUES.adults,

            kids:
                query.kids !== undefined && query.kids !== null
                    ? Number(query.kids)
                    : cookieValues.length >= 5 && cookieValues[3]
                    ? Number(cookieValues[3])
                    : DEFAULT_VALUES.kids,

            location:
                location !== undefined && location !== null && location !== ''
                    ? location
                    : cookieValues.length >= 5
                    ? cookieValues[4]
                    : DEFAULT_VALUES.location,
        }
    }, [location, query, searchParams])

    const [searchbar, setSearchbar] = useState<SearchbarFieldsReturn>(getSearchbarFields)

    useEffect(() => {
        setSearchbar(getSearchbarFields())
    }, [query, location, searchParams, getSearchbarFields])

    const updateSearchbar = useCallback(<T extends keyof SearchbarFields>(field: T, value: UpdateFieldValue<T>) => {
        setSearchbar(prev => ({
            ...prev,
            [field]:
                field === 'arrival' || field === 'departure' ? convertToDate(value as Date | string | null) : value,
        }))
    }, [])

    return {
        searchbar,
        updateSearchbar,
    }
}

export default useSearchbar
