import { desktopBreakpoint } from 'react-components'
import styled, { keyframes, css } from 'styled-components'

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideUp = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`

interface NavContainerProps {
    visible: boolean
    animating: boolean
}

export const NavContainer = styled.nav<NavContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: ${props => props.theme.spacing.medium};
    z-index: 1000;
    display: none;
    animation: ${props => {
        if (!props.animating) {
            return 'none'
        }
        return props.visible
            ? css`
                  ${slideDown} 0.3s ease-in-out forwards
              `
            : css`
                  ${slideUp} 0.3s ease-in-out forwards
              `
    }};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: ${props => (!props.visible && !props.animating ? 'none' : 'block')};
    }
`

export const NavContent = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    display: ${props => props.theme.style.display.flex};
    gap: ${props => props.theme.spacing.small};
`

interface NavButtonProps {
    isActive: boolean
}

export const NavButton = styled.button<NavButtonProps>`
    padding: ${props => `${props.theme.spacing.xSmall} ${props.theme.spacing.medium}`};
    border-radius: ${props => props.theme.typography.border.radius.xxxLarge};
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.theme.colors.white};
    border: ${props =>
        props.isActive ? `1px solid ${props.theme.colors.tfwBlack99}` : `1px solid ${props.theme.colors.tfwBlack10}`};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.tfwBlack10};
    }

    &:focus {
        outline: none;
        ring: 1px;
        ring-offset: 1px;
        ring-color: ${props => props.theme.colors.tfwBlack10};
    }
`

export const WishlistShareButtonWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    gap: ${({ theme }) => theme.spacing.xSmall};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.flexEnd};
`
