import { PropertyDetails } from '../../../transports/property-details-page-transport'

const buildRouteObjectId = (
    providerId: PropertyDetails['providerId'],
    externalObjectId: PropertyDetails['externalObjectId'],
    tfwObjectId?: PropertyDetails['tfwObjectId'],
): string => {
    if (tfwObjectId) {
        return tfwObjectId.toString()
    }

    return `${providerId}-${externalObjectId}`
}

export default buildRouteObjectId
