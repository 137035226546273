import { tabletBreakpoint } from 'react-components'
import styled, { css } from 'styled-components'
import { SuitableStates } from '../../../models/property-details-page/suitables-model'

export const FeaturesContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing.small};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 0;
    }
`

export const FeatureWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    padding: ${({ theme }) => `${theme.spacing.xxSmall} 0`};
    width: 100%;
    align-items: ${({ theme }) => theme.style.alignItems.center};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 50%;
        margin-bottom: ${({ theme }) => theme.spacing.small};
    }
`

export const FeatureIconWrapper = styled.div<{ state: SuitableStates }>`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing.medium};
    position: relative;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: ${({ theme }) => theme.spacing.xSmall};
    }

    ${({ state }) => {
        switch (state) {
            case SuitableStates.SUITABLE_STATUS_NOT_ALLOWED:
                return css`
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 32px;
                        height: 0;
                        border-top: 2px solid ${({ theme }) => theme.colors.tfwNegativeMedium};
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                `
            case SuitableStates.SUITABLE_STATUS_ON_REQUEST:
                return css`
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 32px;
                        height: 0;
                        border-top: 2px dotted ${({ theme }) => theme.colors.tfwAlertMedium};
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                `
            default:
                return ''
        }
    }}
`

export const FeatureIcon = styled.img``
