import React, { FC, memo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Typography } from 'react-components'
import { OwnerDetailItem } from './styles'

const OwnerDetails: FC<{ label: string; value: string | null }> = ({ label, value }) => {
    if (isEmpty(value)) {
        return null
    }

    return (
        <OwnerDetailItem>
            <Typography variant="subtitle1" fontWeight="semiBold">
                {label}
            </Typography>
            <Typography variant="subtitle2" color="tfwBlack40">
                {value}
            </Typography>
        </OwnerDetailItem>
    )
}

export default memo(OwnerDetails)
