import styled, { css } from 'styled-components'
import { desktopBreakpoint, mobileBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

export const BreadCrumbsContainer = styled.section<{ containerPadding?: string }>`
    position: ${({ theme }) => theme.style.position.relative};
    max-width: 87.5rem;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    padding: ${({ containerPadding }) => containerPadding ?? '1.5rem 1rem 0'};
    line-height: 1rem;
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    font-size: ${props => props.theme.typography.font.size.small};
    display: ${({ theme }) => theme.style.display.flex};
    letter-spacing: 0.0125rem;
    white-space: nowrap;
    overflow: auto;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 0.8125rem;
        padding: ${({ containerPadding, theme }) => containerPadding ?? `1rem ${theme.spacing.large} 0`};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: ${({ containerPadding, theme }) => containerPadding ?? `1.25rem ${theme.spacing.large} 0`};
    }
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`

export const BreadCrumbsList = styled.ul`
    display: ${({ theme }) => theme.style.display.none};
    margin: 0;
    padding: 0;
    width: 100%;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${({ theme }) => theme.style.display.flex};
    }
`

export const BreadCrumbListItem = styled.li`
    list-style: none;
`

export const BreadCrumbLink = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 12.5rem;
`
export const BreadCrumbIcon = styled.img`
    margin-left: 0.375rem;
    margin-right: 0.375rem;
`
export const BreadCrumbsListMobile = styled.ul`
    display: ${({ theme }) => theme.style.display.flex};
    margin: 0;
    padding: 0;
    width: 100%;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${({ theme }) => theme.style.display.none};
    }
`
