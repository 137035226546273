import { TranslationFunction } from '../../common/types'

/**
 * Enum representing different types of accessibility
 */
export enum AccessibilityType {
    AT_GROUND_LEVEL = 1,
    STAIRS = 2,
    ELEVATOR = 3,
}

export interface FormattedAccessibilityTransport {
    key: number
    label: string
    id: string
}

/**
 * Class Accessibility
 *
 */
export class Accessibility {
    private __: TranslationFunction

    constructor(translateFunction: TranslationFunction) {
        this.__ = translateFunction
    }

    /**
     * Returns the formatted accessibility object with translated label
     * @param accessibility The accessibility type to format
     * @returns {FormattedAccessibilityTransport} Formatted accessibility object
     */
    public format(accessibility: AccessibilityType): FormattedAccessibilityTransport | null {
        if (!accessibility) {
            return null
        }
        switch (accessibility) {
            case AccessibilityType.AT_GROUND_LEVEL:
                return {
                    key: AccessibilityType.AT_GROUND_LEVEL,
                    label: this.__('groundFloor'),
                    id: 'groundLevel',
                }
            case AccessibilityType.STAIRS:
                return {
                    key: AccessibilityType.STAIRS,
                    label: this.__('stairCase'),
                    id: 'stairs',
                }
            case AccessibilityType.ELEVATOR:
                return {
                    key: AccessibilityType.ELEVATOR,
                    label: this.__('elevator'),
                    id: 'elevator',
                }
            default:
                throw new Error(`Unsupported accessibility type: ${accessibility}`)
        }
    }
}
