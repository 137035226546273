import { FormatDateFunction, RoutingFunction, TranslationFunction } from '../../common/types'
import {
    PropertyDetailsRatingsTransport,
    PropertyDetails,
    RatingDetails,
    CustomerDetails,
} from '../../transports/property-details-page-transport'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Provider, ContactFormatter } from './contact-model'
import { parseEntities } from 'parse-entities'
import { buildPath } from '../../routes/build-route'

export interface FormattedRatingsAddress {
    city: string
}

export interface FormattedRatingsContact {
    title: string | null
    firstName: string | null
    lastName: string | null
    address: FormattedRatingsAddress
}

export interface FormattedRatingAnswer {
    date: string | null
    text: string | null
}

export interface FormattedRating {
    text: string
    title: string | null
    avg: number | null
    features: string | null
    price: number | null
    service: number | null
    ambience: number | null
    contact: FormattedRatingsContact
    touristType: string | null
    travelDate: string | null
    travelDateFrom: string | null
    ratingDate: string | null
    answers: FormattedRatingAnswer[]
    translationNote: string | null
}

export interface FormattedRatingRecommendation {
    count: number
    percentage: number
}

export interface FormattedRatingsBadgeTransport {
    count: number
    avg: string
    features: string
    price: string
    service: string
    ambience: string
    recommendation: FormattedRatingRecommendation
}

export interface FormattedRatingTransport {
    showRatingBadge: boolean
    name: string
    ratingsCount: number
    ratings: FormattedRating[]
    badge: FormattedRatingsBadgeTransport
    addRatingRoute: string
}

export enum TouristTypeEnum {
    TOURIST_FAMILY = 1,
    TOURIST_SINGLE = 2,
    TOURIST_FAMILY_WITH_YOUNG_CHILDREN = 3,
    TOURIST_FAMILY_WITH_TEENAGERS = 4,
    TOURIST_PAIR = 5,
    TOURIST_FRIENDS = 6,
    TOURIST_CLUB = 7,
    TOURIST_TRAVEL_GROUP = 8,
    TOURIST_SENIORS = 9,
}

export class Reviews {
    private __: TranslationFunction
    private formatDate: FormatDateFunction
    private r: RoutingFunction
    private readonly touristTypeTranslation: Record<number, string> = {
        [TouristTypeEnum.TOURIST_FAMILY]: 'touristType.family',
        [TouristTypeEnum.TOURIST_SINGLE]: 'touristType.single',
        [TouristTypeEnum.TOURIST_FAMILY_WITH_YOUNG_CHILDREN]: 'touristType.familyWithChildren',
        [TouristTypeEnum.TOURIST_FAMILY_WITH_TEENAGERS]: 'touristType.familyWithTeenagers',
        [TouristTypeEnum.TOURIST_PAIR]: 'touristType.pair',
        [TouristTypeEnum.TOURIST_FRIENDS]: 'touristType.friends',
        [TouristTypeEnum.TOURIST_CLUB]: 'touristType.club',
        [TouristTypeEnum.TOURIST_TRAVEL_GROUP]: 'touristType.travelGroup',
        [TouristTypeEnum.TOURIST_SENIORS]: 'touristType.seniors',
    }

    constructor(translationFunction: TranslationFunction, formatDate: FormatDateFunction, r: RoutingFunction) {
        this.__ = translationFunction
        this.formatDate = formatDate
        this.r = r
    }

    format(
        ratings: PropertyDetailsRatingsTransport,
        providerId: PropertyDetails['providerId'],
        contact: CustomerDetails,
        listingId: PropertyDetails['tfwObjectId'],
    ): FormattedRatingTransport {
        const formattedRatings: FormattedRating[] = this.formatRatings(ratings, providerId)
        return {
            ratingsCount: ratings.ratings.length,
            ratings: formattedRatings,
            showRatingBadge: ratings.ratings.length > 0,
            name: this.getName(contact),
            badge: this.getBadge(ratings),
            addRatingRoute: buildPath(this.r('pdpRating'), {
                listingId: listingId,
            }),
        }
    }

    private formatRatings(
        ratings: PropertyDetailsRatingsTransport,
        providerId: PropertyDetails['providerId'],
    ): FormattedRating[] {
        const formattedRatings: FormattedRating[] = []
        const ratingsData = get(ratings, 'ratings')
        const contactFormatter = new ContactFormatter(this.__)
        ratingsData.forEach(rating => {
            formattedRatings.push(this.formatRating(rating, providerId, contactFormatter))
        })
        return formattedRatings
    }

    private formatRating(
        rating: RatingDetails,
        providerId: PropertyDetails['providerId'],
        contactFormatter: ContactFormatter,
    ): FormattedRating {
        let translationNote = null
        const formattedAnswers: FormattedRatingAnswer[] = []
        if (providerId === Provider.PROVIDER_BELVILLA) {
            translationNote = this.__('propertyRatings.translationNote')
        }
        !isEmpty(rating.answers) &&
            rating.answers.forEach(answer => {
                const answerDate = answer?.date ? this.formatDate(new Date(answer?.date), 'dd.MM.yyyy') : ''
                formattedAnswers.push({
                    date: answerDate,
                    text: answer?.text,
                })
            })
        let ratingTitle = this.stripTagsAndDecode(rating.title)
        if (isEmpty(ratingTitle)) {
            const ratingTextPieces: string[] = rating?.text?.split(' ')
            ratingTitle = ratingTextPieces?.slice(0, 8).join(' ') + '...'
        }
        const ratingCity = !isEmpty(get(rating, 'contact.address.city')) ? get(rating, 'contact.address.city') : null
        return {
            text: rating.text,
            title: ratingTitle,
            avg: get(rating, 'rating.average'),
            features: get(rating, 'rating.features'),
            price: get(rating, 'rating.price'),
            service: get(rating, 'rating.service'),
            ambience: get(rating, 'rating.ambience'),
            contact: {
                title: contactFormatter.getTitleValue(get(rating, 'contact.title')),
                firstName: get(rating, 'contact.firstName'),
                lastName: get(rating, 'contact.lastName'),
                address: {
                    city: ratingCity,
                },
            },
            touristType: rating.touristType ? this.__(this.touristTypeTranslation[rating.touristType]) : '',
            travelDate: !isEmpty(rating?.travelDateFrom)
                ? this.formatDate(new Date(rating?.travelDateFrom), 'dd.MM.yyyy')
                : null,
            travelDateFrom: !isEmpty(rating?.travelDateFrom)
                ? this.formatDate(new Date(rating?.travelDateFrom), 'dd.MM.yyyy')
                : null,
            ratingDate: !isEmpty(rating?.ratingDate)
                ? this.formatDate(new Date(rating?.ratingDate), 'dd.MM.yyyy')
                : null,
            answers: formattedAnswers,
            translationNote: translationNote,
        }
    }

    private stripTagsAndDecode(htmlString: string) {
        return !htmlString ? '' : parseEntities(htmlString)
    }

    private getName(contact: CustomerDetails): string {
        let name
        const titleLabel = new ContactFormatter(this.__).getTitleValue(contact.title)
        if (titleLabel && contact.lastName) {
            name = this.__('propertyRatings.answerFromPerson', { title: titleLabel, lastName: contact.lastName })
        } else if (contact.company) {
            name = this.__('propertyRatings.answerFromCompany', { company: contact.company })
        } else {
            name = this.__('propertyRatings.answerFromHost')
        }
        return name
    }

    private getBadge(ratings: PropertyDetailsRatingsTransport) {
        return {
            count: ratings.ratings.length,
            avg: Number(get(ratings, 'summary.average')).toFixed(2).toString(),
            features: Number(get(ratings, 'summary.features')).toFixed(2).toString(),
            price: Number(get(ratings, 'summary.price')).toFixed(2).toString(),
            service: Number(get(ratings, 'summary.service')).toFixed(2).toString(),
            ambience: Number(get(ratings, 'summary.ambience')).toFixed(2).toString(),
            recommendation: this.getRecommendation(ratings),
        }
    }

    private getRecommendation(ratings: PropertyDetailsRatingsTransport) {
        return {
            count: ratings.summary.recommendations,
            percentage:
                ratings.ratings.length > 0 ? (100 * ratings.summary.recommendations) / ratings.ratings.length : 0,
        }
    }
}
