import styled from 'styled-components'
import MaleIcon from '../../../assets/icons/male.svg'
import FemaleIcon from '../../../assets/icons/female.svg'

export const ContactContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const ContactInfoWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing.small} 0`};
`

export const OwnerImage = styled.div<{ width?: string; height?: string; borderRadius?: string; marginRight?: string }>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    margin-right: ${({ theme, marginRight }) => (marginRight ? marginRight : theme.spacing.medium)};
    border-radius: ${({ theme, borderRadius }) => (borderRadius ? borderRadius : theme.typography.border.radius.small)};
    height: ${({ theme, height }) => (height ? height : theme.layout.large)};
    width: ${({ theme, width }) => (width ? width : theme.layout.xLarge)};
    aspect-ratio: 1 / 1;
`

export const OwnerInfo = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    flex: 1;
`

export const OwnerDetailsWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    flex: 1;
`

export const OwnerDetailItem = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    flex: 1;
    padding-bottom: ${({ theme }) => theme.spacing.small};
    &:last-child {
        padding-bottom: 0;
    }
`

export const CommunicationWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const CommunicationItem = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const StyledImageContainer = styled.img<{
    width?: string
    height?: string
    borderRadius?: string
    marginRight?: string
}>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    margin-right: ${({ theme, marginRight }) => (marginRight ? marginRight : theme.spacing.medium)};
    border-radius: ${({ theme, borderRadius }) => (borderRadius ? borderRadius : theme.typography.border.radius.small)};
    height: ${({ theme, height }) => (height ? height : theme.layout.xLarge)};
    width: ${({ theme, width }) => (width ? width : theme.layout.xLarge)};
    aspect-ratio: 1 / 1;
`

export const StyledMaleIcon = styled(MaleIcon)<{
    width?: string
    height?: string
    borderRadius?: string
    marginRight?: string
}>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    margin-right: ${({ theme, marginRight }) => (marginRight ? marginRight : theme.spacing.medium)};
    border-radius: ${({ theme, borderRadius }) => (borderRadius ? borderRadius : theme.typography.border.radius.small)};
    height: ${({ theme, height }) => (height ? height : theme.layout.xLarge)};
    width: ${({ theme, width }) => (width ? width : theme.layout.xLarge)};
    aspect-ratio: 1 / 1;
`

export const StyledFemaleIcon = styled(FemaleIcon)<{
    width?: string
    height?: string
    borderRadius?: string
    marginRight?: string
}>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    margin-right: ${({ theme, marginRight }) => (marginRight ? marginRight : theme.spacing.medium)};
    border-radius: ${({ theme, borderRadius }) => (borderRadius ? borderRadius : theme.typography.border.radius.small)};
    height: ${({ theme, height }) => (height ? height : theme.layout.xLarge)};
    width: ${({ theme, width }) => (width ? width : theme.layout.xLarge)};
    aspect-ratio: 1 / 1;
`
