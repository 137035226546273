import { TranslationFunction } from '../common/types'
import { FormattedPropertyData } from '../models/property-details-page/property-details-model'
import { PostalAddress, Accommodation, GeoCoordinates, AggregateRating } from 'schema-dts'
import isEmpty from 'lodash/isEmpty'
import { SuitableStates } from '../models/property-details-page/suitables-model'
import {
    PriceDetailsSchemaTransport,
    PropertyObjectTransport,
    PropertyRooms,
    VacationRegionNodeTransport,
} from '../transports/property-details-page-transport'

export const getVacationRentalSchemaForProperty = ({
    propertyDetails,
    vacationRegionNode,
    objectDetails,
    rooms,
    prices,
    t,
}: {
    propertyDetails: FormattedPropertyData | null
    vacationRegionNode?: VacationRegionNodeTransport
    objectDetails?: PropertyObjectTransport | null
    rooms?: PropertyRooms
    prices?: PriceDetailsSchemaTransport
    t: TranslationFunction
}): { __html: string } | null => {
    if (!propertyDetails || isEmpty(propertyDetails) || !propertyDetails.reviews?.ratingsCount) {
        return null
    }

    const { fullTitle: name, description, gallery, reviews, stats, features, id, general } = propertyDetails
    const { badge, ratingsCount } = reviews
    const { longitude, latitude } = objectDetails?.location ?? {}

    const aggregateRating: AggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: badge?.avg,
        reviewCount: ratingsCount,
    }

    const address: PostalAddress = {
        '@type': 'PostalAddress',
        addressLocality: vacationRegionNode?.title,
    }

    const geo: GeoCoordinates | undefined =
        longitude && latitude ? { '@type': 'GeoCoordinates', latitude, longitude } : undefined

    const roomsCount = rooms?.rooms?.reduce((count, room) => (room.type ? count + room.count : count), 0) || 0

    const containsPlace = {
        '@type': 'Accommodation',
        additionalType: general?.isComboListing ? 'HolidayHome' : 'EntirePlace',
        occupancy: stats?.maxPersons ? { '@type': 'QuantitativeValue', value: stats.maxPersons } : undefined,
        numberOfBedrooms: stats?.roomCount,
        numberOfBathroomsTotal: stats?.bathrooms,
        numberOfRooms: roomsCount,
        amenityFeature: features
            ? Object.entries(features)
                  .slice(0, 5)
                  .map(([key, value]) => ({
                      '@type': 'LocationFeatureSpecification',
                      name: key.replace(/-/g, ' '),
                      value: Number(value.state) === SuitableStates.SUITABLE_STATUS_ALLOWED,
                  }))
            : undefined,
        floorSize: stats?.apartmentSize
            ? {
                  '@type': 'QuantitativeValue',
                  value: stats.apartmentSize,
                  unitCode: 'SQM',
              }
            : undefined,
        geo,
    } as Accommodation

    return {
        __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'VacationRental',
            name,
            address,
            description: description?.text,
            image: gallery?.slice(0, 8).map(image => image.src),
            url: `${t('traumBaseUrl')}${id}`,
            identifier: id,
            aggregateRating,
            geo,
            containsPlace,
            priceRange: prices?.price ?? null,
        }),
    }
}

export const getProductSchemaForProperty = ({
    propertyDetails,
    prices,
}: {
    propertyDetails: FormattedPropertyData | null
    prices?: PriceDetailsSchemaTransport
}): { __html: string } | null => {
    if (!propertyDetails || isEmpty(propertyDetails) || !propertyDetails.reviews?.ratingsCount) {
        return null
    }

    const { fullTitle: name, description, gallery, reviews, id } = propertyDetails
    const { badge, ratingsCount } = reviews

    const aggregateRating: AggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: badge?.avg,
        reviewCount: ratingsCount,
    }

    return {
        __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name,
            description: description?.text,
            image: gallery?.slice(0, 8).map(image => image.src),
            identifier: id,
            aggregateRating,
            priceRange: prices?.price ?? null,
        }),
    }
}
