import React, { FC, useContext } from 'react'
import { Modal, ScreenContext, BottomSheet } from 'react-components'
import { useTranslation } from '../../../../hooks/locale'
import getShareLinks from './get-share-link'
import SharePopoverContent from './content'
import SharePopoverHeader from './header'

export interface SharePopoverProps {
    listingId: string
    onClose: () => void
    isOpen: boolean
}

const SharePopover: FC<SharePopoverProps> = ({ isOpen, onClose, listingId }) => {
    const { isMobile } = useContext(ScreenContext)
    const { t } = useTranslation()
    const shareUrl = `${t('traumBaseUrl')}/${listingId}`
    const shareLinks = getShareLinks(shareUrl)
    if (isMobile) {
        return (
            <BottomSheet
                rootElementSelector="#__next"
                header={<SharePopoverHeader onClose={onClose} />}
                isOpen={isOpen}
                onClose={onClose}
                content={<SharePopoverContent shareLinks={shareLinks} onClose={onClose} />}
            />
        )
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} preventScroll>
            <SharePopoverHeader onClose={onClose} />
            <SharePopoverContent shareLinks={shareLinks} onClose={onClose} />
        </Modal>
    )
}

export default SharePopover
