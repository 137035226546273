import React, { FC, useRef, useState, useContext, useEffect, useCallback, memo } from 'react'
import { Gallery, CarouselHandle } from 'react-components'
import { GalleryWrapper, ThumnailImageWrapper, StyledSlider, StyledCarouselProvider } from './styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import ImageLoadingComponent from '../../app-components/image-loading-component'
import { ThemeContext } from 'styled-components'
import ThumbnailBack from './thumbnail-back'
import ThumbnailNext from './thumbnail-next'
import { GalleryImages } from '../../../models/property-details-page/gallery-model'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import GalleryHeader from './gallery-header'
import GalleryFooter from './gallery-footer'

type GalleryProps = {
    imageBorderRadius?: string
    imageBorder?: string
    showThumbnail?: boolean
    images: GalleryImages[]
    bookingType: FormattedBookingTypeData | null
    propertyName: string
    searchbar: SearchbarFieldsReturn
}

const DEFAULT_BORDER = '1px solid #ccc'
const DEFAULT_BORDER_RADIUS = '0'
const VISIBLE_SLIDE = 6

const ExposeGallery: FC<GalleryProps> = ({
    imageBorder = DEFAULT_BORDER,
    imageBorderRadius = DEFAULT_BORDER_RADIUS,
    showThumbnail = false,
    images,
    bookingType,
    propertyName,
    searchbar,
}) => {
    const sliderRef = useRef<CarouselHandle | null>(null)
    const thumbnailContainerRef = useRef<HTMLDivElement | null>(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [visibleSlides, setVisibleSlides] = useState(VISIBLE_SLIDE)
    const theme = useContext(ThemeContext)
    const smallBorderRadius = theme.typography.border.radius.small

    const calculateVisibleSlides = useCallback(() => {
        if (thumbnailContainerRef.current) {
            const containerWidth = thumbnailContainerRef.current.offsetWidth
            const thumbnailWidth = 110
            const newVisibleSlides = Math.floor(containerWidth / thumbnailWidth)
            setVisibleSlides(newVisibleSlides > 0 ? newVisibleSlides : VISIBLE_SLIDE)
        }
    }, [])

    useEffect(() => {
        calculateVisibleSlides()
        window.addEventListener('resize', calculateVisibleSlides)
        return () => window.removeEventListener('resize', calculateVisibleSlides)
    }, [calculateVisibleSlides])

    useEffect(() => {
        if (showThumbnail) {
            const startVisible = currentSlide
            const endVisible = currentSlide + visibleSlides - 1
            if (currentIndex < startVisible || currentIndex > endVisible) {
                setCurrentSlide(Math.max(0, currentIndex - Math.floor(visibleSlides / 2)))
            }
        }
    }, [currentIndex, currentSlide, visibleSlides, showThumbnail])

    const onSlideChange = useCallback(
        (index: number) => {
            if (showThumbnail) {
                setCurrentIndex(index)
            }
        },
        [showThumbnail],
    )

    const onThumbnailHover = useCallback((index: number) => {
        sliderRef.current?.goToSlide(index)
    }, [])

    const renderThumbnails = useCallback(() => {
        return images.map((image, index) => (
            <Slide key={`thumbnail_${image.caption}_${index}`} index={index}>
                <ThumnailImageWrapper isActive={index === currentIndex} onMouseEnter={() => onThumbnailHover(index)}>
                    <ImageLoadingComponent src={image.thumbnail} borderRadius={smallBorderRadius} />
                </ThumnailImageWrapper>
            </Slide>
        ))
    }, [images, currentIndex, onThumbnailHover, smallBorderRadius])

    return (
        <GalleryWrapper>
            <Gallery
                images={images}
                showArrows={false}
                ref={sliderRef}
                onSlideChange={onSlideChange}
                imageBorder={imageBorder}
                imageBorderRadius={imageBorderRadius}
                header={({ onClose }) => (
                    <GalleryHeader
                        onClose={onClose}
                        bookingType={bookingType}
                        propertyName={propertyName}
                        searchbar={searchbar}
                    />
                )}
                footer={() => <GalleryFooter bookingType={bookingType} searchbar={searchbar} />}
            />
            {showThumbnail && (
                <div ref={thumbnailContainerRef}>
                    <StyledCarouselProvider
                        totalSlides={images.length}
                        visibleSlides={visibleSlides}
                        naturalSlideWidth={6.5}
                        naturalSlideHeight={5.5}
                        dragEnabled={false}
                        step={visibleSlides}
                        currentSlide={currentSlide}
                    >
                        <StyledSlider
                            onMouseEnter={() => sliderRef?.current?.stopAutoplay()}
                            onMouseLeave={() => sliderRef?.current?.resumeAutoplay()}
                        >
                            {renderThumbnails()}
                        </StyledSlider>
                        <ButtonBack>
                            <ThumbnailBack />
                        </ButtonBack>
                        <ButtonNext>
                            <ThumbnailNext numberOfSlides={images.length} visibleSlides={visibleSlides} />
                        </ButtonNext>
                    </StyledCarouselProvider>
                </div>
            )}
        </GalleryWrapper>
    )
}

export default memo(ExposeGallery)
