import React, { FC, useMemo } from 'react'
import { Typography } from 'react-components'
import { StyledTitleBar, ErrorText } from './styles'
import { useTranslation } from '../../../../hooks/locale'
import getSideBarTitleMessageId from '../utils/get-sidebar-title-message-id'
import isNumber from 'lodash/isNumber'
import { SearchbarFieldsReturn } from '../../hooks/use-searchbar'
import { isValidPeriod } from '../../../../utils/date-utils'

type TitleBarProps = {
    pricingErrorCode: number | null
    searchbar: SearchbarFieldsReturn
}

const TitleBar: FC<TitleBarProps> = ({ pricingErrorCode, searchbar }) => {
    const { t } = useTranslation()
    const messageId = getSideBarTitleMessageId({ pricingErrorCode })
    const arrivalDate = searchbar?.arrival
    const departureDate = searchbar?.departure

    const isHidden = useMemo(() => {
        return isValidPeriod(arrivalDate, departureDate) && !isNumber(pricingErrorCode)
    }, [arrivalDate, departureDate, pricingErrorCode])

    return (
        <StyledTitleBar isHidden={isHidden}>
            <Typography variant={'subtitle1'} fontWeight="semiBold">
                {t(messageId)}
            </Typography>
            {isNumber(pricingErrorCode) && (
                <ErrorText>
                    <Typography variant="caption" fontWeight="regular" color="tfwNegativeMedium">
                        {t(`livePriceErrors.${pricingErrorCode}`)}
                    </Typography>
                </ErrorText>
            )}
        </StyledTitleBar>
    )
}

export default TitleBar
