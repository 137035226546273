import { TranslationFunction } from '../../common/types'

export interface FormattedFeatFilter {
    featId: number
    label: string
}

export interface FormattedFeatFilterMap {
    [key: number]: FormattedFeatFilter
}

export enum FeatFilterTypes {
    FILTER_MAPPER_FEAT_TV = 1,
    FILTER_MAPPER_FEAT_SWIMMING_POOL = 2,
    FILTER_MAPPER_FEAT_TERRACE = 3,
    FILTER_MAPPER_FEAT_DISH_WASHER = 4,
    FILTER_MAPPER_FEAT_GARDEN = 5,
    FILTER_MAPPER_FEAT_BALCONY = 6,
    FILTER_MAPPER_FEAT_INTERNET = 7,
    FILTER_MAPPER_FEAT_WLAN = 8,
    FILTER_MAPPER_FEAT_WASHING_MACHINE = 9,
    FILTER_MAPPER_FEAT_SAUNA = 10,
    FILTER_MAPPER_FEAT_AIR_CONDITIONING = 11,
    FILTER_MAPPER_FEAT_GRILL = 12,
    FILTER_MAPPER_FEAT_BABY_CRIB = 13,
    FILTER_MAPPER_FEAT_CHARGING_STATION_FOR_ELECTRIC_CAR = 14,
}

export class FeatFilter {
    private readonly filterFeatTranslationKeys: Record<number, string> = {
        [FeatFilterTypes.FILTER_MAPPER_FEAT_TV]: 'tvFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_SWIMMING_POOL]: 'swimmingPoolFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_TERRACE]: 'terraceFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_DISH_WASHER]: 'dishWaherFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_GARDEN]: 'gardenFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_BALCONY]: 'balconyFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_INTERNET]: 'internetFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_WLAN]: 'wlanFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_WASHING_MACHINE]: 'washingMachineFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_SAUNA]: 'saunaFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_AIR_CONDITIONING]: 'airConditionerFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_GRILL]: 'grillsFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_BABY_CRIB]: 'babyCribFeature',
        [FeatFilterTypes.FILTER_MAPPER_FEAT_CHARGING_STATION_FOR_ELECTRIC_CAR]: 'chargingStationFeature',
    }

    constructor(private __: TranslationFunction) {
        this.__ = __
    }

    public format(featIds: number[]): FormattedFeatFilterMap {
        const formattedMap: FormattedFeatFilterMap = {}

        featIds.forEach(featId => {
            const translationKey = this.filterFeatTranslationKeys[featId]
            const label = this.__(translationKey, { count: 1 })
            formattedMap[featId] = {
                featId,
                label,
            }
        })

        return formattedMap
    }
}
