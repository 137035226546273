import { TranslationFunction, FormatDateFunction } from '../../common/types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { PropertyOffersTransport } from '../../transports/property-details-page-transport'

export enum OfferTypes {
    FILTER_MAPPER_OFFER_EARLY_BOOKING = 1,
    FILTER_MAPPER_OFFER_GROUP = 2,
    FILTER_MAPPER_OFFER_SHORT_HOLIDAY = 3,
    FILTER_MAPPER_OFFER_LAST_MINUTE = 4,
    FILTER_MAPPER_OFFER_SPECIAL = 5,
}

export interface FormattedOfferTransport {
    discount: string | null
    label: string
    offerInfo: string
    periodPreposition: string
    validToDate: string
    validFromDate: string
    period: string
}

export class Offer {
    private readonly __: TranslationFunction
    private readonly formatDate: FormatDateFunction

    constructor(translationFunction: TranslationFunction, formatDate: FormatDateFunction) {
        this.__ = translationFunction
        this.formatDate = formatDate
    }

    /**
     * Formats offer data based on provided parameters
     * @param offers PropertyOffersTransport
     * @returns Formatted offer object or null
     */
    public format(offers: PropertyOffersTransport | null): FormattedOfferTransport | null {
        const from = get(offers, 'dateFrom')
        const to = get(offers, 'dateTo')
        const discount = get(offers, 'discount')
        const types = get(offers, 'types')
        if (isEmpty(offers)) {
            return null
        }
        if (types?.length && to && new Date(to) > new Date()) {
            let offer: FormattedOfferTransport = {
                discount: null,
                label: this.__('propertyOffers.special', { type: 'single' }),
                offerInfo: this.__('propertyOffers.offerInfos.special'),
                periodPreposition: this.__('propertyOffers.periodPreposition'),
                period:
                    from && to
                        ? `${this.formatDate(new Date(from), 'dd.MM.yyyy')} - ${this.formatDate(
                              new Date(to),
                              'dd.MM.yyyy',
                          )}`
                        : '',
                validFromDate: from ? this.formatDate(new Date(from), 'MM-dd-yyyy') : '',
                validToDate: to ? this.formatDate(new Date(to), 'MM-dd-yyyy') : '',
            }

            offer = discount ? this.addDiscount(offer, discount) : offer
            offer = this.addColorLabelAndInfo(offer, types)

            return offer
        }

        return null
    }

    /**
     * Adds discount information to the offer
     */
    private addDiscount(
        offer: FormattedOfferTransport,
        discount: PropertyOffersTransport['discount'],
    ): FormattedOfferTransport {
        if (discount) {
            offer.discount = this.__('propertyOffers.discountPercentage', { percentage: discount })
        }
        return offer
    }

    /**
     * Adds color, label and info to the offer based on offer type
     */
    private addColorLabelAndInfo(
        offer: FormattedOfferTransport,
        types: PropertyOffersTransport['types'],
    ): FormattedOfferTransport {
        let offerTypeId = OfferTypes.FILTER_MAPPER_OFFER_SPECIAL
        for (const ot of types) {
            if (ot !== OfferTypes.FILTER_MAPPER_OFFER_SPECIAL) {
                offerTypeId = ot
                break
            }
        }

        switch (offerTypeId) {
            case OfferTypes.FILTER_MAPPER_OFFER_EARLY_BOOKING:
                offer.label = this.__('propertyOffers.earlyBird', { type: 'single' })
                offer.offerInfo = this.__('propertyOffers.offerInfos.earlyBird')
                break
            case OfferTypes.FILTER_MAPPER_OFFER_GROUP:
                offer.label = this.__('propertyOffers.groupTravel', { type: 'single' })
                offer.offerInfo = this.__('propertyOffers.offerInfos.groupTravel')
                break
            case OfferTypes.FILTER_MAPPER_OFFER_SHORT_HOLIDAY:
                offer.label = this.__('propertyOffers.shortBreak', { type: 'single' })
                offer.offerInfo = this.__('propertyOffers.offerInfos.shortBreak')
                break
            case OfferTypes.FILTER_MAPPER_OFFER_LAST_MINUTE:
                offer.label = this.__('propertyOffers.lastMinute', { type: 'single' })
                offer.offerInfo = this.__('propertyOffers.offerInfos.lastMinute')
                break
            default:
                offer.label = this.__('propertyOffers.special', { type: 'single' })
                break
        }

        return offer
    }
}
