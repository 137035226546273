import { TranslationFunction } from '../../common/types'
import {
    ExposeTransport,
    Communication,
    CustomerDetails,
    PropertyDetails,
} from '../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'

export enum Title {
    TITLE_MR = 1,
    TITLE_MRS = 2,
    TITLE_FAMILY = 3,
    TITLE_COMPANY = 4,
}

export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
}

export enum Provider {
    PROVIDER_BELVILLA = 3,
    PROVIDER_WILDEAST = 4,
    PROVIDER_VLABS = 5,
    PROVIDER_FEDER = 6,
    PROVIDER_FERATEL = 7,
    PROVIDER_ICAL = 8,
    PROVIDER_DKFERIENCRAWLER = 9,
    PROVIDER_AVANTIO = 10,
    PROVIDER_FEWOVERWALTER = 11,
    PROVIDER_DANCENTER = 12,
    PROVIDER_TFW = 13,
    PROVIDER_VVS = 14,
    PROVIDER_SECRA = 16,
    PROVIDER_NEXTPAX = 17,
    PROVIDER_TUI = 18,
    PROVIDER_HOMETOGO = 19,
    PROVIDER_SILKA = 24,
    PROVIDER_MYRENT = 25,
    PROVIDER_BEERENT = 26,
    PROVIDER_KROSSBOOKING = 27,
    PROVIDER_HOMELIKE = 28,
    PROVIDER_ADRIAGATE = 29,
    PROVIDER_ADRIATIC = 30,
    PROVIDER_CAMPAYA = 31,
    PROVIDER_BEDS24 = 32,
    PROVIDER_MAGELLANO = 33,
    PROVIDER_BOOKINGEXPERTS = 34,
}

export enum CommunicationTypes {
    COMMUNICATION_EMAIL = 1,
    COMMUNICATION_PHONE = 2,
    COMMUNICATION_MOBILE = 3,
    COMMUNICATION_FAX = 4,
}

export enum Languages {
    LANGUAGE_GERMAN = 1,
    LANGUAGE_ENGLISH = 2,
    LANGUAGE_FRENCH = 3,
    LANGUAGE_SPANISH = 4,
    LANGUAGE_RUSSIAN = 5,
    LANGUAGE_PORTUGUESE = 6,
    LANGUAGE_CROATIAN = 7,
    LANGUAGE_DANISH = 8,
    LANGUAGE_FINNISH = 9,
    LANGUAGE_GREEK = 10,
    LANGUAGE_ITALIAN = 11,
    LANGUAGE_POLISH = 12,
    LANGUAGE_SERBIAN = 13,
    LANGUAGE_NORWEGIAN = 14,
    LANGUAGE_SWEDISH = 15,
    LANGUAGE_SCANDINAVIA = 17,
    LANGUAGE_DUTCH = 18,
    LANGUAGE_SORBIAN = 19,
    LANGUAGE_TURKISH = 20,
    LANGUAGE_SLOVAK = 21,
    LANGUAGE_CZECH = 22,
    LANGUAGE_SLOVENIAN = 23,
    LANGUAGE_CHINESE = 24,
    LANGUAGE_ARABIC = 25,
    LANGUAGE_JAPANESE = 26,
    LANGUAGE_VIETNAMESE = 27,
    LANGUAGE_BENGALI = 28,
    LANGUAGE_KOREAN = 29,
    LANGUAGE_THAI = 30,
    LANGUAGE_HEBREW = 31,
    LANGUAGE_PERSIAN = 32,
    LANGUAGE_ROMANIAN = 33,
}

export interface FormattedContactDetails {
    name: string
    gender: Gender
    providerInfo: string
    operatingHours: string | null
    company: string
    isFamily: boolean
    contactPerson: string
    hasEmail: boolean
    languages: string | null
    photo: string
    objectReferenceNumbers: Array<{
        label: string
        value: string
    }>
    communication: Array<{
        type: number
        value: string
    }>
    firstPhoneNumber: string | null
    isHMSObject: boolean
}

export class ContactFormatter {
    private translate: TranslationFunction
    private readonly titleTranslationKeys: Record<number, string> = {
        [Title.TITLE_MR]: 'salutations.maleSalutation',
        [Title.TITLE_MRS]: 'salutations.femaleSalutation',
        [Title.TITLE_FAMILY]: 'salutations.familySalutation',
        [Title.TITLE_COMPANY]: 'salutations.companySalutation',
    }

    private readonly languageTranslationKeys: Record<number, string> = {
        [Languages.LANGUAGE_GERMAN]: 'languages.germanLanguage',
        [Languages.LANGUAGE_ENGLISH]: 'languages.englishLanguage',
        [Languages.LANGUAGE_FRENCH]: 'languages.frenchLanguage',
        [Languages.LANGUAGE_SPANISH]: 'languages.spanishLanguage',
        [Languages.LANGUAGE_RUSSIAN]: 'languages.russianLanguage',
        [Languages.LANGUAGE_PORTUGUESE]: 'languages.portugueseLanguage',
        [Languages.LANGUAGE_CROATIAN]: 'languages.croatianLanguage',
        [Languages.LANGUAGE_DANISH]: 'languages.danishLanguage',
        [Languages.LANGUAGE_FINNISH]: 'languages.finnishLanguage',
        [Languages.LANGUAGE_GREEK]: 'languages.greekLanguage',
        [Languages.LANGUAGE_ITALIAN]: 'languages.italianLanguage',
        [Languages.LANGUAGE_POLISH]: 'languages.polishLanguage',
        [Languages.LANGUAGE_SERBIAN]: 'languages.serbianLanguage',
        [Languages.LANGUAGE_NORWEGIAN]: 'languages.norwegianLanguage',
        [Languages.LANGUAGE_SWEDISH]: 'languages.swedishLanguage',
        [Languages.LANGUAGE_SCANDINAVIA]: 'languages.scandinavianLanguage',
        [Languages.LANGUAGE_DUTCH]: 'languages.dutchLanguage',
        [Languages.LANGUAGE_SORBIAN]: 'languages.sorbianLanguage',
        [Languages.LANGUAGE_TURKISH]: 'languages.turkishLanguage',
        [Languages.LANGUAGE_SLOVAK]: 'languages.slovakLanguage',
        [Languages.LANGUAGE_CZECH]: 'languages.czechLanguage',
        [Languages.LANGUAGE_SLOVENIAN]: 'languages.slovenianLanguage',
        [Languages.LANGUAGE_CHINESE]: 'languages.chineseLanguage',
        [Languages.LANGUAGE_ARABIC]: 'languages.arabicLanguage',
        [Languages.LANGUAGE_JAPANESE]: 'languages.japaneseLanguage',
        [Languages.LANGUAGE_VIETNAMESE]: 'languages.vietnameseLanguage',
        [Languages.LANGUAGE_BENGALI]: 'languages.bengaliLanguage',
        [Languages.LANGUAGE_KOREAN]: 'languages.koreanLanguage',
        [Languages.LANGUAGE_THAI]: 'languages.thaiLanguage',
        [Languages.LANGUAGE_HEBREW]: 'languages.hebrewLanguage',
        [Languages.LANGUAGE_PERSIAN]: 'languages.persianLanguage',
        [Languages.LANGUAGE_ROMANIAN]: 'languages.romanianLanguage',
    }

    constructor(translate: TranslationFunction) {
        this.translate = translate
    }

    public format(propertyDetails: ExposeTransport): FormattedContactDetails {
        const contact = propertyDetails.objectContact
        const firstPhoneCommunication = this.getCommunication(
            CommunicationTypes.COMMUNICATION_PHONE,
            contact.communications,
        )

        const canReceiveEmail = !isEmpty(
            this.getCommunication(CommunicationTypes.COMMUNICATION_EMAIL, contact.communications),
        )

        return {
            name: this.getName(contact, propertyDetails.objectGeneral.providerId),
            gender: this.getGender(contact),
            company: contact.company,
            isFamily: contact.title === Title.TITLE_FAMILY,
            contactPerson: `${contact.firstName} ${contact.lastName}`.trim(),
            providerInfo: contact.introduction,
            operatingHours: contact.operatingHours,
            hasEmail: canReceiveEmail,
            languages: this.getLanguages(contact),
            photo: contact.imageUrl,
            communication: this.formatCommunications(contact),
            firstPhoneNumber: firstPhoneCommunication?.value ?? null,
            objectReferenceNumbers: this.getObjectReferenceNumbers(propertyDetails),
            isHMSObject: this.isHMSObject(propertyDetails.objectGeneral.providerId),
        }
    }

    public getTitleValue(title: Title) {
        return title ? this.translate(this.titleTranslationKeys[title]) : ''
    }

    private getName(contact: CustomerDetails, providerId: PropertyDetails['providerId']): string {
        let name = ''
        const isProviderVlabs = providerId === Provider.PROVIDER_VLABS

        if (contact.company) {
            name += `${this.translate('enterprise')} ${contact.company}`
            if (isProviderVlabs) {
                return name
            }
            if (contact.firstName && contact.lastName) {
                name += ' - '
            }
        }

        if (
            (contact.lastName || contact.firstName) &&
            contact.title !== null &&
            contact.title !== Title.TITLE_COMPANY
        ) {
            name += this.translate(this.titleTranslationKeys[contact.title])
        }

        if (contact.firstName) {
            name += ` ${contact.firstName} `
        }

        name += contact.lastName

        return name
    }

    private getLanguages(contact: CustomerDetails): string | null {
        const languages = contact.spokenLanguages.map(spokenLanguage =>
            this.translate(this.languageTranslationKeys[spokenLanguage]),
        )

        if (languages.length === 0) {
            return null
        }

        const last = languages.pop()!
        if (languages.length > 0) {
            return `${languages.join(', ')} ${this.translate('andTranslation')} ${last}`
        }

        return last
    }

    private getGender(contact: CustomerDetails): Gender {
        if (contact.title === Title.TITLE_MRS) {
            return Gender.FEMALE
        }
        return Gender.MALE
    }

    private isHMSObject(providerId: PropertyDetails['providerId']): boolean {
        if (Provider.PROVIDER_VVS === Number(providerId)) {
            return true
        }
        return false
    }

    private getObjectReferenceNumbers(propertyDetails: ExposeTransport): Array<{ value: string; label: string }> {
        const objectId = propertyDetails.objectGeneral.tfwObjectId
        const externalObjectId = propertyDetails.objectGeneral.externalObjectId
        const numbers = []
        if (!externalObjectId.includes(objectId)) {
            numbers.push({
                value: externalObjectId,
                label: this.translate('referenceNumber'),
            })
        }
        numbers.push({
            value: objectId,
            label: this.translate('accomodationNumber'),
        })
        return numbers
    }

    public getCommunication(type: CommunicationTypes, communications: Communication[]): Communication | null {
        for (const communication of communications) {
            if (communication.type === type) {
                return communication
            }
        }

        return null
    }

    private getCommunicationTypeAsString(type: number): string {
        switch (type) {
            case CommunicationTypes.COMMUNICATION_PHONE:
                return 'phone'
            case CommunicationTypes.COMMUNICATION_MOBILE:
                return 'mobile'
            case CommunicationTypes.COMMUNICATION_FAX:
                return 'fax'
            case CommunicationTypes.COMMUNICATION_EMAIL:
                return 'e-mail'
            default:
                return 'unknown'
        }
    }

    private formatCommunications(contact: CustomerDetails): Array<{ type: number; value: string; id: string }> {
        const communications: Array<{ type: number; value: string; id: string }> = []

        for (const comm of contact.communications) {
            communications.push({
                id: this.getCommunicationTypeAsString(comm.type),
                value: comm.value,
                type: comm.type,
            })
        }

        return communications
    }
}
