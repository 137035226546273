import React, { useState, useEffect, useCallback, useContext, useMemo, useRef, memo } from 'react'
import throttle from 'lodash/throttle'
import { SECTION_IDS } from '../constants'
import { NavButton, NavContainer, NavContent, WishlistShareButtonWrapper } from './styles'
import { useTranslation } from '../../../hooks/locale'
import { Typography } from 'react-components'
import { ThemeContext } from 'styled-components'
import WishlistSection from '../../object-cards/wishlist-section'
import ShareButton from '../share-button'

interface Section {
    id: string
    label: string
}

const isElementVisible = (element: HTMLElement | null): boolean => {
    if (!element) {
        return false
    }
    const style = getComputedStyle(element)
    return style.display !== 'none' && style.visibility !== 'hidden'
}

const NAVBAR_HEIGHT = 80

export const scrollToSection = (id: string): void => {
    const element = document.getElementById(id)
    if (element) {
        window.scrollTo({
            top: element.offsetTop - NAVBAR_HEIGHT,
            behavior: 'smooth',
        })
    }
}

interface NavigationBarProps {
    listingId: string
}

const NavButtonMemo: React.FC<{
    id: string
    label: string
    isActive: boolean
    onClick: (id: string) => void
}> = memo(({ id, label, isActive, onClick }) => (
    <NavButton isActive={isActive} onClick={() => onClick(id)}>
        <Typography variant="subtitle2">{label}</Typography>
    </NavButton>
))

NavButtonMemo.displayName = 'NavButtonMemo'

const NavigationBar: React.FC<NavigationBarProps> = ({ listingId }) => {
    const [showNav, setShowNav] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    const [visibleSections, setVisibleSections] = useState<Section[]>([])
    const [activeSection, setActiveSection] = useState<string>()
    const scrollThreshold = 200
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()

    // Use refs to track the latest values without causing re-renders
    const showNavRef = useRef(showNav)
    const visibleSectionsRef = useRef(visibleSections)

    // Update refs when state changes
    useEffect(() => {
        showNavRef.current = showNav
        visibleSectionsRef.current = visibleSections
    }, [showNav, visibleSections])

    const sections = useMemo(
        () => [
            { id: SECTION_IDS.DESCRIPTION_SECTION_ID, label: t('descriptionTitle') },
            { id: SECTION_IDS.DISTANCES_SECTION_ID, label: t('locationTitle') },
            { id: SECTION_IDS.AVAILABILITY_SECTION_ID, label: t('availabilityTitle') },
            { id: SECTION_IDS.CONTACT_SECTION_ID, label: t('contact.contactTitle') },
            { id: SECTION_IDS.RATING_REVIEWS_SECTION_ID, label: t('reviews') },
        ],
        [t],
    )

    const updateVisibleSections = useCallback(() => {
        const visible = sections.filter(section => {
            const element = document.getElementById(section.id)
            return isElementVisible(element)
        })

        if (JSON.stringify(visible) !== JSON.stringify(visibleSectionsRef.current)) {
            setVisibleSections(visible)
        }
    }, [sections])

    const updateActiveSection = useCallback(() => {
        const currentVisible = visibleSectionsRef.current
        const sectionsWithElements = currentVisible.map(section => ({
            ...section,
            element: document.getElementById(section.id),
        }))

        let currentSection = ''
        let minDistance = Number.POSITIVE_INFINITY

        sectionsWithElements.forEach(({ id, element }) => {
            if (element) {
                const rect = element.getBoundingClientRect()
                const distance = Math.abs(rect.top)

                if (distance < minDistance) {
                    minDistance = distance
                    currentSection = id
                }
            }
        })

        if (currentSection !== activeSection) {
            setActiveSection(currentSection)
        }
    }, [activeSection])

    const handleScroll = useMemo(() => {
        const throttledScroll = throttle(() => {
            const scrollPosition = window.scrollY
            const shouldShowNav = scrollPosition > scrollThreshold

            if (shouldShowNav !== showNavRef.current) {
                setIsAnimating(true)
                setShowNav(shouldShowNav)
                setTimeout(() => {
                    setIsAnimating(false)
                }, 300)
            }

            updateActiveSection()
        }, 100)

        return throttledScroll
    }, [updateActiveSection])

    const handleSectionClick = useCallback((id: string) => {
        scrollToSection(id)
    }, [])

    // Set up initial visible sections
    useEffect(() => {
        updateVisibleSections()
    }, [updateVisibleSections])

    // Set up scroll and resize listeners
    useEffect(() => {
        const scrollHandler = handleScroll
        const resizeHandler = updateVisibleSections

        window.addEventListener('scroll', scrollHandler)
        window.addEventListener('resize', resizeHandler)

        return () => {
            scrollHandler.cancel()
            window.removeEventListener('scroll', scrollHandler)
            window.removeEventListener('resize', resizeHandler)
        }
    }, [handleScroll, updateVisibleSections])

    const shareButtons = useMemo(
        () => (
            <WishlistShareButtonWrapper>
                <WishlistSection
                    width={theme.layout.xxSmall}
                    height={theme.layout.xxSmall}
                    listingId={listingId}
                    containerHeight={theme.layout.small}
                    containerWidth={theme.layout.small}
                    containerPadding="0"
                    containerBorderRadius={theme.typography.border.radius.xxLarge}
                    containerMargin="0"
                    containerborderColor={theme.colors.tfwBlack10}
                />
                <ShareButton
                    width={theme.layout.xxSmall}
                    height={theme.layout.xxSmall}
                    listingId={listingId}
                    containerHeight={theme.layout.small}
                    containerWidth={theme.layout.small}
                    containerPadding="0"
                    containerBorderRadius={theme.typography.border.radius.xxLarge}
                    containerMargin="0"
                    containerborderColor={theme.colors.tfwBlack10}
                />
            </WishlistShareButtonWrapper>
        ),
        [theme, listingId],
    )

    return (
        <NavContainer visible={showNav} animating={isAnimating}>
            <NavContent>
                {visibleSections.map(section => (
                    <NavButtonMemo
                        key={section.id}
                        id={section.id}
                        label={section.label}
                        isActive={activeSection === section.id}
                        onClick={handleSectionClick}
                    />
                ))}
                {shareButtons}
            </NavContent>
        </NavContainer>
    )
}

export default memo(NavigationBar)
