import styled, { keyframes } from 'styled-components'

export const CostBar = styled.div`
    display: ${({ theme }) => `${theme.style.display.flex}`};
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0`};
    justify-content: ${({ theme }) => `${theme.style.justifyContent.spaceBetween}`};
    margin-bottom: ${({ theme }) => `${theme.spacing.xSmall}`};
`

export const PriceGuestWrapper = styled.div`
    display: ${({ theme }) => `${theme.style.display.flex}`};
    flex-direction: ${({ theme }) => `${theme.style.flexDirection.column}`};
`

export const AvailableTag = styled.div<{ onRequest: boolean }>`
    display: ${({ theme }) => `${theme.style.display.flex}`};
    flex-direction: ${({ theme }) => `${theme.style.flexDirection.row}`};
    padding: ${({ theme }) => `${theme.spacing.xSmall} ${theme.spacing.xxSmall}`};
    background-color: ${({ onRequest, theme }) =>
        `${onRequest ? theme.colors.tfwAlertMedium : theme.colors.tfwPositiveMedium}`};
    border-radius: ${({ theme }) => `${theme.typography.border.radius.xSmall}`};
    height: 1.25rem;
    justify-content: ${({ theme }) => `${theme.style.justifyContent.center}`};
    align-items: ${({ theme }) => `${theme.style.alignItems.center}`};
`

export const TickIcon = styled.img`
    height: 1rem;
    width: 1rem;
    margin-right: ${({ theme }) => `${theme.spacing.xxSmall}`};
`

// Keyframes for the spinner animation
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

// Styled component for the spinner
interface SpinnerProps {
    size: number
    color: string
}

export const Spinner = styled.div<SpinnerProps>`
    border: ${({ size }) => size / 10}px solid #f3f3f3; /* Light gray border */
    border-top: ${({ size }) => size / 10}px solid ${({ color }) => color}; /* Customizable color */
    border-radius: 50%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    animation: ${spin} 1s linear infinite;
`
