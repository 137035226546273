import styled, { css } from 'styled-components'

interface FooterContainerProps {
    visible: boolean
}

export const FooterContainer = styled.section<FooterContainerProps>(
    ({ theme, visible }) => css`
        background-color: ${theme.colors.tfwWhite64};
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 100;
        box-shadow: 0 -2px 10px #00000026;
        display: ${theme.style.display.flex};
        flex: 1;
        flex-direction: ${theme.style.flexDirection.column};
        gap: ${theme.spacing.xSmall};
        justify-content: ${theme.style.justifyContent.center};
        transition: bottom 0.3s ease-in-out;
        padding: ${theme.spacing.medium};
        bottom: ${visible ? '0' : '-100%'};
    `,
)

export const ButtonsWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.row};
        justify-content: ${theme.style.justifyContent.spaceBetween};
        gap: ${theme.spacing.medium};
        width: 100%;
        background-color: ${theme.colors.tfwWhite64};
    `,
)

export const PriceDetailsWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.row};
        justify-content: ${theme.style.justifyContent.spaceBetween};
        gap: ${theme.spacing.medium};
        align-items: ${theme.style.alignItems.center};
        width: 100%;
        background-color: ${theme.colors.tfwWhite64};
    `,
)

export const AmountWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.column};
        justify-content: ${theme.style.justifyContent.center};
        gap: ${theme.spacing.xxSmall};
        align-items: ${theme.style.alignItems.center};
        background-color: ${theme.colors.tfwWhite64};
    `,
)
