import { useContext } from 'react'
import QueryContext from '../context/query'

export const useQuery = () => {
    const context = useContext(QueryContext)
    if (!context) {
        throw new Error('useQuery must be used within a QueryProvider')
    }
    return context.queryParams
}
