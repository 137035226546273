import { TranslationFunction } from '../../common/types'
import { PropertyRooms, PropertyRoom, PropertyDetailFeatures } from '../../transports/property-details-page-transport'

export interface FormattedRoomsResult {
    label: string
    type: string | null
    description: string[] | null
    items: string[]
}

export const enum RoomAreaMapperEnum {
    ROOM_AREA_BASEMENT = 1,
    ROOM_AREA_GROUND_FLOOR = 2,
    ROOM_AREA_SOUTERRAIN = 3,
    ROOM_AREA_UPPER_GROUND_FLOOR = 4,
    ROOM_AREA_1st_FLOOR = 5,
    ROOM_AREA_2st_FLOOR = 6,
    ROOM_AREA_3st_FLOOR = 7,
    ROOM_AREA_4st_FLOOR = 8,
    ROOM_AREA_5st_FLOOR = 9,
    ROOM_AREA_ATTIC = 10,
    ROOM_AREA_OUTDOOR = 11,
}

export class Rooms {
    private translate: TranslationFunction

    constructor(translate: TranslationFunction) {
        this.translate = translate
    }

    public format(rooms: PropertyRooms, features: PropertyDetailFeatures): FormattedRoomsResult[] {
        const result: FormattedRoomsResult[] = []
        const roomsWithoutEquipment: string[] = []

        for (const room of rooms.rooms) {
            const equipments: Record<string, string> = {}

            for (const equipment of room.equipments) {
                equipments[equipment] = equipment
            }

            for (const bed of room.beds) {
                const txtItems: string[] = []
                if (bed.count && bed.count > 1) {
                    txtItems.push(bed.count.toString())
                }

                txtItems.push(bed.label)

                if (Number(bed.size) > 0) {
                    txtItems.push(`(${bed.size})`)
                }
                equipments[bed.label] = txtItems.join(' ')
            }

            if (Object.keys(equipments).length > 0) {
                result.push(this.getRoomEquipment(room, equipments))
            } else {
                roomsWithoutEquipment.push(this.capitalizeFirstWord(room.label))
            }
        }

        return this.addGeneralFeatures(this.addRoomsWithoutEquipment(result, roomsWithoutEquipment), features)
    }

    private getRoomEquipment(room: PropertyRoom, equipments: Record<string, string>): FormattedRoomsResult {
        const description: string[] = []
        if (room.area) {
            description.push(this.getRoomAreaTranslations(room.area))
        }

        const roomSize = this.formatRoomSize(room)
        if (roomSize) {
            description.push(roomSize)
        }
        const capitalisedLabel = this.capitalizeFirstWord(room.label)

        return {
            label: room.count && room.count > 1 ? `${room.count} ${capitalisedLabel}` : capitalisedLabel,
            type: room.position,
            description: description.length > 0 ? description : null,
            items: this.capitalizeFirstWordInArray(Object.values(equipments)),
        }
    }

    private capitalizeFirstWord(label: string): string {
        const words = label.split(' ')
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
        return words.join(' ')
    }

    private capitalizeFirstWordInArray(strings: string[]): string[] {
        return strings.map(str => {
            const words = str.split(' ')
            // Find the first non-numeric word and capitalize it
            for (let i = 0; i < words.length; i++) {
                if (Number.isNaN(Number(words[i]))) {
                    // Check if the word is not numeric
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
                    break // Stop after modifying the first non-numeric word
                }
            }
            return words.join(' ')
        })
    }

    private formatRoomSize(room: PropertyRoom): string | null {
        if (Number(room.squareMeters) > 0) {
            return this.translate('meterSquareSize', { size: room.squareMeters })
        }
        return null
    }

    private addRoomsWithoutEquipment(
        rooms: FormattedRoomsResult[],
        roomsWithoutEquipment: string[],
    ): FormattedRoomsResult[] {
        if (roomsWithoutEquipment.length > 0) {
            rooms.push({
                label: this.translate('remainingRooms'),
                type: null,
                description: null,
                items: this.capitalizeFirstWordInArray(roomsWithoutEquipment),
            })
        }
        return rooms
    }

    private addGeneralFeatures(
        rooms: FormattedRoomsResult[],
        features: PropertyDetailFeatures,
    ): FormattedRoomsResult[] {
        if (features.length > 0) {
            rooms.push({
                label: this.translate('generalTitle'),
                type: null,
                description: null,
                items: this.capitalizeFirstWordInArray(features),
            })
        }
        return rooms
    }

    private getRoomAreaTranslations = (roomAreaType: RoomAreaMapperEnum) => {
        switch (roomAreaType) {
            case RoomAreaMapperEnum.ROOM_AREA_BASEMENT:
                return this.translate('cellarRoomArea')
            case RoomAreaMapperEnum.ROOM_AREA_GROUND_FLOOR:
                return this.translate('groundFloor')
            case RoomAreaMapperEnum.ROOM_AREA_SOUTERRAIN:
                return this.translate('basementRoomArea')
            case RoomAreaMapperEnum.ROOM_AREA_UPPER_GROUND_FLOOR:
                return this.translate('upperGroundFloorArea')
            case RoomAreaMapperEnum.ROOM_AREA_1st_FLOOR:
                return this.translate('floorArea', { floor: 1 })
            case RoomAreaMapperEnum.ROOM_AREA_2st_FLOOR:
                return this.translate('floorArea', { floor: 2 })
            case RoomAreaMapperEnum.ROOM_AREA_3st_FLOOR:
                return this.translate('floorArea', { floor: 3 })
            case RoomAreaMapperEnum.ROOM_AREA_4st_FLOOR:
                return this.translate('floorArea', { floor: 4 })
            case RoomAreaMapperEnum.ROOM_AREA_5st_FLOOR:
                return this.translate('floorArea', { floor: 5 })
            case RoomAreaMapperEnum.ROOM_AREA_ATTIC:
                return this.translate('topFloorArea')
            case RoomAreaMapperEnum.ROOM_AREA_OUTDOOR:
                return this.translate('outsideArea')
            default:
                throw new Error(`Unsupported room area type: ${roomAreaType}`)
        }
    }
}
