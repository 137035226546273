import { TranslationFunction } from '../../../common/types'
import { PaymentOption } from '../enums/payment-option'

export const getPaymentOptionLabel = (id: PaymentOption, translate: TranslationFunction): string => {
    switch (id) {
        case PaymentOption.CASH_PAYMENT:
            return translate('paymentOption.cashPayment')
        case PaymentOption.TRANSFER:
            return translate('paymentOption.transfer')
        case PaymentOption.DIRECT_DEBIT:
            return translate('paymentOption.directDebit')
        case PaymentOption.PAYPAL:
            return translate('paymentOption.paypal')
        case PaymentOption.CASH_CARD:
            return translate('paymentOption.cashCard')
        case PaymentOption.CREDIT_CARD:
            return translate('paymentOption.creditCard')
        case PaymentOption.TRANSFER_WISE:
            return translate('paymentOption.transferWise')
        default:
            return ''
    }
}
