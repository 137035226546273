import styled from 'styled-components'

export const NearByWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const NearByTitle = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    padding: ${({ theme }) => `${theme.spacing.xSmall} 0`};
`
