import { TranslationFunction } from '../../common/types'
import { PropertyRentNotes } from '../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'
import { getFinalPaymentTimeLabel } from './utils/get-final-payment-time-label'
import { getPrePaymentTimeLabel } from './utils/get-pre-payment-time-label'
import { getTotalPaymentTimeLabel } from './utils/get-total-payment-time-label'
import { PaymentDeposit } from './enums/payment-deposit'
import { RentNotesArrivalTime } from './enums/arrival-time'
import { getArrivalTimeLabel } from './utils/get-arrival-time-label'
import { getPaymentOptionLabel } from './utils/get-payment-option-label'
import { PriceFormatter } from '../../hooks/price'

export interface FormattedTravelNote {
    title: string
    content: string | string[]
}

export enum PaymentMethods {
    PAYMENT_PREPAYMENT_FLATRATE = 1,
    PAYMENT_PREPAYMENT_PERCENTAGE = 2,
    PAYMENT_TOTAL_PAYMENT = 3,
}
export class TravelNotes {
    private readonly __: TranslationFunction
    private readonly priceFormatter: PriceFormatter

    constructor(translationFunction: TranslationFunction, priceFormatter: PriceFormatter) {
        this.__ = translationFunction
        this.priceFormatter = priceFormatter
    }

    /**
     * Formats notes data based on provided parameters
     * @param shouldIncludeCancellationConditionNotes Array of offer types
     * @param rentNotes all rent notes associated with property
     * @returns Formatted travel notes array
     */
    public format(
        rentNotes: PropertyRentNotes,
        shouldIncludeCancellationConditionNotes: boolean,
    ): FormattedTravelNote[] {
        const notes: FormattedTravelNote[] = []
        if (shouldIncludeCancellationConditionNotes) {
            if (rentNotes.cancellationConditions) {
                notes.push({
                    title: this.__('travelNotes.cancellationPolicy', { type: 'multiple' }),
                    content: rentNotes.cancellationConditions,
                })
            } else {
                notes.push({
                    title: this.__('travelNotes.cancellationPolicy', { type: 'multiple' }),
                    content: this.__('travelNotes.noCancellationCondition'),
                })
            }
        }
        const rentalTermsAndConditions = this.formatRentalTermsAndConditions(rentNotes)
        if (!isEmpty(rentalTermsAndConditions)) {
            notes.push({
                title: this.__('travelNotes.termsOfRental', { type: 'multiple' }),
                content: rentalTermsAndConditions ?? '',
            })
        }
        const paymentOptions = this.formatPaymentOption(rentNotes.payment)
        if (!isEmpty(paymentOptions)) {
            notes.push({
                title: this.__('travelNotes.paymentOptions', { type: 'multiple' }),
                content: paymentOptions ?? '',
            })
        }
        if (!isEmpty(rentNotes.notes)) {
            notes.push({
                title: this.__('travelNotes.commentNotes', { type: 'multiple' }),
                content: rentNotes.notes ?? '',
            })
        }
        return notes
    }

    private formatRentalTermsAndConditions(rentNotes: PropertyRentNotes): string[] | null {
        let rentalTermsAndConditions: string[] = []
        const payment = this.formatPayment(rentNotes.payment)
        if (!isEmpty(payment)) {
            rentalTermsAndConditions = [...rentalTermsAndConditions, ...(payment || [])]
        }
        const deposit = this.formatDeposit(rentNotes.deposit)
        if (!isEmpty(deposit)) {
            rentalTermsAndConditions.push(deposit || '')
        }
        const arrival = this.formatArrival(rentNotes.arrival)
        if (!isEmpty(arrival)) {
            rentalTermsAndConditions.push(arrival || '')
        }
        const departure = this.formatDeparture(rentNotes.departure)
        if (!isEmpty(departure)) {
            rentalTermsAndConditions.push(departure || '')
        }
        if (isEmpty(rentalTermsAndConditions)) {
            return null
        }
        return rentalTermsAndConditions
    }

    private formatPayment(payment: PropertyRentNotes['payment']): string[] | null {
        if (isEmpty(payment)) {
            return null
        }
        switch (Number(payment?.method)) {
            case PaymentMethods.PAYMENT_PREPAYMENT_FLATRATE:
                return this.formatPrepaymentFlatrate(payment)
            case PaymentMethods.PAYMENT_PREPAYMENT_PERCENTAGE:
                return this.formatPrepaymentPercentage(payment)
            case PaymentMethods.PAYMENT_TOTAL_PAYMENT:
                return this.formatTotalPayment(payment)
            default:
                return null
        }
    }

    private formatPrepaymentFlatrate(payment: PropertyRentNotes['payment']): string[] | null {
        if (isEmpty(payment?.prepaymentFlatrate)) {
            return null
        }
        const prePaymentDays = payment?.prepaymentFlatrate?.prePaymentDays
        const finalPaymentDays = payment?.prepaymentFlatrate?.finalPaymentDays
        const prePaymentAmount = this.priceFormatter(payment?.prepaymentFlatrate?.value)

        return [
            this.__('travelNotes.downPayment', {
                payment: prePaymentAmount || '',
                days: prePaymentDays ?? '',
                time: getPrePaymentTimeLabel(Number(prePaymentDays) ?? 10, this.__),
            }),
            this.__('travelNotes.finalPayment', {
                days: finalPaymentDays ?? '',
                time: getFinalPaymentTimeLabel(Number(prePaymentDays) ?? 10, this.__),
            }),
        ]
    }

    private formatPrepaymentPercentage(payment: PropertyRentNotes['payment']): string[] | null {
        if (isEmpty(payment?.prepaymentPercentage)) {
            return null
        }
        const prePaymentDays = payment?.prepaymentPercentage?.prePaymentDays
        const finalPaymentDays = payment?.prepaymentPercentage?.finalPaymentDays
        const prepaymentPercentage = payment?.prepaymentPercentage?.value
        const finalPayment = payment?.prepaymentPercentage?.finalPayment || 10

        return [
            this.__('travelNotes.downPaymentPercent', {
                percent: prepaymentPercentage ?? '',
                days: prePaymentDays ?? '',
                time: getPrePaymentTimeLabel(prePaymentDays ?? 10, this.__),
            }),
            this.__('travelNotes.finalPayment', {
                days: finalPaymentDays ?? '',
                time: getFinalPaymentTimeLabel(finalPaymentDays !== null ? 10 : finalPayment, this.__),
            }),
        ]
    }

    private formatTotalPayment(payment: PropertyRentNotes['payment']): string[] | null {
        if (isEmpty(payment?.totalPayment)) {
            return null
        }
        return [
            this.__('travelNotes.totalPayment', {
                time: getTotalPaymentTimeLabel(payment?.totalPayment ?? 10, this.__),
            }),
        ]
    }

    private formatDeposit(deposit: PropertyRentNotes['deposit']): string | null {
        if (deposit) {
            switch (deposit.type) {
                case PaymentDeposit.NO:
                    return this.__('travelNotes.depositTypes.no')
                case PaymentDeposit.PERCENTAGE_OF_THE_RENT:
                    return this.__('travelNotes.depositTypes.rentPercent', { deposit: deposit.value })
                case PaymentDeposit.FIXED_PRICE:
                    return this.__('travelNotes.depositTypes.fixedPrice', {
                        deposit: this.priceFormatter(deposit.value) || '',
                    })
                default:
                    return null
            }
        }

        return null
    }

    private formatArrival(arrival: PropertyRentNotes['arrival']): string | null {
        if (arrival && arrival.earliestTime) {
            const arrivalTimeId = arrival.earliestTime
            if (arrivalTimeId === RentNotesArrivalTime.BY_ARRANGEMENT) {
                return this.__('travelNotes.arrivalTime', { time: getArrivalTimeLabel(arrivalTimeId, this.__) })
            }
            return this.__('travelNotes.arrivalTimeEarliest', { time: getArrivalTimeLabel(arrivalTimeId, this.__) })
        }

        return null
    }

    private formatDeparture(departure: PropertyRentNotes['departure']): string | null {
        if (departure && departure.latestTime) {
            const departureLatestTime = departure.latestTime
            if (departureLatestTime === RentNotesArrivalTime.BY_ARRANGEMENT) {
                return this.__('travelNotes.departureTime', { time: getArrivalTimeLabel(departureLatestTime, this.__) })
            }
            return this.__('travelNotes.departureLatestBy', { time: getArrivalTimeLabel(departureLatestTime, this.__) })
        }

        return null
    }

    private formatPaymentOption(payment: PropertyRentNotes['payment']): string[] | null {
        const paymentOptions: string[] = []
        if (payment?.options) {
            payment?.options.forEach(optionId => {
                const paymentOptionLabel = getPaymentOptionLabel(optionId, this.__)
                paymentOptions.push(paymentOptionLabel)
            })
        }

        if (isEmpty(paymentOptions)) {
            return null
        }

        return paymentOptions
    }
}
