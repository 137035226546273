import { useMemo } from 'react'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { useRequestUserConfigContext } from '../../../hooks/request'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { encodeToBase64 } from '../../../utils/encoding-utils'
import { useDateFormatter } from '../../../hooks/date'
import { FormatDateFunction } from '../../../common/types'

type QueryParamMapping = Record<string, string>
type QueryValues = Record<string, string | number | Date | undefined | number[] | null>

const defaultQueryParams: QueryParamMapping = {
    date_from: 'arrival',
    date_to: 'departure',
    persons: 'adults',
    children: 'childrenAges',
    arrival: 'arrival',
    departure: 'departure',
    adults: 'adults',
}

const generateQueryString = (
    queryParams: QueryParamMapping,
    values: QueryValues,
    format: FormatDateFunction,
): string => {
    return Object.entries(queryParams)
        .map(([key, valueKey]) => {
            let actualValue = get(values, valueKey)
            if (actualValue instanceof Date) {
                actualValue = format(actualValue, 'yyyy-MM-dd')
            }
            if (Array.isArray(actualValue)) {
                actualValue = JSON.stringify(actualValue)
            }
            return actualValue ? `${key}=${encodeURIComponent(actualValue)}` : ''
        })
        .filter(Boolean)
        .join('&')
}

const useDynamicQueryParams = (
    bookingType: FormattedBookingTypeData | null | undefined,
    values: QueryValues,
    qp: QueryParamMapping = defaultQueryParams,
): string => {
    const user = useRequestUserConfigContext()
    const { format } = useDateFormatter()
    const url = useMemo(() => {
        let baseUrl = get(user, 'domain')
        if (isEmpty(bookingType)) {
            return user.domain
        }
        let requestContextUrl = get(bookingType, 'requestContextUrl')
        const requestContext = get(bookingType, 'requestContext')
        const type = get(bookingType, 'type')
        if (type === 'redirect') {
            baseUrl = ''
        }
        const route = get(bookingType, 'route', '')
        let queryString = generateQueryString(qp, values, format)
        if (requestContextUrl) {
            requestContextUrl = encodeToBase64(
                queryString ? `${baseUrl}/${requestContextUrl}?${queryString}` : `${baseUrl}/${requestContextUrl}`,
            )
        }
        if (requestContextUrl) {
            requestContextUrl = encodeToBase64(`${baseUrl}${requestContextUrl}`)
            queryString += `&requestContextUrl=${encodeURIComponent(requestContextUrl)}`
        }
        if (requestContext) {
            queryString += `&requestContext=${encodeURIComponent(requestContext)}`
        }
        if (type === 'redirect') {
            return queryString ? `${route}?${queryString}` : `${route}`
        }
        return queryString ? `${route}?${queryString}` : `${route}`
    }, [bookingType, qp, values, format, user])

    return url
}

export default useDynamicQueryParams
