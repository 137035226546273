import React, { FC } from 'react'
import { FormattedBookingTypeData } from '../../../models/property-details-page/booking-type-model'
import { GalleryButton, GalleryButtonWrapper, GalleryHeaderWrapper, StyledCloseIcon } from './styles'
import { Typography } from 'react-components'
import EnquiryButton from '../enquiry-button'
import BookNowButton from '../book-now-button'
import BindingInquiryButton from '../binding-inquiry-button'
import { SearchbarFieldsReturn } from '../hooks/use-searchbar'
import isInquiryButtonVisible from '../side-bar/utils/is-inquiry-button-visible'
import isDirectBookingButtonVisible from '../side-bar/utils/is-direct-booking-button-visible'
import is24HourBookingButtonVisible from '../side-bar/utils/is-24-hour-booking-button-visible'

interface GalleryHeaderProps {
    bookingType: FormattedBookingTypeData | null
    propertyName: string
    searchbar: SearchbarFieldsReturn
    onClose: () => void
}

const GalleryHeader: FC<GalleryHeaderProps> = ({ bookingType, propertyName, searchbar, onClose }) => {
    return (
        <GalleryHeaderWrapper>
            <Typography variant="subtitle1" fontWeight="semiBold">
                {propertyName}
            </Typography>
            <GalleryButtonWrapper>
                {isInquiryButtonVisible({ bookingType }) && (
                    <GalleryButton>
                        <EnquiryButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                    </GalleryButton>
                )}
                {isDirectBookingButtonVisible({ bookingType }) && (
                    <GalleryButton>
                        <BookNowButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                    </GalleryButton>
                )}
                {is24HourBookingButtonVisible({ bookingType }) && (
                    <GalleryButton>
                        <BindingInquiryButton bookingType={bookingType} searchbar={searchbar} position="gallery" />
                    </GalleryButton>
                )}
            </GalleryButtonWrapper>
            <GalleryButton onClick={onClose}>
                <StyledCloseIcon />
            </GalleryButton>
        </GalleryHeaderWrapper>
    )
}

export default GalleryHeader
