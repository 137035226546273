import React, { FC, memo } from 'react'
import { ArrowContainerNext, ArrowRightIcon } from './styles'

interface Props {
    numberOfSlides?: number
}

const AvailabilityCalendarNext: FC<Props> = () => {
    return (
        <ArrowContainerNext>
            <ArrowRightIcon />
        </ArrowContainerNext>
    )
}

export default memo(AvailabilityCalendarNext)
