import { AnyStyledComponent } from 'styled-components'
import React, { FC, useState } from 'react'
import { Container, ShareIconWrapper } from './styles'
import ShareIcon from '../../../assets/icons/object-icons/share-icon.svg'
import SharePopover from './share-popover'

interface Props {
    containerBackgroundColor?: string
    containerBorderRadius?: string
    containerWidth?: string
    containerHeight?: string
    containerMargin?: string
    containerPadding?: string
    width?: string
    height?: string
    listingId: string
    containerborderColor?: string
}

const ShareButton: FC<Props> = ({
    containerBackgroundColor,
    containerBorderRadius,
    containerHeight,
    containerWidth,
    containerMargin,
    containerPadding,
    width,
    height,
    listingId,
    containerborderColor,
}) => {
    const [isSharePopoverOpen, setIsSharePopoverOpen] = useState(false)

    const handleSharePopoverClose = () => {
        setIsSharePopoverOpen(false)
    }

    const handleSharePopoverOpen = () => {
        setIsSharePopoverOpen(true)
    }

    return (
        <React.Fragment>
            <Container
                onClick={handleSharePopoverOpen}
                containerBackgroundColor={containerBackgroundColor}
                containerBorderRadius={containerBorderRadius}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                containerMargin={containerMargin}
                containerPadding={containerPadding}
                containerborderColor={containerborderColor}
            >
                <ShareIconWrapper as={ShareIcon as AnyStyledComponent} alt="share-icon" width={width} height={height} />
            </Container>
            <SharePopover isOpen={isSharePopoverOpen} onClose={handleSharePopoverClose} listingId={listingId} />
        </React.Fragment>
    )
}

export default ShareButton
