import React, { FC, memo } from 'react'
import { ListItem, Bullet, StyledTitle } from './styles'
import { Typography } from 'react-components'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const CancellationItem: FC<{ text: string }> = ({ text }) => (
    <ListItem>
        <Bullet>
            <Typography variant="subtitle2" color="tfwBlack40" fontWeight="semiBold">
                •
            </Typography>
        </Bullet>
        <StyledTitle>
            <Typography variant="subtitle2" color="tfwBlack40">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
            </Typography>
        </StyledTitle>
    </ListItem>
)

export default memo(CancellationItem)
