import { TranslationFunction, RoutingFunction } from '../../common/types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import {
    ExposeTransport as PropertyDetailsTransport,
    AddressTransport,
} from '../../transports/property-details-page-transport'
import buildRouteObjectId from './utils/build-route-object-id'
import { buildPath } from '../../routes/build-route'
import { parseISO, intervalToDuration } from 'date-fns'

interface FooterItemTransport<T> {
    title?: string
    data: T
    type: 'link' | 'normal'
}

interface ImprintData {
    registration_number?: string
    commercial_register?: string
    vat_id?: string
    additional_infos?: string
    contact?: {
        name: string
        communications?: string[]
        address?: AddressTransport
    }
}

interface ImprintResult {
    link: string
    data: ImprintData
}

export interface FormattedFooterTransport {
    website: FooterItemTransport<string | null>
    apartmentTitle: string
    accomodationNumber: FooterItemTransport<string>
    onlineSince: FooterItemTransport<string | null>
    cinNumber: FooterItemTransport<string | null>
    listingUrl: FooterItemTransport<string>
    housingProtectionNumber: FooterItemTransport<string | null>
    providerId: string | null
    externalObjectId: string | null
    seoRating: FooterItemTransport<string | null>
    imprint: FooterItemTransport<ImprintResult | null> | null
}

export class Footer {
    private readonly __: TranslationFunction
    private r: RoutingFunction

    constructor(translationFunction: TranslationFunction, r: RoutingFunction) {
        this.__ = translationFunction
        this.r = r
    }

    /**
     * Formats footer based on provided parameters
     * @param propertyDetails Full property details data
     * @param fullTitle Full title of the property
     * @returns Formatted footer object
     */
    public format(propertyDetails: PropertyDetailsTransport, fullTitle: string): FormattedFooterTransport {
        const website = this.getWebsite(propertyDetails)
        const accomodationNumber = this.getAccomadationNumber(propertyDetails)
        const onlineSince = this.getOnlineSince(propertyDetails)
        const cinNumber = this.getCinNumber(propertyDetails)
        const listingUrl = this.getListingUrl(propertyDetails)
        const housingProtectionNumber = this.getHousingProtectionNumber(propertyDetails)
        const seoRating = this.getSeoRating(propertyDetails, fullTitle)
        const imprint = this.getImprintData(propertyDetails, fullTitle)
        return {
            website,
            apartmentTitle: fullTitle,
            accomodationNumber,
            onlineSince,
            cinNumber,
            listingUrl,
            housingProtectionNumber,
            providerId: get(propertyDetails, 'objectGeneral.providerId', null),
            externalObjectId: get(propertyDetails, 'objectGeneral.externalObjectId', null),
            seoRating,
            imprint,
        }
    }

    private getWebsite(propertyDetails: PropertyDetailsTransport): FormattedFooterTransport['website'] {
        const contact = get(propertyDetails, 'objectContact')
        const website = get(contact, 'website')
        const providerId = get(propertyDetails, 'objectGeneral.providerId')
        const externalObjectId = get(propertyDetails, 'objectGeneral.externalObjectId')
        const tfwObjectId = get(propertyDetails, 'objectGeneral.tfwObjectId')
        const title = this.__('propertyDetailsFooter.privateHomePage')
        if (!isEmpty(website) && website && new URL(website).protocol === 'https:') {
            const data = buildPath(this.r('propertyDetailsContactWebsite'), {
                routeObjectId: buildRouteObjectId(providerId, externalObjectId, tfwObjectId),
            })

            return { title, data, type: 'link' }
        }
        return {
            title: title,
            data: null,
            type: 'link',
        }
    }

    private getAccomadationNumber(
        propertyDetails: PropertyDetailsTransport,
    ): FormattedFooterTransport['accomodationNumber'] {
        const title = this.__('accomodationNumber')
        const providerId = get(propertyDetails, 'objectGeneral.providerId')
        const externalObjectId = get(propertyDetails, 'objectGeneral.externalObjectId')
        const tfwObjectId = get(propertyDetails, 'objectGeneral.tfwObjectId')
        const data = buildRouteObjectId(providerId, externalObjectId, tfwObjectId)
        return { title, data, type: 'normal' }
    }

    private getOnlineSince(propertyDetails: PropertyDetailsTransport): FormattedFooterTransport['onlineSince'] {
        const title = this.__('propertyDetailsFooter.onlineSince')
        const data = this.generateOnlinceSInce(propertyDetails.objectGeneral.releaseDate)
        return { title, data, type: 'normal' }
    }

    private generateOnlinceSInce(releaseDate: string): string | null {
        const now = new Date()
        const release = parseISO(releaseDate)
        const { years, months, days } = intervalToDuration({ start: release, end: now })
        if (!isUndefined(years) && !isUndefined(months) && !isUndefined(days)) {
            return this.__('propertyDetailsFooter.onlineDuration', { years, months, days })
        }
        return null
    }

    private getCinNumber(propertyDetails: PropertyDetailsTransport): FormattedFooterTransport['cinNumber'] {
        const title = this.__('propertyDetailsFooter.cinNumber')
        const data = get(propertyDetails, 'objectGeneral.cinNumber')
        return { title, data, type: 'normal' }
    }

    private getListingUrl(propertyDetails: PropertyDetailsTransport): FormattedFooterTransport['listingUrl'] {
        const providerId = get(propertyDetails, 'objectGeneral.providerId')
        const externalObjectId = get(propertyDetails, 'objectGeneral.externalObjectId')
        const tfwObjectId = get(propertyDetails, 'objectGeneral.tfwObjectId')
        const title = this.__('propertyDetailsFooter.urlTitle')
        const data = buildPath(this.r('propertyDetails'), {
            listingId: buildRouteObjectId(providerId, externalObjectId, tfwObjectId),
        })

        return { title, data, type: 'link' }
    }

    private getHousingProtectionNumber(
        propertyDetails: PropertyDetailsTransport,
    ): FormattedFooterTransport['housingProtectionNumber'] {
        const title = this.__('propertyDetailsFooter.housingProtectionNumber')
        const data = get(propertyDetails, 'objectGeneral.housingProtectionNumber')
        return { title, data, type: 'normal' }
    }

    private getSeoRating(
        propertyDetails: PropertyDetailsTransport,
        fullTitle: string,
    ): FormattedFooterTransport['seoRating'] {
        const ratings = get(propertyDetails, 'objectRatings')
        const ratingsCount = ratings?.ratings?.length || 0
        let data = null
        if (ratingsCount > 0) {
            const ratingAvg = Number(get(ratings, 'summary.average')).toFixed(2).toString()
            data = this.__('propertyDetailsFooter.seoRatingData', { title: fullTitle, ratingAvg, ratingsCount })
        }
        return { data, type: 'normal' }
    }

    private getImprintData(
        propertyDetails: PropertyDetailsTransport,
        fullTitle: string,
    ): FormattedFooterTransport['imprint'] | null {
        const imprint = get(propertyDetails, 'objectImprint')
        if (!imprint) {
            return null
        }
        const imprintData: ImprintData = {}

        if (imprint.registrationNumber) {
            imprintData.registration_number = imprint.registrationNumber
        }

        if (imprint.commercialRegister) {
            imprintData.commercial_register = imprint.commercialRegister
        }

        if (imprint.vatId) {
            imprintData.vat_id = imprint.vatId
        }

        if (imprint.additionalInfos) {
            imprintData.additional_infos = imprint.additionalInfos
        }

        if (imprint.contact) {
            const address = imprint.contact.address
                ? {
                      street: imprint.contact.address.street,
                      house_number: imprint.contact.address.houseNumber,
                      zip_code: imprint.contact.address.zipCode,
                      city: imprint.contact.address.city,
                      country: imprint.contact.address.country,
                  }
                : undefined

            imprintData.contact = {
                name: imprint.contact.lastName,
                communications: imprint.contact.communications ?? [],
                address,
            }
        }

        if (Object.keys(imprintData).length === 0) {
            return null
        }
        const providerId = get(propertyDetails, 'objectGeneral.providerId')
        const externalObjectId = get(propertyDetails, 'objectGeneral.externalObjectId')
        const tfwObjectId = get(propertyDetails, 'objectGeneral.tfwObjectId')
        const link = buildPath(this.r('customerInformation'), {
            routeObjectId: buildRouteObjectId(providerId, externalObjectId, tfwObjectId),
        })

        const imprintFinalData = {
            link,
            data: imprintData,
        }

        return {
            title: fullTitle,
            data: imprintFinalData,
            type: 'normal',
        }
    }
}
