import styled from 'styled-components'

export const OfferWrapper = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    background-color: ${props => props.theme.colors.tfwPositiveBackground};
    padding: ${props => props.theme.spacing.xxSmall};
    border-radius: ${props => props.theme.typography.border.radius.small};
    margin-bottom: ${props => props.theme.spacing.small};
    align-items: ${({ theme }) => theme.style.alignItems.start};
`

export const IconContainer = styled.div`
    height: ${({ theme }) => theme.layout.xxSmall};
    width: ${({ theme }) => theme.layout.xxSmall};
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
    margin-top: ${({ theme }) => theme.spacing.xxSmall};
`

export const Icon = styled.img``
