import styled, { css } from 'styled-components'
import { ChevronLeftIcon, ChevronRightIcon } from '../../common/icons'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import { tabletBreakpoint } from 'react-components'

export const AvailabilityCalendarWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.grid};
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: ${tabletBreakpoint.min}px) {
        grid-template-columns: 1fr;
    }
`

export const AvailabilityBoxWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
`

export const CalendarNavigationBar = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin-bottom: ${({ theme }) => theme.spacing.small};
`

export const ArrowStyles = ({}) => css`
    cursor: pointer;
    display: ${props => props.theme.style.display.flex};
    align-items: ${props => props.theme.style.alignItems.center};
    justify-content: ${props => props.theme.style.justifyContent.center};
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid ${props => props.theme.colors.tfwBlack10};
    border-radius: ${props => props.theme.typography.border.radius.xSmall};
    &:hover {
        transform: scale(1.1);
    }
`

export const ArrowContainerBack = styled(ButtonBack)`
    ${ArrowStyles};
`

export const ArrowContainerNext = styled(ButtonNext)`
    ${ArrowStyles};
`

export const ArrowLeftIcon = styled(ChevronLeftIcon)`
    height: 1.625rem;
    width: 1.625rem;
`

export const ArrowRightIcon = styled(ChevronRightIcon)`
    height: 1.625rem;
    width: 1.625rem;
`

export const DescriptionBar = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex: 1;
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    padding: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.xSmall}`};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    @media only screen and (max-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.column};
        gap: ${({ theme }) => `${theme.spacing.medium}`};
        align-items: ${({ theme }) => theme.style.alignItems.flexStart};
    }
`

export const LegendWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
`

export const Legend = styled.div<{ available: boolean }>`
    display: ${({ theme }) => theme.style.display.flex};
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${({ available, theme }) =>
        available ? theme.colors.tfwPositiveMedium : theme.colors.tfwNegativeMedium};
    border-radius: ${({ theme }) => theme.typography.border.radius.xSmall};
    margin: ${({ theme }) => `0 ${theme.spacing.small}`};
`

export const LegendBar = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    flex: 1;
`
