import { useMemo, useState } from 'react'

interface PaginationResult<T> {
    // Pagination data
    currentPage: number
    pageSize: number
    totalPages: number
    paginatedData: T[]
    cumulativeData: T[]
    totalItems: number

    // Navigation methods
    goToPage: (page: number) => void
    nextPage: () => void
    previousPage: () => void
    goToFirstPage: () => void
    goToLastPage: () => void

    // Helper properties
    hasNextPage: boolean
    hasPreviousPage: boolean
}

function usePagination<T>(data: T[] = [], pageSize = 10): PaginationResult<T> {
    const [currentPage, setCurrentPage] = useState(1)

    const totalPages = Math.ceil(data.length / pageSize)

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize
        const endIndex = startIndex + pageSize
        return data.slice(startIndex, endIndex)
    }, [data, currentPage, pageSize])

    const cumulativeData = useMemo(() => {
        const endIndex = currentPage * pageSize
        return data.slice(0, endIndex)
    }, [data, currentPage, pageSize])

    const goToPage = (page: number): void => {
        const pageNumber = Math.max(1, Math.min(page, totalPages))
        setCurrentPage(pageNumber)
    }

    const nextPage = (): void => {
        goToPage(currentPage + 1)
    }

    const previousPage = (): void => {
        goToPage(currentPage - 1)
    }

    const goToFirstPage = (): void => {
        goToPage(1)
    }

    const goToLastPage = (): void => {
        goToPage(totalPages)
    }

    return {
        // Pagination data
        currentPage,
        pageSize,
        totalPages,
        paginatedData,
        cumulativeData,
        totalItems: data.length,

        // Navigation methods
        goToPage,
        nextPage,
        previousPage,
        goToFirstPage,
        goToLastPage,

        // Helper properties
        hasNextPage: currentPage < totalPages,
        hasPreviousPage: currentPage > 1,
    }
}

export default usePagination
