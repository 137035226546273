import React, { FC, useContext } from 'react'
import { WishlistShareBarContainer } from './styles'
import WishlistSection from '../../object-cards/wishlist-section'
import { ThemeContext } from 'styled-components'
import ShareButton from '../share-button'

type WishlistShareBarProps = {
    listingId: string
}

const WishlistShareBar: FC<WishlistShareBarProps> = ({ listingId }) => {
    const theme = useContext(ThemeContext)
    return (
        <WishlistShareBarContainer>
            <WishlistSection
                width={theme.layout.xxSmall}
                height={theme.layout.xxSmall}
                listingId={listingId}
                containerHeight={theme.layout.small}
                containerWidth={theme.layout.small}
                containerPadding="0"
                containerBorderRadius={theme.typography.border.radius.xxLarge}
                containerMargin="0"
                containerborderColor={theme.colors.tfwBlack10}
            />
            <ShareButton
                width={theme.layout.xxSmall}
                height={theme.layout.xxSmall}
                listingId={listingId}
                containerHeight={theme.layout.small}
                containerWidth={theme.layout.small}
                containerPadding="0"
                containerBorderRadius={theme.typography.border.radius.xxLarge}
                containerMargin="0"
                containerborderColor={theme.colors.tfwBlack10}
            />
        </WishlistShareBarContainer>
    )
}

export default WishlistShareBar
