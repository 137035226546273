import styled, { css } from 'styled-components'

interface ContainerProps {
    containerBackgroundColor?: string
    containerBorderRadius?: string
    containerWidth?: string
    containerHeight?: string
    containerMargin?: string
    containerPadding?: string
    containerborderColor?: string
}

export const Container = styled.div<ContainerProps>(
    ({
        containerBackgroundColor,
        containerBorderRadius,
        containerHeight,
        containerWidth,
        containerMargin,
        containerPadding,
        containerborderColor,
        theme,
    }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${theme.layout.xSmall};
        height: ${theme.layout.xSmall};
        background: ${theme.colors.white};
        padding: ${theme.spacing.xxSmall};
        margin: ${theme.spacing.small};
        border-radius: ${theme.typography.border.radius.xLarge};
        ${!!containerborderColor &&
        css`
            border: 1px solid ${containerborderColor};
        `};
        ${!!containerBackgroundColor &&
        css`
            background-color: ${containerBackgroundColor};
        `};
        ${!!containerBorderRadius &&
        css`
            border-radius: ${containerBorderRadius};
        `};
        ${!!containerHeight &&
        css`
            height: ${containerHeight};
        `};
        ${!!containerWidth &&
        css`
            width: ${containerWidth};
        `};
        ${!!containerMargin &&
        css`
            margin: ${containerMargin};
        `};
        ${!!containerPadding &&
        css`
            padding: ${containerPadding};
        `};
    `,
)

interface ShareIconProps {
    width?: string
    height?: string
}

export const ShareIconWrapper = styled.img<ShareIconProps>(
    ({ width, height, theme }) => css`
        width: ${theme.layout.xxSmall};
        height: ${theme.layout.xxSmall};
        ${!!width &&
        css`
            height: ${width};
        `};
        ${!!height &&
        css`
            width: ${height};
        `};
    `,
)
