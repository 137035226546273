import React from 'react'
import { Typography } from 'react-components'
import { SectionTitleWrapper } from '../style'
import { PriceCostContentWrapper } from './styles'
import { useTranslation } from '../../../hooks/locale'
import { FormattedLivePricingDataTransport } from '../../../models/pricing/live-pricing-formatter'

type UsageBoundCostsProps = {
    usageBoundCosts?: FormattedLivePricingDataTransport['usageBoundCosts']
}

const UsageBoundCosts: React.FC<UsageBoundCostsProps> = ({ usageBoundCosts }) => {
    const { t } = useTranslation()
    usageBoundCosts = usageBoundCosts ? usageBoundCosts : []
    return (
        <>
            <SectionTitleWrapper size="header">
                <Typography variant="subtitle1" fontWeight="bold">
                    {t('price.usageBoundCosts.title')}
                </Typography>
            </SectionTitleWrapper>
            <PriceCostContentWrapper>
                <Typography variant="subtitle2" fontWeight="regular" color="tfwBlack40">
                    {usageBoundCosts.length > 0 ? usageBoundCosts.join(',') : t('price.usageBoundCosts.description')}
                </Typography>
            </PriceCostContentWrapper>
        </>
    )
}

export default UsageBoundCosts
