import get from 'lodash/get'
import {
    FormattedBookingTypeData,
    BookingTypeStringsEnum,
} from '../../../../models/property-details-page/booking-type-model'

const isDirectBookingButtonVisible = ({ bookingType }: { bookingType: FormattedBookingTypeData | null }): boolean => {
    if (!bookingType) {
        return false
    }
    const type: string | null = get(bookingType, 'type')
    return type === BookingTypeStringsEnum.DIRECT_BOOKING
}

export default isDirectBookingButtonVisible
