import { RoutingFunction, TranslationFunction } from '../../common/types'
import {
    CustomerObjectsTransport,
    PropertyDetails,
    PropertyObjectTransport,
} from '../../transports/property-details-page-transport'
import isEmpty from 'lodash/isEmpty'
import { PropertyType } from './property-type-model'
import get from 'lodash/get'
import buildRouteObjectId from './utils/build-route-object-id'
import { buildPath } from '../../routes/build-route'

export interface FormattedCustomerObject {
    title: string
    imageUrl: string | null
    exposeUrl: string
}

export interface FormattedCustomerObjectsTransport {
    total: number
    objects: FormattedCustomerObject[]
    objectOverviewLink: string | null
}

export class CustomerObjects {
    private readonly __: TranslationFunction
    private readonly r: RoutingFunction
    private static OBJECT_IMAGE_SIZE = 41

    constructor(translationFunction: TranslationFunction, r: RoutingFunction) {
        this.__ = translationFunction
        this.r = r
    }

    public format(
        customerObjects: CustomerObjectsTransport,
        objectOverviewLink: PropertyDetails['objectOverviewLink'],
    ): FormattedCustomerObjectsTransport | null {
        const formattedCustomerObjects: FormattedCustomerObject[] = []
        if (customerObjects.total <= 0) {
            return null
        }
        customerObjects.objects.forEach(custObject => {
            const formattedCustomerObject: FormattedCustomerObject = {
                title: '',
                imageUrl: null,
                exposeUrl: '',
            }

            formattedCustomerObject['title'] = this.buildTitle(custObject.propertyTypeId, custObject.title)
            formattedCustomerObject['imageUrl'] = this.getImageUrl(custObject.images)
            const listingId = buildRouteObjectId(
                custObject.providerId,
                custObject.externalObjectId,
                custObject.tfwObjectId,
            )

            formattedCustomerObject['exposeUrl'] = buildPath(this.r('propertyDetails'), {
                listingId,
            })
            formattedCustomerObjects.push(formattedCustomerObject)
        })
        return {
            total: get(customerObjects, 'total', 0),
            objects: formattedCustomerObjects,
            objectOverviewLink: objectOverviewLink
                ? buildPath(this.r('objectOverview'), {
                      objectOverviewLink,
                  })
                : null,
        }
    }

    private buildTitle(propertyTypeId: number, title: string): string {
        const label = new PropertyType(propertyTypeId, this.__).getLabel()
        if (!title) {
            return label
        }
        if (title.includes(label)) {
            return title
        }
        return `${label} ${title}`
    }

    private getImageUrl(images: PropertyObjectTransport['images']): string | null {
        if (!isEmpty(images)) {
            const image = images[0]
            const imageUrl = get(image, 'url')
            if (imageUrl) {
                return imageUrl.replace(/\/46\//, `/${CustomerObjects.OBJECT_IMAGE_SIZE}/`)
            }
        }
        return null
    }
}
